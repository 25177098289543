import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Swal from 'sweetalert2';

import Loader from '../../../UiUxComponents/Loader/Loader';
import { selectToken, selectUser } from '../../../../Redux/selector';
import { getAssignedEmployees } from '../../../../utils/Services/subscriberConfigurationServices/assignedClientServices';

function AssignedUsers() {
  const [assignedData, setAssignedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = useSelector(selectToken);
  const user = useSelector(selectUser);
  const { clientId } = useParams();
  const companyId = user?.companyId?._id

  // Fetch assigned users details
  const fetchAssignedUsersDetail = async () => {
    setLoading(true);
    try {
      const response = await getAssignedEmployees(token, clientId, companyId);
      setAssignedData(response?.data?.data || []);
    } catch (error) {
      console.error('Error:', error);
      Swal.fire({
        icon: 'error',
        title: 'Failed ',
        text: 'Failed to fetch data',
        confirmButtonColor: '#1B4965',
        customClass: {
            container: 'swal-container',
        }
    })
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchAssignedUsersDetail();
  }, []);

  return (
    <Container sx={{ padding: '20px' }}>
      <TableWrapper>
        <Table>
          <thead>
            <tr>
              <Th>Client</Th>
              <Th>Employee</Th>
            </tr>
          </thead>
          <tbody>
            {assignedData.length > 0 ? (
              assignedData.map((row, index) => (
                <tr key={index}>
                  <Td>{row.client?.name || 'N/A'}</Td>
                  <Td>{row.user?.name || 'N/A'}</Td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={2} align="center">No employees assigned yet.</td>
              </tr>
            )}
          </tbody>
        </Table>
      </TableWrapper>
      {loading && <Loader />}
    </Container>
  );
}

export default AssignedUsers;

const Container = styled.div`
  padding: 20px;
  height: 70vh;
  overflow: hidden;
`;

const TableWrapper = styled.div`
  max-height: 100%;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #1B4965 #f0f0f0;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #1B4965;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background: #f0f0f0;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  padding: 10px;
  background-color: #1B4965;
  color: #fff;
  text-align: left;
`;

const Td = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
`;
