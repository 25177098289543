import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ProtectedClientCoordinatorsContainer from '../utils/ProtectedContainers/ProtectedClientCoordinatorsContaine';
import ClientCoordinatorsDashboard from '../Pages/ClientCoordinators/ClientCoordinatorsDashboard/ClientCoordinatorsDashboard';
import ClientCoordinatorDetails from '../Pages/ClientCoordinators/ClientCoordinatorDetails/ClientCoordinatorDetails';

const ClientCoordinatorsRoutes: React.FC = () => (
  <Routes>
    <Route element={<ProtectedClientCoordinatorsContainer />}>
      <Route path='/:clientId' element={<ClientCoordinatorsDashboard />} />

      <Route path='/coordinator-details/:coordinatorId' element={<ClientCoordinatorDetails/>} />
    </Route>
  </Routes>
);

export default ClientCoordinatorsRoutes;
