import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import Swal from 'sweetalert2';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import './ForgotPassword.css'
import Loader from '../../../Components/UiUxComponents/Loader/Loader';
import { forgotPassword } from '../../../utils/Services/authServices';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Determine the target path for the "Back To Login" link
  const backToLoginPath = location.pathname.startsWith('/client-forgot-password')
    ? '/client-login'
    : '/';

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await forgotPassword({ email: email });
      switch (response.status) {
        case 200:
          Swal.fire({
            icon: 'success',
            title: 'OTP Sent',
            text: `OTP sent to ${email}`,
            showConfirmButton: false,
            timer: 1500,
          });
          setTimeout(() => {
            const redirectPath = location.pathname.startsWith('/client-forgot-password')
              ? '/client-change-password'
              : '/change-password';
              navigate(redirectPath, { state: { email } });
          }, 1500);
          break;
        case 404:
          Swal.fire({
            icon: 'error',
            title: 'Email Invalid',
            text: 'Email does not exist in our records.',
            showConfirmButton: false,
            timer: 1500,
          })
          break;
        default:
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Something went wrong',
            confirmButtonColor: "#1B4965",
          });
          break;
      }
    } catch (error) {
      console.error('Error sending forgot password email:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Something went wrong',
        confirmButtonColor: "#1B4965",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ background: '#f2f2f2', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div className="forgot-password">
        <div className='forgot-password-container'>
          <div className='forgotpassword-title-container'>
            <h2>Forgot Password</h2>
          </div>
          <div className='forgotpassword-box'>
            <div>
              <form onSubmit={handleSubmit}>
                <div className='input-field'>
                  <span><FontAwesomeIcon icon={faEnvelope} className='email-icon' /></span>
                  <input type="email" name='email' placeholder='Enter Email' value={email} onChange={(e) => setEmail(e.target.value)} required />
                </div>
                <input className="button" type="submit" value="Send Link" />
                <div className='extraLinks'>
                  <Link to={backToLoginPath} className='loginpassword'>Back To Login</Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {(loading) && (
        <Loader />
      )}
    </div>

  )
}

export default ForgotPassword
