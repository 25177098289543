import { useState, useEffect } from 'react';

import { Box, Typography, IconButton, Popover, TextField } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';

import { format } from '../../../utils/CustomPackages/dateUtils';

const TimePeriod = ({ startDate, endDate, onDateRangeChange }) => {
    const today = DateTime.now();
    const startOfWeek = today.startOf('week');
    const endOfWeek = today.endOf('week');

    const [anchorEl, setAnchorEl] = useState(null);
    const [start, setStart] = useState(startDate ? DateTime.fromISO(startDate) : startOfWeek);
    const [end, setEnd] = useState(endDate ? DateTime.fromISO(endDate) : endOfWeek);

    useEffect(() => {
        setStart(startDate ? DateTime.fromISO(startDate) : startOfWeek);
        setEnd(endDate ? DateTime.fromISO(endDate) : endOfWeek);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate]);

    const handleCalendarOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCalendarClose = () => {
        setAnchorEl(null);
        if (onDateRangeChange) {
            onDateRangeChange(start.toISO(), end.toISO()); // Send ISO format dates
        }
    };

    const handleStartDateChange = (newValue) => {
        if (newValue) {
            setStart(newValue);
            if (onDateRangeChange) {
                onDateRangeChange(newValue.toISO(), end.toISO());
            }
        }
    };

    const handleEndDateChange = (newValue) => {
        if (newValue && start && newValue < start) {
            return;
        }
        setEnd(newValue);
        if (onDateRangeChange) {
            onDateRangeChange(start.toISO(), newValue.toISO());
        }
    };

    const open = Boolean(anchorEl);
    const id = open ? 'calendar-popover' : undefined;

    return (
        <Box display="flex" alignItems="center" padding={1} border="1px solid #ccc" borderRadius="4px">
            <Typography variant="body2" color="textSecondary" sx={{ marginRight: '4px' }}>
                Time period:
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: 'bold', marginRight: '8px' }}>
                {start.isValid ? format(start.toJSDate(), 'dd/MM/yyyy') : format(startOfWeek.toJSDate(), 'dd/MM/yyyy')} - 
                {end.isValid ? format(end.toJSDate(), 'dd/MM/yyyy') : format(endOfWeek.toJSDate(), 'dd/MM/yyyy')}
            </Typography>
            <IconButton size="small" onClick={handleCalendarOpen}>
                <CalendarTodayIcon fontSize="small" />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleCalendarClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <Box padding={2} display="flex" flexDirection="row" gap={2}>
                        <DatePicker
                            label="Start Date"
                            value={start}
                            onChange={handleStartDateChange}
                            renderInput={(params) => <TextField {...params} />}
                            maxDate={end.isValid ? end : undefined}
                        />
                        <DatePicker
                            label="End Date"
                            value={end}
                            onChange={handleEndDateChange}
                            renderInput={(params) => <TextField {...params} />}
                            minDate={start.isValid ? start : undefined}
                        />
                    </Box>
                </LocalizationProvider>
            </Popover>
        </Box>
    );
};

export default TimePeriod;
