import axios from 'axios';
const backEndUrl = `${process.env.REACT_APP_BACKEND_URL}/api/comapany-admin-dashboard`;

// To get Company Attendance Statistics
export const getCompanyAttendanceStatistics = (token, subscriberId, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.get(`${backEndUrl}/company-attendance-statistics/${subscriberId}`, {
                headers,
                params: data
            });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};