import React, { useState } from 'react';
import { Tabs, Tab, Box, Typography } from '@mui/material';
import CompanyDetails from '../../../../Components/SystemAdminComponents/SubscriberDetailsComponents/CompanyDetails/CompanyDetails';
import AdminDetails from '../../../../Components/SystemAdminComponents/SubscriberDetailsComponents/AdminDetails/AdminDetails';

interface TabPanelProps {
    children?: React.ReactNode;
    value: number;
    index: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other} style={{ padding: '0px 12px 0px 12px', height: '100%' }}>
            {value === index && (
                <Box sx={{ p: 1, backgroundColor: 'white', height: '100%' }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function SubscriberDetails() {
    const [value, setValue] = useState<number>(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <div style={{ backgroundColor: '#ccc', height: '100%' }}>
            <Box sx={{ width: '100%', height: '100%', }}>
                <Box sx={{ padding: 1, paddingBottom: 0, paddingTop: 2 }}>
                    <Tabs value={value} onChange={handleChange} aria-label="subscriber details tabs" textColor='inherit' indicatorColor='primary'>
                        {['Company Details', 'Admin Details', 'Employees list', 'Documents', 'Permissions', 'Settings'].map((label, index) => (
                            <Tab key={label} label={label}
                                sx={{ backgroundColor: value === index ? 'white' : '#1B4965', color: value === index ? '#1B4965' : 'White', margin: '0 5px', borderRadius: 1, borderBottomLeftRadius: 0, borderBottomRightRadius: 0, fontSize: '12px', paddingTop: '0px', paddingBottom: '0'}}
                            />
                        ))}
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <CompanyDetails/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <AdminDetails/>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    Employees list Content
                </TabPanel>
                <TabPanel value={value} index={3}>
                    Documents Content
                </TabPanel>
                <TabPanel value={value} index={4}>
                    Permissions Content
                </TabPanel>
                <TabPanel value={value} index={5}>
                    Settings Content
                </TabPanel>
            </Box>
        </div>
    );
}

export default SubscriberDetails;
