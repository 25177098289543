import axios from 'axios';
const backEndUrl = `${process.env.REACT_APP_BACKEND_URL}/api/users`;

// To get users list based on subscriber
export const getAllUsersDetails = (token, subscriberId) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.get(`${backEndUrl}/getalluserslist/${subscriberId}`, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// To Create a user by company super-admin
export const createUserBySubscriber = (token, subscriberId, userDetails, officeDetails, datesDetails, companyDetails) => {
    const formData = new FormData();
    formData.append('userDetails', JSON.stringify(userDetails));
    formData.append('officeDetails', JSON.stringify(officeDetails));
    formData.append('datesDetails', JSON.stringify(datesDetails));
    formData.append('companyDetails', JSON.stringify(companyDetails));

    if (userDetails.profilePic) {
        formData.append('profilePic', userDetails.profilePic);
    }

    if (datesDetails.electronicSignature) {
        formData.append('electronicSignature', datesDetails.electronicSignature);
    }

    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            }
            let response = await axios.post(`${backEndUrl}/create-user/${subscriberId}`, formData, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// To get the user details by tab names
export const getuserDetailsByTabName = (token, tabName, subscriberId) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.get(`${backEndUrl}/getuserdetails/${tabName}/${subscriberId}`, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// To Update a company super admin by system super admin
export const updateCompanySuperAdminBySystemSuperAdmin = (token, employeeId, userDetails, companyDetails) => {
    const formData = new FormData();
    formData.append('companyDetails', JSON.stringify(companyDetails));

    formData.append('userDetails', JSON.stringify(userDetails));

    if (userDetails.profilePic) {
        formData.append('profilePic', userDetails.profilePic);
    }

    if (userDetails.uploadedFile) {
        formData.append('uploadedFile', userDetails.uploadedFile);
    }

    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
            let response = await axios.put(`${backEndUrl}/update-company-super-admin/${employeeId}`, formData, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// To Update user
export const updateUser = (token, employeeId, tabName, updatedData) => {
    const formData = new FormData();

    switch (tabName) {
        case 'personalDetails':
            formData.append('userPersonalDetails', JSON.stringify(updatedData.userPersonalDetails));
            formData.append('userInformatioDetails', JSON.stringify(updatedData.userInformatioDetails));
            formData.append('companyDetails', JSON.stringify(updatedData.companyDetails));

            if (updatedData.userPersonalDetails.profilePic) {
                formData.append('profilePic', updatedData.userPersonalDetails.profilePic);
            }

            if (updatedData.userPersonalDetails.profileIdCard) {
                formData.append('profileIdCard', updatedData.userPersonalDetails.profileIdCard);
            }

            if (updatedData.userInformatioDetails.electronicSignature) {
                formData.append('electronicSignature', updatedData.userInformatioDetails.electronicSignature);
            }
            break;

        case 'companyDetails':
            formData.append('officeInformationDetails', JSON.stringify(updatedData.officeInformationDetails));
            break;

        case 'familyDetails':
            formData.append('familyInformationDetails', JSON.stringify(updatedData.familyInformationDetails));
            break;

        case 'workExperience':
            formData.append('workExperienceDetails', JSON.stringify(updatedData.data));
            break;

        case 'bankDetails':
            formData.append('bankInformationDetails', JSON.stringify(updatedData.bankInformationDetails));
            break;

        case 'educationDetails':
            formData.append('educationInformationDetails', JSON.stringify(updatedData.data));
            break;

        case 'idProofs':
            formData.append('userPersonalDetails', JSON.stringify(updatedData.userPersonalDetails));
            formData.append('idProofs', JSON.stringify(updatedData.idProofs));
            formData.append('companyDetails', JSON.stringify(updatedData.companyDetails));

            updatedData.idProofs.forEach(item => {
                formData.append('idProofUploadDetails', item.idProofUploadDetails)
            })
            if (updatedData.idProofs.idProofUploadDetails) {
                formData.append('idProofUploadDetails', updatedData.idProofs.idProofUploadDetails);
            }
            break;

        default:
            throw new Error(`Unknown tabName: ${tabName}`);
    }

    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
            let response = await axios.put(`${backEndUrl}/update-user/${tabName}/${employeeId}`, formData, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// To Suspend a user
export const suspendUserById = (token, userId, active) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            };
            let response = await axios.put(`${backEndUrl}/suspend-user/${userId}`, {active}, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// To Delete a user
export const deleteUserById = (token, userId ) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            };
            let response = await axios.delete(`${backEndUrl}/delete-user/${userId}`, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};