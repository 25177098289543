import axios from 'axios';
const backEndUrl = `${process.env.REACT_APP_BACKEND_URL}/api/clients`;

// To get all clients related to that subscriber
export const getAllClients = (token, subscriberId) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.get(`${backEndUrl}/getallClients/${subscriberId}`, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// To get all subscribers list related to that client
export const getAllSubscribers = (token, clientId) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.get(`${backEndUrl}/getallSubscribers/${clientId}`, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// To get Coordiators list related to that company
export const getAllCoordinatorsList = (token, clientId) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.get(`${backEndUrl}/getallCoordinatorsList/${clientId}`, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// To get Subscribers according to Coordinator and Department
export const getSubscribersData = (token, companyId, CoordinatorIds, DepartmentIds) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.post(`${backEndUrl}/getSubscribersData/${companyId}`, { CoordinatorIds, DepartmentIds}, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};