import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useEffect } from "react";
import { logoutUser } from "../../Redux/reducers/authReducer";
import { selectToken } from "../../Redux/selector";

const useCheckValidUser = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = useSelector(selectToken);

    const handleLogout = () => {
        dispatch(logoutUser());
        navigate('/')
    }

    useEffect(() => {
        if (token) {
            try {
                let decode = jwtDecode(token)
                if (!decode) {
                    handleLogout()
                } else {
                    const currentTime = Math.floor(Date.now() / 1000)
                    const isExpired = decode.exp < currentTime
                    const willExpireSoon = decode.exp - currentTime < 3600
                    
                    if (isExpired || willExpireSoon) {
                        handleLogout()
                    } else {
                        const { user } = decode;
                        if(!user.id){
                            handleLogout();
                        }
                    }
                }
            } catch (error){
                console.log("Token decoding error", error);
                handleLogout()
            }
        } else {
            handleLogout();
        }
    }, [navigate, dispatch, token]);
};

export default useCheckValidUser;
