import { StatusType } from '../../types'
import axios, { AxiosResponse } from 'axios';
const backEndUrl = `${process.env.REACT_APP_BACKEND_URL}/api/status`;


// To Add New Status
export const addNewStatus = (data: StatusType): Promise<AxiosResponse> => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                'Content-Type': 'application/json'
            }
            let response = await axios.post(`${backEndUrl}/add-status`, data, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// To get Newly Status
export const getNewStatus = (userId: string): Promise<AxiosResponse> => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                'Content-Type': 'application/json'
            }
            let response = await axios.get(`${backEndUrl}/get-newly-added-status/${userId}`, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// To Delete Newly Added Status
export const deleteNewStatus = (userId: string, statusId: string): Promise<AxiosResponse> => {
    return new Promise(async (resolve, reject) => {
        try {
            let header = {
                'Content-Type': 'application/json',
            };
            const response = await axios.delete(`${backEndUrl}/delete-newly-added-status/${userId}/${statusId}`, {
                headers: header,
            });
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
}

// To Add Current Status
export const currentStatus = (data: StatusType, userId: string): Promise<AxiosResponse> => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                'Content-Type': 'application/json'
            }
            let response = await axios.post(`${backEndUrl}/current-status/${userId}`, data, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// To get Newly Status
export const getCurrentStatus = (userId: string): Promise<AxiosResponse> => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                'Content-Type': 'application/json'
            }
            let response = await axios.get(`${backEndUrl}/get-current-status/${userId}`, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};