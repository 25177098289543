import React from 'react';
import { Route, Routes } from 'react-router-dom';

import './App.css';
import RoutesConfig from './RoutesConfig';

const App: React.FC = () => {
  return (
    <Routes>
      <Route path="/*" element={<RoutesConfig />} />
    </Routes>
  );
};

export default App;
