import React from 'react';
import Navbar from '../../Components/UiUxComponents/Navbar/Navbar';
import SidebarContainer from '../../Components/UiUxComponents/Sidebar/SidebarContainer/SidebarContainer';
import useInactivityTimeout from '../useInactivityTimeout';
import useCheckValidClientCoordinators from '../authHelper/useCheckValidClientCoordinators';

interface ContainerProps {
  children?: React.ReactNode;
}

const ProtectedClientCoordinatorsContainer: React.FC<ContainerProps> = ({ children }) => {
    useCheckValidClientCoordinators();

  const currentPath = window.location.pathname;
  const excludedRoutes = ['/client-login', '/client-change-password', '/client-login-with-otp', '/client-forgot-password'];
  const isExcludedRoute = excludedRoutes.includes(currentPath);

  useInactivityTimeout(isExcludedRoute);

  return (
    <div style={{ zIndex: 1 }}>
      <Navbar />
      <SidebarContainer/>
    </div>
  );
};

export default ProtectedClientCoordinatorsContainer;
