import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Swal from 'sweetalert2';
import { Box, IconButton, Modal, TextField, Button, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import './DesignationRegistration.css';
import { selectToken } from "../../../Redux/selector";
import { getAllUsersDetails } from "../../../utils/Services/userServices";
import { createDesignation } from "../../../utils/Services/subscriberConfigurationServices/designationServices"

function DesignationRegistration({ open, onClose }) {
  const [designationName, setDesignationName] = useState('');
  const [designationHead, setDesignationHead] = useState({ id: '', name: '' });
  const [description, setDescription] = useState('');
  const [userDetails, setUserDetails] = useState([]);
  const token = useSelector(selectToken);
  const { subscriberId } = useParams();

  // To fetch Users Details
  const fetchUsersDetail = async () => {
    try {
      const response = await getAllUsersDetails(token, subscriberId);
      setUserDetails(response?.data?.data || []);
    } catch (error) {
      console.error('Error:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'There was an error. Please try again later.',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });
    }
  }
  useEffect(() => {
    fetchUsersDetail();
  }, []);

  const handleDesignationNameChange = (event) => {
    setDesignationName(event.target.value);
  };

  const handleDesignationHeadChange = (event) => {
    const selectedUser = userDetails.find(user => user.fullName === event.target.value);
    if (selectedUser) {
      setDesignationHead({ id: selectedUser._id, name: selectedUser.fullName });
    }
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  // Reset the states
  const resetStates = () => {
    setDesignationName('')
    setDesignationHead({ id: '', name: '' })
    setDescription('')
  };

  const handleFormSubmit = async () => {
    const data = {
      designationName,
      designationHeadId: designationHead.id,
      designationHeadName: designationHead.name,
      description,
    };
    try {
      await createDesignation(token, subscriberId, data);
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Designation registered successfully!',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });

      resetStates();
      onClose();
    } catch (error) {
      console.error('Error:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to register designation. Please try again later.',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });
    }
  };

  const handleBeforeUnload = () => {
    resetStates();
    onClose();
  };

  return (
    <div>
      <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
        <Box className="designationRegistration" sx={{ width: '40%', height: 'auto' }}>
          <div style={{ height: '100%', overflow: 'auto' }}>
            <Box sx={{ position: 'sticky', top: 0, zIndex: 1, background: 'linear-gradient(90deg, #1B4965 63.83%, #051925 82.97%)', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '6px', textAlign: 'center', borderRadius: '4px' }}>
              <h2 id="modal-modal-title" style={{ margin: 0, color: 'white', marginLeft: '15px' }}>New Designation Registration</h2>
              <IconButton onClick={handleBeforeUnload}>
                <CloseIcon sx={{ fontSize: '32px', color: 'white' }} />
              </IconButton>
            </Box>
            <div style={{ padding: '16px' }}>
              <div>
                <TextField required label="Designation Name" fullWidth margin="normal"
                  value={designationName}
                  onChange={handleDesignationNameChange}
                />
                <FormControl required variant='outlined' fullWidth margin='normal'>
                  <InputLabel id="designationHead">Designation Head</InputLabel>
                  <Select labelId='designationHead' id='designationHead' value={designationHead.name} onChange={handleDesignationHeadChange} label="Designation Head" margin="dense" sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '100%' }}>
                    {userDetails.map((user) => (
                      <MenuItem key={user._id} value={user.fullName}>
                        {user.fullName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField label="Description" fullWidth margin="normal" multiline rows={4}
                  value={description}
                  onChange={handleDescriptionChange}
                />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                  <Button variant="contained" color="primary" onClick={handleFormSubmit} sx={{ background: 'linear-gradient(90deg, #1B4965 63.83%, #051925 82.97%)', backgroundSize: '200%' }}>Register</Button>
                </Box>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default DesignationRegistration;