import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Swal from 'sweetalert2';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { selectToken } from '../../../../Redux/selector';
import { addDailyReport, updateDailyReport } from '../../../../utils/Services/dailyReportsServices';
import Loader from '../../../UiUxComponents/Loader/Loader';

function AddTask({ open, handleClose, selectedDate, selectedTask, isEditing }) {
    const [taskTitle, setTaskTitle] = useState('');
    const [taskDescription, setTaskDescription] = useState('');
    const [clientName, setClientName] = useState('');
    const [hours, setHours] = useState('');
    const [taskStatus, setTaskStatus] = useState('');
    const [comments, setComments] = useState('');
    const [loading, setLoading] = useState(false);
    const token = useSelector(selectToken);
    const { userId } = useParams();

    // Effect to populate the form with selectedTask data when editing
    useEffect(() => {
        if (isEditing && selectedTask) {
            setTaskTitle(selectedTask.taskTitle || '');
            setTaskDescription(selectedTask.taskDescription || '');
            setClientName(selectedTask.clientName || '');
            setHours(selectedTask.hours || '');
            setTaskStatus(selectedTask.taskStatus || '');
            setComments(selectedTask.comments || '');
        }
    }, [isEditing, selectedTask]);

    // To reset form states
    const resetForm = () => {
        setTaskTitle('');
        setTaskDescription('');
        setClientName('');
        setHours('');
        setTaskStatus('');
        setComments('');
    };

    // To close form
    const handleCloseWithReset = () => {
        resetForm();
        handleClose();
    };

    // To submit form data
    const handleSubmit = async () => {
        const data = {
            selectedDate,
            clientName,
            dailyReport: { taskTitle, taskDescription, clientName, hours, taskStatus, comments },
        };
        try {
            setLoading(true)
            if (isEditing) {
                await updateDailyReport(token, userId, selectedTask._id, data);
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Daily Report Updated successfully!',
                    confirmButtonColor: '#1B4965',
                    customClass: {
                        container: 'swal-container',
                    }
                });
            } else {
                await addDailyReport(token, userId, data);
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Daily Report Added successfully!',
                    confirmButtonColor: '#1B4965',
                    customClass: {
                        container: 'swal-container',
                    }
                });
            }
        } catch (error) {
            console.error('Error:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Failed to add or update daily report. Please try again later.',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
        } finally {
            setLoading(false);
            handleCloseWithReset();
        }
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle style={{ backgroundColor: '#1B4965', color: 'white', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    Daily Task Sheet
                    <IconButton onClick={handleCloseWithReset} style={{ color: 'white' }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent style={{ backgroundColor: '#F0F4F8', paddingTop: '20px' }}>
                    <TextField label="Task Title" fullWidth margin="dense" value={taskTitle} onChange={(e) => setTaskTitle(e.target.value)} />
                    <TextField label="Task Description" fullWidth multiline rows={3} margin="dense" value={taskDescription} onChange={(e) => setTaskDescription(e.target.value)} />
                    <TextField label="Client Company Name" fullWidth margin="dense" value={clientName} onChange={(e) => setClientName(e.target.value)} />
                    <TextField label="No. of Hours" fullWidth margin="dense" value={hours} onChange={(e) => setHours(e.target.value)} />
                    <TextField label="Task Status" fullWidth margin="dense" value={taskStatus} onChange={(e) => setTaskStatus(e.target.value)} />
                    <TextField label="Comments" fullWidth multiline rows={2} margin="dense" value={comments} onChange={(e) => setComments(e.target.value)} />
                </DialogContent>
                <DialogActions style={{ backgroundColor: '#F0F4F8' }}>
                    <Button onClick={handleCloseWithReset} color="secondary" variant="contained">Cancel</Button>
                    <Button onClick={handleSubmit} color="primary" variant="contained"
                        sx={{
                            marginY: 2,
                            backgroundColor: '#1B4965',
                            '&:hover': {
                                backgroundColor: '#1B4965',
                            }
                        }}>{isEditing ? 'Update' : 'Submit'}
                    </Button>
                </DialogActions>
            </Dialog>
            {(loading) && (
                <Loader />
            )}
        </>
    );
}

export default AddTask;
