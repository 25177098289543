import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Swal from 'sweetalert2';
import { Avatar, Box, Button, Card, Divider, FormControl, Grid, InputLabel, MenuItem, Modal, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import './ClientCoordinatorsDashboard.css';
import User from '../../../assets/NavbarImages/user.svg'
import Loader from '../../../Components/UiUxComponents/Loader/Loader';
import { selectToken, selectUser } from '../../../Redux/selector';
import { getAllSubscribers, getSubscribersData } from '../../../utils/Services/clientServices';
import { getAssignedEmployees } from '../../../utils/Services/subscriberConfigurationServices/assignedClientServices';
import { getDepartment } from '../../../utils/Services/subscriberConfigurationServices/departmentsServices';

function ClientCoordinatorsDashboard() {
  const [subscriberData, setSubscriberData] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [coordinatorsList, setCoordinatorsList] = useState([])
  const [subscriberPersonalData, setSubscriberPersonalData] = useState([])
  const [selectedCoordinator, setSelectedCoordinator] = useState('All');
  const [selectedDepartment, setSelectedDepartment] = useState('All');
  const [selectedUser, setSelectedUser] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const token = useSelector(selectToken);
  const user = useSelector(selectUser);
  const clientId = user?.companyId?._id
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    try {
      fetchSubscriberDetail();
      fetchDepartmentList();
      fetchAssignedEmployeesList();
    } catch (error) {
      console.log('Error while loading data', error)
    }
  }, []);

  // To fetch Subscriber Details
  const fetchSubscriberDetail = async () => {
    setLoading(true);
    try {
      const response = await getAllSubscribers(token, clientId);
      setSubscriberData(response?.data);
    } catch (error) {
      console.error('Error:', error);
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: 'Failed to load Data. Please try again.',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });
    } finally {
      setLoading(false);
    }
  }

  // To fetch Department list
  const fetchDepartmentList = async () => {
    setLoading(true);
    try {
      const response = await getDepartment(token, user?.companyId?.clientOf);
      setDepartments(response?.data || []);
    } catch (error) {
      console.error('Error:', error);
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: 'Failed to load Data. Please try again.',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });
    } finally {
      setLoading(false);
    }
  }

  // To fetch Assigned Employees Data according to the client
  const fetchAssignedEmployeesList = async () => {
    setLoading(true);
    try {
      const response = await getAssignedEmployees(token, clientId, user?.companyId?.clientOf);
      setCoordinatorsList(response?.data?.data || []);
    } catch (error) {
      console.error('Error:', error);
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: 'Failed to load Data. Please try again.',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });
    } finally {
      setLoading(false);
    }
  }

  // Fetch Subscribers Data based on Selected Coordinator and Selected Department
  const fetchSubscribersData = async () => {
    setLoading(true);
    try {
      let CoordinatorIds = [];
      let DepartmentIds = []

      if (selectedCoordinator === 'All') {
        CoordinatorIds = coordinatorsList.map((coordinator) => coordinator?.user?.id);
      } else {
        const selectedCoordinatorData = coordinatorsList.find((coordinator) => coordinator?.user?.name === selectedCoordinator);
        CoordinatorIds = [selectedCoordinatorData?.user?.id];
      }
      if (selectedDepartment === 'All') {
        DepartmentIds = []
      } else {
        const selectedDepartmentData = departments.find((department) => department.departmentName === selectedDepartment);
        DepartmentIds = [selectedDepartmentData?._id];
      }
      const response = await getSubscribersData(token, clientId, CoordinatorIds, DepartmentIds);
      setSubscriberPersonalData(response?.data?.userDetails || []);
    } catch (error) {
      console.error('Error:', error);
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: 'Failed to load Data. Please try again.',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (selectedCoordinator === 'All' || selectedDepartment === 'All') {
      fetchSubscribersData();
    }
  }, [selectedCoordinator, selectedDepartment, coordinatorsList]);

  // To Display Location
  const location = [
    subscriberData?.city,
    subscriberData?.state,
    subscriberData?.country
  ].filter(Boolean).join(', ') || 'N/A';

  // Handle View Profile
  const handleViewProfile = (subscriber) => {
    setSelectedUser(subscriber);
    handleOpen();
  };

  // To Display User Image
  const userImageUrl = selectedUser?.profilePic?.location ? selectedUser.profilePic.location : User;

  return (
    <Box sx={{ padding: 2, background: '#f1f1f1', height: '100%', maxHeight: '85vh', overflow: 'auto' }} className="custom-scrollbar">
      <Grid container spacing={2}>
        <Grid item xs={8} >
          <Card style={{ padding: '16px', background: '#fff' }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" >
              <FormControl variant="outlined" fullWidth margin="dense" sx={{ mr: 2 }}>
                <InputLabel id="coordinator-select-label">Coordinator</InputLabel>
                <Select labelId="coordinator-select-label" id="coordinator-select" value={selectedCoordinator} onChange={(e) => setSelectedCoordinator(e.target.value)} label="Coordinator">
                  <MenuItem value="All">All</MenuItem>
                  {coordinatorsList?.map((coordinator) => (
                    <MenuItem key={coordinator?.user?.id} value={coordinator?.user?.name}>
                      {coordinator?.user?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined" fullWidth margin="dense" sx={{ mr: 2 }}>
                <InputLabel id="department-select-label">Department</InputLabel>
                <Select labelId="department-select-label" id="department-select" value={selectedDepartment} onChange={(e) => setSelectedDepartment(e.target.value)} label="Department">
                  <MenuItem value="All">All</MenuItem>
                  {departments.map((department) => (
                    <MenuItem key={department._id} value={department.departmentName}>
                      {department.departmentName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Card>
          <Card style={{ padding: '16px', background: '#fff', marginTop: '20px' }}>
            <TableContainer sx={{ borderRadius: 2, overflow: 'hidden' }}>
              <Table sx={{
                '& .MuiTableHead-root': {
                  backgroundColor: '#1B4965',
                },
                '& .MuiTableCell-root': {
                  color: 'white',
                  fontWeight: 'bold',
                },
                '& .MuiTableBody-root .MuiTableRow-root:hover': {
                  backgroundColor: '#f1f1f1',
                },
                '& .MuiTableBody-root .MuiTableRow-root:hover .MuiTableCell-root': {
                  color: '#333',
                },
                '& .MuiTableCell-body': {
                  fontSize: '14px',
                  color: '#333',
                },
              }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Full Name</TableCell>
                    <TableCell>Current Status</TableCell>
                    <TableCell>View</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {subscriberPersonalData?.map((subscriber) => (
                    <TableRow key={subscriber?._id}>
                      <TableCell>{subscriber?.fullName}</TableCell>
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Box
                            sx={{
                              width: 11, height: 11, borderRadius: '50%', backgroundColor: (() => {
                                switch (subscriber?.userCurrentStatus?.label) {
                                  case 'Active':
                                    return 'green';
                                  case 'Away':
                                    return '#ccc';
                                  case 'Offline':
                                    return 'red';
                                  case 'InMeeting':
                                    return 'blue';
                                  case null:
                                  case undefined:
                                    return 'red';
                                  default:
                                    return 'yellow';
                                }
                              })(),
                              border:
                                subscriber?.userCurrentStatus?.label === 'Away'
                                  ? '2px solid white'
                                  : 'none',
                              marginRight: 1,
                            }}
                          />
                          <Typography>{subscriber?.userCurrentStatus?.label || 'Offline'}</Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Button variant="contained" color="primary" onClick={() => handleViewProfile(subscriber)}
                          sx={{
                            backgroundColor: '#1B4965',
                            color: 'white',
                            '&:hover': {
                              backgroundColor: '#1B4965',
                            },
                            fontSize: 12,
                          }}>View Profile</Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card style={{ padding: '16px', background: '#fff' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
              <Avatar style={{ marginRight: '16px', backgroundColor: '#1B4965', width: `${subscriberData?.companyName?.split(' ').map((word) => word[0]).join('').length * 15}px`, }}>{subscriberData?.companyName?.split(' ').map((word) => word[0]).join('')}</Avatar>
              <div style={{ flexGrow: 1 }}>
                <Typography variant="h6" style={{ fontWeight: 'bold', color: '#1B4965' }}>{subscriberData?.companyName}</Typography>
                <Typography variant="body1" style={{ color: '#1B4965' }}>{subscriberData?.companyEmail}</Typography>
                <Typography variant="body2" style={{ color: '#1B4965' }}><a href={`http://${subscriberData?.companyWebsite}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#1B4965' }}>{subscriberData?.companyWebsite}</a></Typography>
              </div>
            </div>
            <Divider />
            <Grid container spacing={2} style={{ marginTop: '16px' }}>
              <Grid item xs={12}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <LocationOnIcon style={{ color: '#1B4965', marginRight: '8px' }} />
                  <Typography variant="body2" style={{ color: '#1B4965' }}>{location}</Typography>
                </div>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
      <Modal open={open} onClose={handleClose} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <Box sx={{
          width: 300, height: '100vh', backgroundColor: 'white', boxShadow: 24, p: 4, position: 'relative',
          left: 0, transition: 'transform 0.3s ease-in-out', transform: open ? 'translateX(0)' : 'translateX(-100%)',
        }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', marginTop: '20px' }}>
            <Typography variant="h6" sx={{ color: '#1B4965' }}>User Details</Typography>
            <Button
              onClick={handleClose}
              variant="contained"
              sx={{
                backgroundColor: '#1B4965',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#1B4965',
                },
              }}
            >
              Back
            </Button>
          </Box>
          {selectedUser && (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '50px' }}>
              <img src={userImageUrl} alt="" style={{ width: '150px', height: '150px', borderRadius: '50%', objectFit: 'fill' }} />
              <Typography variant="h6" sx={{ color: '#1B4965', marginTop: '10px' }}>{selectedUser?.fullName}</Typography>
              <TextField id='workEmail' name='workEmail' label="Work Email" variant='outlined' fullWidth className='TextField' margin='dense'
                value={selectedUser?.workEmail || ''}
                sx={{
                  '& .MuiInputLabel-root': { fontSize: '14px' },
                  '& .MuiInputBase-root': { fontSize: '14px' },
                  width: '100%',
                  marginTop: '20px',
                }}
              />
              <TextField id='personalEmail' name='personalEmail' label="Personal Email" variant='outlined' fullWidth className='TextField' margin='dense'
                value={selectedUser?.personalEmail || ''}
                sx={{
                  '& .MuiInputLabel-root': { fontSize: '14px' },
                  '& .MuiInputBase-root': { fontSize: '14px' },
                  width: '100%',
                  marginTop: '20px',
                }}
              />
              <TextField id='designation' name='designation' label="Designation" variant='outlined' fullWidth className='TextField' margin='dense'
                value={selectedUser?.designation?.name || ''}
                sx={{
                  '& .MuiInputLabel-root': { fontSize: '14px' },
                  '& .MuiInputBase-root': { fontSize: '14px' },
                  width: '100%',
                  marginTop: '20px',
                }}
              />
            </div>
          )}
        </Box>
      </Modal>
      {(loading) && (
        <Loader />
      )}
    </Box>
  );
}

export default ClientCoordinatorsDashboard;
