import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Login from '../Pages/LoginPages/Login/Login';
import ForgotPassword from '../Pages/LoginPages/Forgot-password/ForgotPassword';
import LoginWithOtp from '../Pages/LoginPages/LoginWithOtp/LoginWithOtp';
import ChangePassword from '../Pages/LoginPages/ChangePassword/ChangePassword';

const LoginRoutes: React.FC = () => (
  <Routes>
    <Route path="/" element={<Login />} />
    <Route path="/client-login" element={<Login />} />

    <Route path='/forgot-password' element={<ForgotPassword />} />
    <Route path='/client-forgot-password' element={<ForgotPassword />} />

    <Route path='/login-with-otp' element={<LoginWithOtp />} />
    <Route path='/client-login-with-otp' element={<LoginWithOtp />} />

    <Route path='/change-password' element={<ChangePassword />} />
    <Route path='/client-change-password' element={<ChangePassword />} />
  </Routes>
);

export default LoginRoutes;
