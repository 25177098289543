import React, { forwardRef } from 'react';
import './InMeetingModal.css'

const InMeetingModal = forwardRef(({ showInMeetingModal, handleInMeetingTimeSelect, onClose, position }, ref) => {
    if (!showInMeetingModal) return null;

    return (
        <div className="profile-meeting" ref={ref} style={{ top: `${position.top}px`, left: `${position.left}px`, position: 'absolute' }}>
            <div className='profile-meeting-container'>
                <h2>Mute Chat Notifications For</h2>
                <div className="meeting-option" onClick={() => handleInMeetingTimeSelect(30, "30 minutes")}>
                    <p className="meeting-time">30 minutes</p>
                    <p className="meeting-until">Until {new Date(Date.now() + 30 * 60000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>
                </div>
                <div className="meeting-option" onClick={() => handleInMeetingTimeSelect(60, "1 hour")}>
                    <p className="meeting-time">1 hour</p>
                    <p className="meeting-until">Until {new Date(Date.now() + 60 * 60000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>
                </div>
                <div className="meeting-option" onClick={() => handleInMeetingTimeSelect(120, "2 hours")}>
                    <p className="meeting-time">2 hours</p>
                    <p className="meeting-until">Until {new Date(Date.now() + 120 * 60000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>
                </div>
                <div className="meeting-option" onClick={() => handleInMeetingTimeSelect(240, "4 hours")}>
                    <p className="meeting-time">4 hours</p>
                    <p className="meeting-until">Until {new Date(Date.now() + 240 * 60000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>
                </div>
                <div className="meeting-option" onClick={() => handleInMeetingTimeSelect(480, "8 hours")}>
                    <p className="meeting-time">8 hours</p>
                    <p className="meeting-until">Until {new Date(Date.now() + 480 * 60000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>
                </div>
                <div className="meeting-option" onClick={() => handleInMeetingTimeSelect(1440, "24 hours")}>
                    <p className="meeting-time">24 hours</p>
                    <p className="meeting-until">Until {new Date(Date.now() + 1440 * 60000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>
                </div>
            </div>
        </div>
    );
});

export default InMeetingModal;
