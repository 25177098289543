import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ProtectedUserContainer from '../utils/ProtectedContainers/ProtectedUserContainer';
import UsersDashboard from '../Pages/Users/UsersDashboard/UsersDashboard';
import UserDetails from '../Pages/CompanyAdmin/UsersList/UsersDetails/UserDetails';
import Attendance from '../Pages/Users/Attendance/Attendance';
import DailyReports from '../Pages/Users/DailyReports/DailyReports';

const UserRoutes: React.FC = () => (
  <Routes>
    <Route element={<ProtectedUserContainer />}>
      <Route path='/:userId' element={<UsersDashboard />} />
      <Route path='/user-details/:userId' element={<UserDetails />} />
      <Route path='/attendance/:userId' element={<Attendance/>}/>
      <Route path='/daily-reports/:userId' element={<DailyReports/>}/>
    </Route>
  </Routes>
);

export default UserRoutes;
