import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import PhoneInput from 'react-phone-input-2';
// import SignatureCanvas from 'react-signature-canvas';

import Swal from 'sweetalert2';
import { Box, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Modal, Select, Tab, Tabs, TextField } from '@mui/material'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseIcon from '@mui/icons-material/Close';

import './UserRegistration.css'
import Fileuploadimg from '../../../assets/Mixed/file-upload.svg';
import Loader from "../../UiUxComponents/Loader/Loader";
import { selectToken, selectUser } from '../../../Redux/selector';
import { getDepartment } from "../../../utils/Services/subscriberConfigurationServices/departmentsServices"
import { getDesignation } from "../../../utils/Services/subscriberConfigurationServices/designationServices"
import { getLocation } from "../../../utils/Services/subscriberConfigurationServices/locationServices"
import { createUserBySubscriber } from '../../../utils/Services/userServices'

const UserRegistration = ({ open, onClose }) => {
    const [value, setValue] = useState(0);
    const [userDetails, setUserDetails] = useState({ firstName: '', lastName: '', personalEmail: '', gender: '', mobileNumber: '', userAddress: '', city: '', state: '', country: '', timeZone: '', profilePic: null });
    const [officeDetails, setOfficeDetails] = useState({ employeeCode: '', employeeType: '', jobTitle: '', workingDayType: '', role: '', department: '', designation: '', workEmail: '', location: '' });
    const [datesDetails, setDatesDetails] = useState({ hireDate: '', dateOfBirth: '', password: '', electronicSignature: null })
    const [departmentDetails, setDepartmentDetails] = useState([])
    const [designationDetails, setDesignationDetails] = useState([])
    const [locationDetails, setLocationDetails] = useState([])
    const [loading, setLoading] = useState(false);
    const token = useSelector(selectToken);
    const user = useSelector(selectUser);
    const { subscriberId } = useParams();
    // const sigCanvas = useRef(null);

    // To fetch Department Details
    const fetchDepartmentDetail = async () => {
        try {
            const response = await getDepartment(token, subscriberId);
            setDepartmentDetails(response?.data || [])
        } catch (error) {
            console.error('Error:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'There was an error. Please try again later.',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
        }
    }
    useEffect(() => {
        fetchDepartmentDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // To fetch Designation Details
    const fetchDesignationDetail = async () => {
        try {
            const response = await getDesignation(token, subscriberId);
            setDesignationDetails(response?.data || [])
        } catch (error) {
            console.error('Error:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'There was an error. Please try again later.',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
        }
    }
    useEffect(() => {
        fetchDesignationDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // To fetch location Details
    const fetchLocationDetail = async () => {
        try {
            const response = await getLocation(token, subscriberId);
            setLocationDetails(response?.data || [])
        } catch (error) {
            console.error('Error:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'There was an error. Please try again later.',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
        }
    }
    useEffect(() => {
        fetchLocationDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Handle Tab Change
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    // Handle Click Next Button
    const handleNext = () => {
        if (value === 0 && !validateUserDetails()) {
            return;
        }
        if (value === 1 && !validateOfficeDetails()) {
            return;
        }
        setValue((prevValue) => Math.min(prevValue + 1, 2));
    }

    // Handle Click Back Button
    const handleBack = () => {
        setValue((prevValue) => Math.max(prevValue - 1, 0));
    };

    // Handle Close Form
    const handleBeforeUnload = () => {
        const hasUserDetails = Object.values(userDetails).some(value => value !== '' && value !== null && !(Array.isArray(value) && value.length === 0) && !(value === null || value === undefined));
        const hasOfficeDetails = Object.values(officeDetails).some(value => value !== '' && value !== null && !(Array.isArray(value) && value.length === 0) && !(value === null || value === undefined));
        const hasDatesDetails = Object.values(datesDetails).some(value => value !== '' && value !== null && !(Array.isArray(value) && value.length === 0) && !(value === null || value === undefined));

        if (hasUserDetails || hasOfficeDetails || hasDatesDetails) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Your entered data will be lost!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#1B4965',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, close it!',
                customClass: {
                    container: 'swal-container',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    resetStates();
                    onClose();
                }
            });
        } else {
            resetStates();
            onClose();
        }
        onClose()
    };

    // Reset the states
    const resetStates = () => {
        setUserDetails({ firstName: '', lastName: '', personalEmail: '', gender: '', mobileNumber: '', userAddress: '', city: '', state: '', country: '', timeZone: '', profilePic: null })
        setOfficeDetails({ employeeCode: '', employeeType: '', jobTitle: '', workingDayType: '', role: '', department: '', designation: '', workEmail: '' })
        setDatesDetails({ hireDate: '', dateOfBirth: '', electronicSignature: '' })
        setValue(0)
    };

    // Step:-1 
    // To Upload Profile Pic
    const { getRootProps: getProfilepicRootProps, getInputProps: getProfilepicInputProps } = useDropzone({
        accept: { 'image/jpeg': [], 'image/png': [], 'image/svg+xml': [] },
        onDrop: (acceptedFiles) => {
            const file = acceptedFiles[0];
            if (file) {
                const fileType = file.type;
                if (fileType.startsWith('image/')) {
                    setUserDetails((userDetails) => ({
                        ...userDetails,
                        profilePic: file,
                    }));
                } else {
                    Swal.fire({
                        title: 'Invalid Image',
                        text: 'Only image files (JPEG, PNG, SVG) are allowed.',
                        icon: 'warning',
                        confirmButtonColor: '#1B4965',
                        customClass: {
                            container: 'swal-container',
                        }
                    });
                }
            }
        },
    });

    // Validate Company Details
    const validateUserDetails = () => {
        const { firstName, lastName, personalEmail, gender } = userDetails;
        if (!firstName || !lastName || !personalEmail || !gender) {
            Swal.fire({
                icon: 'error',
                title: 'Incomplete Information',
                text: 'Please fill in all required fields before proceeding.',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
            return false;
        }
        return true;
    };

    // Step:-2
    // Validate Admin Details
    const validateOfficeDetails = () => {
        const { employeeCode, employeeType, jobTitle, workingDayType, role, workEmail } = officeDetails;
        if (!employeeCode || !employeeType || !jobTitle || !workingDayType || !role || !workEmail) {
            Swal.fire({
                icon: 'error',
                title: 'Incomplete Information',
                text: 'Please fill in all required fields before proceeding.',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
            return false;
        }
        return true;
    };

    // Step:-3
    // To Reset Password
    const resetPassword = () => {
        setDatesDetails({ ...datesDetails, password: '' });
    };

    // To Clear Signature
    // const clearSignature = () => {
    //     sigCanvas.current.clear();
    // };

    // To Save Signature
    // const saveSignature = () => {
    //     const base64String = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');

    //     const byteString = atob(base64String.split(',')[1]);
    //     const mimeString = base64String.split(',')[0].split(':')[1].split(';')[0];

    //     const byteNumbers = new Array(byteString.length);
    //     for (let i = 0; i < byteString.length; i++) {
    //         byteNumbers[i] = byteString.charCodeAt(i);
    //     }
    //     const byteArray = new Uint8Array(byteNumbers);
    //     const blob = new Blob([byteArray], { type: mimeString });
    //     const file = new File([blob], 'signature.png', { type: mimeString });

    //     setDatesDetails(prevState => ({ ...prevState, electronicSignature: file }));
    // };

    // Handle Submit 
    const handleSubmit = async () => {
        setLoading(true)
        try {
            const companyDetails = user?.companyId
            await createUserBySubscriber(token, subscriberId, userDetails, officeDetails, datesDetails, companyDetails);
            Swal.fire({
                icon: 'success',
                title: 'Created Successfully',
                text: 'User Created Successfully.',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
            resetStates();
            onClose();
        } catch (err) {
            console.log(err)
            Swal.fire({
                icon: 'error',
                title: err?.response?.data?.message,
                text: err.response ? err.response.data.message : 'Something went wrong',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
        } finally {
            setLoading(false);
        }
    }

    return (
        <div>
            <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
                <Box className="userRegistration" sx={{ width: '40%', height: 'auto' }}>
                    <div style={{ height: '100%', overflow: 'auto' }}>
                        <Box sx={{ position: 'sticky', top: 0, zIndex: 1, background: 'linear-gradient(90deg, #1B4965 63.83%, #051925 82.97%)', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '2px', textAlign: 'center', borderRadius: '4px' }}>
                            <h2 id="modal-modal-title" style={{ margin: 0, color: 'white', marginLeft: '15px', fontSize: '20px' }}>New User Registration</h2>
                            <IconButton onClick={handleBeforeUnload}>
                                <CloseIcon sx={{ fontSize: '25px', color: 'white' }} />
                            </IconButton>
                        </Box>
                        <Tabs value={value} onChange={handleTabChange} variant="fullWidth" indicatorColor="primary" textColor="primary"
                            sx={{
                                '& .MuiTabs-indicator': {
                                    backgroundColor: '#1B4965',
                                },
                                '& .MuiTab-root.Mui-selected': {
                                    color: '#1B4965',
                                },
                                '& .MuiTab-root': {
                                    color: 'rgba(0, 0, 0, 0.6)',
                                }
                            }}
                        >
                            <Tab label="User Details" />
                            <Tab label="Company Info" />
                            <Tab label="Dates" />
                        </Tabs>
                        <div style={{ padding: '6px' }}>
                            {value === 0 && (
                                <div>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={6}>
                                            <Grid item xs={12}>
                                                <TextField required id="first-name" label="First Name" variant="standard" fullWidth value={userDetails.firstName} onChange={(e) => setUserDetails({ ...userDetails, firstName: e.target.value })} margin="dense" sx={{ '& .MuiInputLabel-root': { fontSize: '12px' }, '& .MuiInputBase-root': { fontSize: '12px' }, width: '92%' }} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField required id="last-name" label="Last Name" variant="standard" fullWidth margin="dense" value={userDetails.lastName} onChange={(e) => setUserDetails({ ...userDetails, lastName: e.target.value })} sx={{ '& .MuiInputLabel-root': { fontSize: '12px' }, '& .MuiInputBase-root': { fontSize: '12px' }, width: '92%' }} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField required id="personalEmail" label="Personal Email" variant="standard" fullWidth margin="dense" value={userDetails.personalEmail} onChange={(e) => setUserDetails({ ...userDetails, personalEmail: e.target.value })} sx={{ '& .MuiInputLabel-root': { fontSize: '12px' }, '& .MuiInputBase-root': { fontSize: '12px' }, width: '92%' }} />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                            {(userDetails?.profilePic) ? (
                                                <>
                                                    {userDetails?.profilePic.name ?
                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <div style={{ height: '180px', width: '180px', textAlign: 'center', borderRadius: '50%', overflow: 'hidden' }}>
                                                                <img alt="Logo" src={typeof userDetails?.profilePic === 'string' ? userDetails?.profilePic : URL.createObjectURL(userDetails?.profilePic)} style={{ height: '100%', width: '100%', objectFit: 'fill' }} />
                                                            </div>
                                                            <div style={{ zIndex: 97, display: 'flex', justifyContent: 'flex-end', marginTop: '-150px' }}>
                                                                <IconButton onClick={() => setUserDetails((prevDetails) => ({ ...prevDetails, profilePic: null }))}>
                                                                    <DeleteForeverIcon sx={{ fontSize: '22px', cursor: 'pointer', }} />
                                                                </IconButton>
                                                            </div>
                                                        </div> : null
                                                    }
                                                </>
                                            ) : (
                                                <div {...getProfilepicRootProps()} style={{ height: '180px', textAlign: 'center', border: '1px dashed #1B4965', borderRadius: '10px', cursor: 'pointer', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                    <input {...getProfilepicInputProps()} />
                                                    <img src={Fileuploadimg} alt="" />
                                                    <p style={{ color: 'rgba(0, 0, 0, 0.6)' }}>Click (or) Drag and Drop your Photo Here.. </p>
                                                </div>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={6}>
                                            <FormControl variant='standard' required fullWidth margin='dense' size='small'>
                                                <InputLabel id="gender">Gender</InputLabel>
                                                <Select labelId='gender' id='gender' label="Gender" value={userDetails.gender} onChange={(e) => setUserDetails({ ...userDetails, gender: e.target.value })} margin="dense" sx={{ '& .MuiInputLabel-root': { fontSize: '12px' }, '& .MuiInputBase-root': { fontSize: '12px' }, width: '92%' }}>
                                                    <MenuItem value="male">Male</MenuItem>
                                                    <MenuItem value="female">Female</MenuItem>
                                                    <MenuItem value="others">Others</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <PhoneInput
                                                country={'in'}
                                                value={userDetails.mobileNumber}
                                                onChange={(value) => setUserDetails({ ...userDetails, mobileNumber: value })}
                                                containerStyle={{ width: '92%', marginTop: '15px', borderRadius: 'none' }}
                                                inputStyle={{ width: '100%', fontSize: '12px', border: 'none', borderBottom: '1px solid #00000099', borderBottomLeftRadius: '0px' }}
                                                buttonStyle={{ border: 'none', backgroundColor: 'white', borderBottom: '1px solid #00000099', borderRadius: 'none', borderBottomRightRadius: '0px' }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <TextField id="userAddress" name="userAddress" label="Address Lane" variant="standard" fullWidth className='TextField' margin="dense" value={userDetails.userAddress} onChange={(e) => setUserDetails({ ...userDetails, userAddress: e.target.value })} sx={{ '& .MuiInputLabel-root': { fontSize: '12px' }, '& .MuiInputBase-root': { fontSize: '12px' }, width: '96%' }} />
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={6}>
                                            <TextField id="city" name="city" label="City" variant="standard" fullWidth className='TextField' margin="dense" value={userDetails.city} onChange={(e) => setUserDetails({ ...userDetails, city: e.target.value })} sx={{ '& .MuiInputLabel-root': { fontSize: '12px' }, '& .MuiInputBase-root': { fontSize: '12px' }, width: '92%' }} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField id="state" name="state" label="State" variant="standard" fullWidth className='TextField' margin="dense" value={userDetails.state} onChange={(e) => setUserDetails({ ...userDetails, state: e.target.value })} sx={{ '& .MuiInputLabel-root': { fontSize: '12px' }, '& .MuiInputBase-root': { fontSize: '12px' }, width: '92%' }} />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={6}>
                                            <TextField id="country" name="country" label="Country" variant="standard" fullWidth className='TextField' margin="dense" value={userDetails.country} onChange={(e) => setUserDetails({ ...userDetails, country: e.target.value })} sx={{ '& .MuiInputLabel-root': { fontSize: '12px' }, '& .MuiInputBase-root': { fontSize: '12px' }, width: '92%' }} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField id="timeZone" name="timeZone" label="Time Zone" variant="standard" fullWidth className='TextField' margin="dense" value={userDetails.timeZone} onChange={(e) => setUserDetails({ ...userDetails, timeZone: e.target.value })} sx={{ '& .MuiInputLabel-root': { fontSize: '12px' }, '& .MuiInputBase-root': { fontSize: '12px' }, width: '92%' }} />
                                        </Grid>
                                    </Grid>
                                </div>
                            )}
                            {value === 1 && (
                                <div>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={6}>
                                            <TextField required id="employeeCode" name="employeeCode" label="Employee Code" variant="standard" fullWidth className='TextField' margin="dense" value={officeDetails.employeeCode} onChange={(e) => setOfficeDetails({ ...officeDetails, employeeCode: e.target.value })} sx={{ '& .MuiInputLabel-root': { fontSize: '12px' }, '& .MuiInputBase-root': { fontSize: '12px' }, width: '96%' }} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant='standard' required fullWidth margin='normal' size='small'>
                                                <InputLabel id="employeeType">Employee Type</InputLabel>
                                                <Select labelId='employeeType' id='employeeType' label="Employee Type" value={officeDetails.employeeType} onChange={(e) => setOfficeDetails({ ...officeDetails, employeeType: e.target.value })} margin="dense" sx={{ '& .MuiInputLabel-root': { fontSize: '12px' }, '& .MuiInputBase-root': { fontSize: '12px' }, width: '92%' }}>
                                                    <MenuItem value="administrator">Administrator</MenuItem>
                                                    <MenuItem value="consultant">Consultant</MenuItem>
                                                    <MenuItem value="contractor">Contractor</MenuItem>
                                                    <MenuItem value="fulltimehourly">Full-time Hourly</MenuItem>
                                                    <MenuItem value="fulltimeinternship">Full-time Internship</MenuItem>
                                                    <MenuItem value="fulltimesalaried">Full-time Salaried</MenuItem>
                                                    <MenuItem value="parttimehourly">Part-time Hourly</MenuItem>
                                                    <MenuItem value="parttimeinternship">Part-time Internship</MenuItem>
                                                    <MenuItem value="parttimesalaried">Part-time Salaried</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <TextField required id="jobTitle" name="jobTitle" label="Job Title" variant="standard" fullWidth className='TextField' margin="dense" value={officeDetails.jobTitle} onChange={(e) => setOfficeDetails({ ...officeDetails, jobTitle: e.target.value })} sx={{ '& .MuiInputLabel-root': { fontSize: '12px' }, '& .MuiInputBase-root': { fontSize: '12px' }, width: '96%' }} />
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={6}>
                                            <FormControl variant='standard' required fullWidth margin='normal' size='small'>
                                                <InputLabel id="workingDayType">Working Day Type</InputLabel>
                                                <Select labelId='workingDayType' id='workingDayType' label="Working Day Type" value={officeDetails.workingDayType} onChange={(e) => setOfficeDetails({ ...officeDetails, workingDayType: e.target.value })} margin="dense" sx={{ '& .MuiInputLabel-root': { fontSize: '12px' }, '& .MuiInputBase-root': { fontSize: '12px' }, width: '92%' }}>
                                                    <MenuItem value="standard">Standard</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant='standard' required fullWidth margin='normal' size='small'>
                                                <InputLabel id="user-role">Role</InputLabel>
                                                <Select labelId='user-role' id='role' label="Role" value={officeDetails.role} onChange={(e) => setOfficeDetails({ ...officeDetails, role: e.target.value })} margin="dense" sx={{ '& .MuiInputLabel-root': { fontSize: '12px' }, '& .MuiInputBase-root': { fontSize: '12px' }, width: '92%' }}>
                                                    <MenuItem value="user">User</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={6}>
                                            <FormControl variant='standard' fullWidth margin='normal' size='small'>
                                                <InputLabel id="department">Department</InputLabel>
                                                <Select labelId='department' id='department' label="Department" value={officeDetails.department} onChange={(e) => setOfficeDetails({ ...officeDetails, department: e.target.value })} margin="dense" sx={{ '& .MuiInputLabel-root': { fontSize: '12px' }, '& .MuiInputBase-root': { fontSize: '12px' }, width: '92%' }}>
                                                    {departmentDetails && departmentDetails.length > 0 ? (
                                                        departmentDetails.map((department) => (
                                                            <MenuItem key={department._id} value={department._id}>
                                                                {department.departmentName}
                                                            </MenuItem>
                                                        ))
                                                    ) : (
                                                        <MenuItem value="" disabled>
                                                            No Departments
                                                        </MenuItem>
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant='standard' fullWidth margin='normal' size='small'>
                                                <InputLabel id="designation">Designation</InputLabel>
                                                <Select labelId='designation' id='designation' label="Designation" value={officeDetails.designation} onChange={(e) => setOfficeDetails({ ...officeDetails, designation: e.target.value })} margin="dense" sx={{ '& .MuiInputLabel-root': { fontSize: '12px' }, '& .MuiInputBase-root': { fontSize: '12px' }, width: '92%' }}>
                                                    {designationDetails && designationDetails.length > 0 ? (
                                                        designationDetails?.map((designation) => (
                                                            <MenuItem key={designation._id} value={designation._id}>
                                                                {designation.designationName}
                                                            </MenuItem>
                                                        ))
                                                    ) : (
                                                        <MenuItem value="" disabled>
                                                            No Designations
                                                        </MenuItem>
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={6}>
                                            <TextField required id="workEmail" label="Work Email" variant="standard" fullWidth value={officeDetails.workEmail} onChange={(e) => setOfficeDetails({ ...officeDetails, workEmail: e.target.value })} margin="dense" sx={{ '& .MuiInputLabel-root': { fontSize: '12px' }, '& .MuiInputBase-root': { fontSize: '12px' }, width: '92%' }} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant='standard' fullWidth margin='normal' size='small'>
                                                <InputLabel id="location">Location / Branch</InputLabel>
                                                <Select labelId='location' id='location' label="Location / Branch" value={officeDetails.location} onChange={(e) => setOfficeDetails({ ...officeDetails, location: e.target.value })} margin="dense" sx={{ '& .MuiInputLabel-root': { fontSize: '12px' }, '& .MuiInputBase-root': { fontSize: '12px' }, width: '92%' }}>
                                                    {locationDetails && locationDetails.length > 0 ? (
                                                        locationDetails?.map((location) => (
                                                            <MenuItem key={location._id} value={location._id}>
                                                                {location.branchName}
                                                            </MenuItem>
                                                        ))
                                                    ) : (
                                                        <MenuItem value="" disabled>
                                                            No Branches
                                                        </MenuItem>
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </div>
                            )}
                            {value === 2 && (
                                <div>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={6}>
                                            <TextField id="hireDate" name="hireDate" label="Hire Date" type='date' variant="outlined" fullWidth className='TextField' margin="dense" value={datesDetails.hireDate} onChange={(e) => setDatesDetails({ ...datesDetails, hireDate: e.target.value })} sx={{ '& .MuiInputLabel-root': { fontSize: '12px' }, '& .MuiInputBase-root': { fontSize: '12px' }, width: '100%' }} InputLabelProps={{ shrink: true }} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField id="dateOfBirth" name="dateOfBirth" label="Date Of Birth" type='date' variant="outlined" fullWidth className='TextField' margin="dense" value={datesDetails.dateOfBirth} onChange={(e) => setDatesDetails({ ...datesDetails, dateOfBirth: e.target.value })} sx={{ '& .MuiInputLabel-root': { fontSize: '12px' }, '& .MuiInputBase-root': { fontSize: '12px' }, width: '100%' }} InputLabelProps={{ shrink: true }} />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} alignItems="center" sx={{ marginTop: '0px' }}>
                                        <Grid item xs={6}>
                                            <TextField required id="password" label="Password" variant="outlined" fullWidth value={datesDetails.password} onChange={(e) => setDatesDetails({ ...datesDetails, password: e.target.value })} margin="dense" sx={{ '& .MuiInputLabel-root': { fontSize: '12px' }, '& .MuiInputBase-root': { fontSize: '12px' }, width: '100%' }} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            {datesDetails.password && <Button variant="outlined" sx={{ color: '#d32f2f', border: '1px solid #d32f2f', marginLeft: '10px' }} onClick={resetPassword}>Reset</Button>}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {/* <div style={{ border: '1px solid #ccc', marginTop: '20px', width: '96%', padding: '10px' }}>
                                            <SignatureCanvas
                                                ref={sigCanvas}
                                                penColor='black'
                                                canvasProps={{ width: 500, height: 180, className: 'sigCanvas' }}
                                            />
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Button onClick={clearSignature} variant='outlined' sx={{ color: '#1B4965', border: '1px solid #1B4965' }}>Clear</Button>
                                                <Button onClick={saveSignature} variant='outlined' sx={{ color: '#1B4965', border: '1px solid #1B4965' }}>Save</Button>
                                            </div>
                                        </div> */}
                                    </Grid>
                                </div>
                            )}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            {value !== 0 ? (
                                <Button onClick={handleBack} variant='outlined' sx={{ color: '#1B4965', border: '1px solid #1B4965' }}>Back</Button>
                            ) : (
                                <Button onClick={handleBeforeUnload} variant='outlined' sx={{ color: '#1B4965', border: '1px solid #1B4965' }}>Cancel</Button>
                            )}
                            {value !== 2 ? (
                                <Button onClick={handleNext} variant="contained" sx={{ background: 'linear-gradient(90deg, #1B4965 63.83%, #051925 82.97%)', backgroundSize: '200%' }}>Next</Button>
                            ) : (
                                <Button onClick={handleSubmit} variant="contained" sx={{ background: 'linear-gradient(90deg, #1B4965 63.83%, #051925 82.97%)', backgroundSize: '200%' }}>Submit</Button>
                            )}
                        </div>
                    </div>
                </Box>
            </Modal>
            {(loading) && (
                <Loader />
            )}
        </div>
    )
}

export default UserRegistration
