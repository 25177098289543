import axios from 'axios';
const backEndUrl = `${process.env.REACT_APP_BACKEND_URL}/api/subscriber-configuration/department`;

// To Create Department
export const createDepartment = (token, subscriberId, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.post(`${backEndUrl}/create-department/${subscriberId}`, data, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// To get Department based on subscriber
export const getDepartment = (token, subscriberId) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.get(`${backEndUrl}/get-department/${subscriberId}`, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// To get Department data with members
export const getDepartmentDataWithMembers = (token, departmentId) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.get(`${backEndUrl}/get-department-data/${departmentId}`, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// To update Department 
export const updateDepartment = (token, departmentId, departmentDetails) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.put(`${backEndUrl}/update-department/${departmentId}`, departmentDetails, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// To Delete Department
export const deleteDepartment = (token, departmentId) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.delete(`${backEndUrl}/delete-department/${departmentId}`, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};