import { useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import Picker from '@emoji-mart/react'
import data from '@emoji-mart/data'
import { Modal, Typography, IconButton, Button, TextField, FormControl, Select, MenuItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';

import './AddStatusModal.css';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../../Redux/selector';
import Loader from "../../../../UiUxComponents/Loader/Loader";
import { addNewStatus, getNewStatus, deleteNewStatus, currentStatus } from '../../../../../utils/Services/statusServices'

const AddStatusModal = ({ open, onClose }) => {
  const [addStatus, setAddStatus] = useState('');
  const [selectedAddStatus, setSelectedAddStatus] = useState(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [selectedEmoji, setSelectedEmoji] = useState('😊');
  const [selectedTime, setSelectedTime] = useState('30 minutes');
  const [userStatuses, setUserStatuses] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = useSelector(selectUser);
  const userId = user ? user._id : null;
  const emojiPickerRef = useRef(null);

  // Predefined Status
  let predefinedStatuses = [
    { icon: '👟', label: 'Be right back', time: '30 minutes', predefined: true },
    { icon: '🚗', label: 'Commuting', time: '1 hour', predefined: true },
    { icon: '🤒', label: 'Off sick', time: '2 hours', predefined: true },
    { icon: '🌴', label: 'On holidays', time: '24 hours', predefined: true }
  ];

  // To get the already existed status
  useEffect(() => {
    const fetchUserStatus = async () => {
      try {
        setLoading(true);
        const response = await getNewStatus(userId);
        let updatedStatuses = []
        if (response.status === 202) {
          updatedStatuses = [...predefinedStatuses];
        }
        else {
          const statuses = response?.data?.map(status => ({
            icon: status.icon,
            label: status.label,
            time: status.time,
            id: status._id,
            predefined: false
          }));
          updatedStatuses = [...predefinedStatuses, ...statuses];
        }
        setUserStatuses(updatedStatuses);
      } catch (err) {
        console.error('Error fetching company details:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchUserStatus();
  }, [userId]);

  // To Reset the states after form closes
  useEffect(() => {
    if (!open) {
      setAddStatus('');
      setSelectedAddStatus(null);
      setShowEmojiPicker(false);
      setSelectedEmoji('😊');
      setSelectedTime('30 minutes');
      setUserStatuses([])
    }
  }, [open]);

  // Handle to write the new status
  const handleStatusChange = (e) => {
    setAddStatus(e.target.value);
  };

  // Hanlde to change the time
  const handleTimeChange = (e, index) => {
    const newTime = e.target.value;
    setUserStatuses((prevStatuses) => {
      const updatedStatuses = [...prevStatuses];
      updatedStatuses[index] = {
        ...updatedStatuses[index],
        time: newTime,
      };
      return updatedStatuses;
    });
  };

  // Hande to update status
  const handleAddStatusSelect = async (status) => {
    setSelectedAddStatus(status);
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: `You are about to select the status: ${status.label}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#1B4965',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, select it!',
      cancelButtonText: 'No, cancel!',
      customClass: {
        container: 'swal-container',
      }
    });

    if (result.isConfirmed) {
      try {
        await currentStatus({ icon: status.icon, label: status.label, time: status.time }, userId);
        Swal.fire({
          icon: 'success',
          title: 'Status Selected',
          text: 'Your status has been successfully updated.',
          confirmButtonColor: '#1B4965',
          customClass: {
            container: 'swal-container',
          }
        });
      } catch (error) {
        console.error('Error sending status to API:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'There was an error updating your status. Please try again later.',
          confirmButtonColor: '#1B4965',
          customClass: {
            container: 'swal-container',
          }
        });
      }
    }
  };

  // Handle to create new status
  const handleCreate = async () => {
    const status = addStatus || selectedAddStatus?.label;
    const emoji = selectedEmoji;

    if (status) {
      setLoading(true);
      try {
        await addNewStatus({ userId: userId, icon: emoji, label: status, time: selectedTime });
        setUserStatuses((prevStatuses) => [
          ...prevStatuses,
          { icon: emoji, label: status, time: selectedTime }
        ]);
      } catch (error) {
        console.error('Error adding new status:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'There was an error creating your status. Please try again later.',
          confirmButtonColor: '#1B4965',
          customClass: {
            container: 'swal-container',
          }
        });
      } finally {
        setLoading(false);
      }
      setAddStatus('');
      setSelectedAddStatus(null);
      setSelectedEmoji('😊');
      setSelectedTime('30 minutes');
    }
  };

  // Handle deleting status
  const handleDelete = async (e, statusLabel, statusId) => {
    e.stopPropagation();
    if (statusId) {
      try {
        setLoading(true);
        await deleteNewStatus(userId, statusId);
        setUserStatuses(prevStatuses => prevStatuses.filter(status => status.label !== statusLabel));
        Swal.fire({
          icon: 'success',
          title: 'Deleted',
          text: 'The status has been deleted successfully.',
          confirmButtonColor: '#1B4965',
          customClass: {
            container: 'swal-container',
          }
        });
      } catch (error) {
        console.error('Error deleting status:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'There was an error deleting the status. Please try again later.',
          confirmButtonColor: '#1B4965',
          customClass: {
            container: 'swal-container',
          }
        });
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
        setShowEmojiPicker(false);
      }
    };

    if (showEmojiPicker) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showEmojiPicker]);

  return (
    <div>
      <Modal open={open} onClose={onClose}>
        <div className="addStatus-modal">
          <div className="addStatus-modal-header">
            <Typography variant="h6">Add Your Own Status</Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <span onClick={() => setShowEmojiPicker(!showEmojiPicker)} style={{ marginLeft: '10px', position: 'absolute', marginTop: '5px', cursor: 'pointer', zIndex: 6 }}>
              {selectedEmoji}
            </span>
            <TextField placeholder="Write your own" fullWidth variant="outlined" margin="dense" value={addStatus} onChange={handleStatusChange} inputProps={{ maxLength: 64 }}
              InputProps={{
                style: { paddingLeft: '30px' }
              }} />
          </div>
          {showEmojiPicker && (
            <div ref={emojiPickerRef} style={{ position: 'absolute', zIndex: 2 }}>
              <Picker data={data} theme="light" onEmojiSelect={(emoji) => setSelectedEmoji(emoji.native)} />
            </div>
          )}
          <div className="predefined-statuses">
            {userStatuses?.map((item, index) => (
              <div key={index} className={`addStatus-option ${selectedAddStatus?.label === item.label ? 'selected' : ''}`} onClick={() => handleAddStatusSelect(item)} role="button" aria-label={`Select status: ${item.label}`}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <span className="addStatus-icon">{item.icon}</span>
                  <span className="addStatus-label">{item.label}</span>
                </div>
                <div>
                  <FormControl variant="standard" size="small" sx={{ marginTop: '3px' }}>
                    <Select value={item.time} onChange={(e) => handleTimeChange(e, index)} style={{ minWidth: 100 }} onClick={(e) => e.stopPropagation()}>
                      <MenuItem value="15 minutes">15 minutes</MenuItem>
                      <MenuItem value="30 minutes">30 minutes</MenuItem>
                      <MenuItem value="45 minutes">45 minutes</MenuItem>
                      <MenuItem value="1 hour">1 hour</MenuItem>
                      <MenuItem value="2 hours">2 hours</MenuItem>
                      <MenuItem value="4 hours">4 hours</MenuItem>
                      <MenuItem value="8 hours">8 hours</MenuItem>
                      <MenuItem value="12 hours">12 hours</MenuItem>
                      <MenuItem value="24 hours">24 hours</MenuItem>
                    </Select>
                  </FormControl>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!item.predefined) {
                        handleDelete(e, item.label, item.id);
                      }
                    }}
                    style={{ marginLeft: '5px', color: item.predefined ? 'gray' : 'red', cursor: item.predefined ? 'default' : 'pointer' }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              </div>
            ))}
          </div>
          <div className="addStatus-modal-actions">
            <Button onClick={handleDelete} variant="outlined" style={{ color: '#051925' }}>Delete Status</Button>
            <Button onClick={handleCreate} variant="outlined" disabled={!addStatus && !selectedAddStatus} style={{ backgroundColor: '#1B4965', color: '#fff' }}>Create New</Button>
          </div>
        </div>
      </Modal>
      {(loading) && (
        <Loader />
      )}
    </div>
  );
};

export default AddStatusModal;
