import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from 'styled-components';

import Swal from 'sweetalert2';

import Loader from "../../../../../UiUxComponents/Loader/Loader";
import { selectToken } from "../../../../../../Redux/selector";
import { getDepartmentDataWithMembers } from "../../../../../../utils/Services/subscriberConfigurationServices/departmentsServices";

function DepartmentMembers() {
  const [departmentUsers, setDepartmentUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = useSelector(selectToken);
  const { departmentId } = useParams();

  // To get department details
  const fetchDepartmentDetails = async () => {
    setLoading(true);
    try {
      const response = await getDepartmentDataWithMembers(token, departmentId);
      setDepartmentUsers(response?.data?.members || []);
    } catch (err) {
      console.error('Error fetching department details:', err);
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: 'Failed to fetch department details',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDepartmentDetails();
  }, []);

  return (
    <Container>
      {loading ? (
        <Loader />
      ) : (
        <TableWrapper>
          <Table>
            <thead>
              <tr>
                <Th>Full Name</Th>
                <Th>Work Email</Th>
                <Th>Role</Th>
              </tr>
            </thead>
            <tbody>
              {departmentUsers.map(user => (
                <tr key={user._id}>
                  <Td>{user.fullName}</Td>
                  <Td>{user.workEmail}</Td>
                  <Td>{user.role}</Td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableWrapper>
      )}
    </Container>
  );
}

export default DepartmentMembers;

const Container = styled.div`
  padding: 20px;
  height: 70vh;
  overflow: hidden;
`;

const TableWrapper = styled.div`
  max-height: 100%;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #1B4965 #f0f0f0;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #1B4965;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background: #f0f0f0;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  padding: 10px;
  background-color: #1B4965;
  color: #fff;
  text-align: left;
`;

const Td = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
`;