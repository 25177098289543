import axios from 'axios';
const backEndUrl = `${process.env.REACT_APP_BACKEND_URL}/api/attendance`;

// To Add user attendance
export const addAttendance = (token, userId, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.post(`${backEndUrl}/add-attendance/${userId}`, data, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// To Get Current Day Attendance Status
export const getCurrentDayAttendanceStatus = (token, userId, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            };
            let response = await axios.get(`${backEndUrl}/get-current-day-attendance-status/${userId}`, {
                headers,
                params: data
            });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// To Get Specific User attendance based on selected dates
export const getSpecificUserAttendance = (token, userId, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.get(`${backEndUrl}/get-specific-attendance/${userId}`, {
                headers,
                params: data
            });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// Generate Average ClockIn and ClockOut Time for the Week and Month
export const generateAverageAttendanceStats = (token, userId, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.get(`${backEndUrl}/generate-average-attendance-stats/${userId}`, {
                headers,
                params: data
            });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// To Get All User Attendance related to specific company
export const getAllUserAttendanceData = (token, subscriberId, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.get(`${backEndUrl}/get-all-attendance/${subscriberId}`, {
                headers,
                params: data
            });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};