import React from 'react';
import './Pagination.css'
import { IconButton } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

const Pagination = ({ count, page, rowsPerPage, onPageChange, onRowsPerPageChange }) => {
    const iconButtonStyle = {
        cursor: 'pointer',
        color: '#1B4965',
        fontSize: '14px',
        padding: '5px',
    };

    const arrowIconStyle = {
        fontSize: '16px'
    }


    return (
        <div className="custom-pagination">
            <div><span></span></div>
            <div style={{ display: 'flex', marginTop: '8px' }}>
                <div style={{ background: '#4575ac38', marginRight: '15px', borderRadius: '7px', color: 'white' }}>
                    <IconButton onClick={(event) => onPageChange(event, 0)} disabled={page === 0} aria-label="first page" style={iconButtonStyle}>
                        <FirstPageIcon style={arrowIconStyle} />
                    </IconButton>
                    <IconButton onClick={(event) => onPageChange(event, page - 1)} disabled={page === 0} aria-label="previous page" style={iconButtonStyle}>
                        <KeyboardArrowLeft style={arrowIconStyle} />
                    </IconButton>
                    {[...Array(Math.ceil(count / rowsPerPage))].map((_, index) => (
                        <IconButton key={index} onClick={(event) => onPageChange(event, index)} disabled={page === index} style={iconButtonStyle}>
                            {index + 1}
                        </IconButton>
                    ))}
                    <IconButton onClick={(event) => onPageChange(event, page + 1)} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page" style={iconButtonStyle}>
                        <KeyboardArrowRight style={arrowIconStyle} />
                    </IconButton>
                    <IconButton onClick={(event) => onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page" style={iconButtonStyle}>
                        <LastPageIcon style={arrowIconStyle} />
                    </IconButton>
                </div>
                <div className="rows-per-page">
                    Rows Per Page:
                    <select value={rowsPerPage} onChange={onRowsPerPageChange}>
                        {[5, 10, 25].map((rowsPerPageOption) => (
                            <option key={rowsPerPageOption} value={rowsPerPageOption}>
                                {rowsPerPageOption}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );
};

export default Pagination;
