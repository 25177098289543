import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Swal from 'sweetalert2';
import { Avatar, Box, Button, Card, CardContent, Divider, Grid, LinearProgress, Paper, Popover, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { CalendarToday, CheckCircle, DateRange, Info } from '@mui/icons-material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';

import Loader from '../../../Components/UiUxComponents/Loader/Loader';
import { eachDayOfInterval, endOfMonth, endOfWeek, format, isSaturday, isSunday, normalizeDate, parseClockInTime, startOfMonth, startOfWeek } from '../../../utils/CustomPackages/dateUtils';
import { selectToken } from '../../../Redux/selector';
import TimePeriod from '../../../Components/UiUxComponents/CustomComponents/TimePeriod';
import { addAttendance, getCurrentDayAttendanceStatus, getSpecificUserAttendance, generateAverageAttendanceStats } from '../../../utils/Services/attendanceServices';
import { getShift } from '../../../utils/Services/shiftServices';

function Attendance() {
  const [currentTime, setCurrentTime] = useState(new Date().toLocaleTimeString());
  const [currentDate, setCurrentDate] = useState(format(new Date(), 'EEE dd, MMM yyyy'));
  const [selectedButton, setSelectedButton] = useState('Remote Clock-in');
  const [currentAttendanceStatus, setCurrentAttendanceStatus] = useState([]);
  const [userAttendanceData, setUserAttendanceData] = useState([]);
  const [shiftData, setShiftData] = useState([]);
  const [weeklyData, setWeeklyData] = useState([]);
  const [monthlyData, setMonthlyData] = useState([]);
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [startDate, setStartDate] = useState(startOfWeek(new Date(), { weekStartsOn: 1 }));
  const [endDate, setEndDate] = useState(endOfWeek(new Date(), { weekStartsOn: 1 }));
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const token = useSelector(selectToken);
  const { userId } = useParams();

  // To Get Current Day Attendance Status
  const fetchCurrentDayAttendanceStatus = async () => {
    setLoading(true);
    try {
      const currentDateTime = new Date();
      const day = String(currentDateTime.getDate()).padStart(2, '0');
      const month = String(currentDateTime.getMonth() + 1).padStart(2, '0');
      const year = currentDateTime.getFullYear();
      const formattedDateTime = `${day}/${month}/${year}`
      const data = { formattedDateTime };

      const response = await getCurrentDayAttendanceStatus(token, userId, data);
      const todayRecords = response?.data?.data;
      if (todayRecords) {
        setCurrentAttendanceStatus(todayRecords);
      } else {
        console.log("Today Records is empty")
      }
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Failed ',
        text: 'Failed to fetch user attendance',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchCurrentDayAttendanceStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // To get user shifts Data
  const fetchShiftDetails = async () => {
    setLoading(true);
    try {
      const response = await getShift(token, userId);
      setShiftData(response?.data || [])
    } catch (err) {
      console.error('Error fetching user shift:', err);
      Swal.fire({
        icon: 'error',
        title: 'Failed ',
        text: 'Failed to fetch user shift',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchShiftDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // To get the default user attendance data
  const fetchUserAttendanceData = async () => {
    setLoading(true);
    try {
      const data = { startDate, endDate };

      const options = { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone };

      const formattedData = {
        startDate: new Date(data.startDate).toLocaleString('en-GB', options),
        endDate: new Date(data.endDate).toLocaleString('en-GB', options)
      };

      const response = await getSpecificUserAttendance(token, userId, formattedData);
      setUserAttendanceData(response?.data?.attendance || [])
    } catch (error) {
      console.error('Error fetching attendance data:', error);
      Swal.fire({
        icon: 'error',
        title: 'Failed ',
        text: 'Failed to fetch user attendance',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchUserAttendanceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  // To get Average Attendance Stats
  const fetchUserAverageAttendanceStats = async () => {
    setLoading(true);
    try {
      const weekStart = startOfWeek(new Date(), { weekStartsOn: 1 });
      const weekEnd = endOfWeek(new Date(), { weekStartsOn: 1 });
      const monthStartDate = startOfMonth(new Date());
      const monthEndDate = endOfMonth(new Date());

      const data = { weekStart, weekEnd, monthStartDate, monthEndDate };
      const options = { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone };
      const formattedData = {
        startDate: new Date(data.weekStart).toLocaleString('en-GB', options),
        endDate: new Date(data.weekEnd).toLocaleString('en-GB', options),
        monthStart: new Date(data.monthStartDate).toLocaleString('en-GB', options),
        monthEnd: new Date(data.monthEndDate).toLocaleString('en-GB', options),
      };
      const response = await generateAverageAttendanceStats(token, userId, formattedData);
      setWeeklyData(response?.data?.weeklyData)
      setMonthlyData(response?.data?.monthlyData)
    } catch (error) {
      console.error('Error fetching attendance data:', error);
      Swal.fire({
        icon: 'error',
        title: 'Failed ',
        text: 'Failed to fetch user attendance',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchUserAverageAttendanceStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update current time and date every second
  useEffect(() => {
    const timer = setInterval(() => {
      const newTime = new Date().toLocaleTimeString();
      const newDate = format(new Date(), 'EEE dd, MMM yyyy');
      if (newTime !== currentTime) {
        setCurrentTime(newTime);
      }
      if (newDate !== currentDate) {
        setCurrentDate(newDate);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [currentTime, currentDate]);

  // Calculate average gross hours for the current week or month
  function getAverageTime(array) {
    let totalMinutes = 0;

    array.forEach(time => {
      const [hours, minutes, seconds] = time.split(':').map(Number);
      totalMinutes += hours * 60 + minutes + seconds / 60;
    });
    const averageMinutes = totalMinutes / array.length;
    const avgHours = Math.floor(averageMinutes / 60);
    const avgMinutes = Math.floor(averageMinutes % 60);
    const avgSeconds = Math.round((averageMinutes % 1) * 60);

    return `${String(avgHours).padStart(2, '0')}:${String(avgMinutes).padStart(2, '0')}:${String(avgSeconds).padStart(2, '0')}`;
  }

  // To Calculate Weekly Clockin Average
  const calculateWeeklyClockInAverage = () => {
    const weekRecords = weeklyData.filter(record => !record.isWeekend && record.clockInTime);
    if (weekRecords.length === 0) {
      return "0:00";
    }
    const clockInTimes = weekRecords.map(record => (record.clockInTime));
    const clockIn = clockInTimes.map(timeString => timeString.split(" ")[1]);
    const averageClockInTime = getAverageTime(clockIn)

    return averageClockInTime;
  };

  // To Calculate Weekly ClockOut Average
  const calculateWeeklyClockOutAverage = () => {
    const weekRecords = weeklyData.filter(record => !record.isWeekend && record.clockOutTime);
    if (weekRecords.length === 0) {
      return "0:00";
    }
    const clockOutTimes = weekRecords.map(record => (record.clockOutTime));
    const clockOut = clockOutTimes.map(timeString => timeString.split(" ")[1]);
    const averageClockOutTime = getAverageTime(clockOut)
    return averageClockOutTime;
  };

  // To Calculate Monthly Clockin Average
  const calculateMonthlyClockInAverage = () => {
    const monthRecords = monthlyData.filter(record => !record.isWeekend && record.clockInTime);

    if (monthRecords.length === 0) {
      return "0:00";
    }
    const clockInTimes = monthRecords.map(record => (record.clockInTime));
    const clockIn = clockInTimes.map(timeString => timeString.split(" ")[1]);
    const averageClockInTime = getAverageTime(clockIn)
    return averageClockInTime;
  };

  // To Calculate Monthly ClockOut Average
  const calculateMonthlyClockOutAverage = () => {
    const monthRecords = monthlyData.filter(record => !record.isWeekend && record?.clockOutTime);
    if (monthRecords.length === 0) {
      return "0:00";
    }
    const clockOutTimes = monthRecords.map(record => (record.clockOutTime));
    const clockOut = clockOutTimes.map(timeString => timeString.split(" ")[1]);
    const averageClockOutTime = getAverageTime(clockOut)
    return averageClockOutTime;
  };

  // To Generate Weekly Days
  const generateWeeklyDays = () => {
    const now = new Date();
    const start = startOfWeek(now, { weekStartsOn: 1 });
    const end = endOfWeek(now, { weekStartsOn: 1 });
    return eachDayOfInterval(start, end);
  };

  // Handle date change 
  const handleDateRangeChange = (start, end) => {
    if (start) setStartDate(start);
    if (end) setEndDate(end);
  };

  // To Generate Date for Table
  const generateCustomDatesForTable = () => {
    const start = startDate
    const end = endDate
    return eachDayOfInterval(start, end, "weekdates");

  };

  // To Display Data in table
  const generateRecords = () => {
    const dates = generateCustomDatesForTable();
    return dates.map(date => {
      const isWeekend = isSaturday(date) || isSunday(date);
      const record = userAttendanceData.find(r => {
        const clockInDate = normalizeDate(parseClockInTime(r.clockInTime));
        const comparisonDate = normalizeDate(date);
        return format(clockInDate, 'yyyy-MM-dd') === format(comparisonDate, 'yyyy-MM-dd');
      });
      const shiftRecord = shiftData.find(s =>
        format(normalizeDate(new Date(s.date)), 'yyyy-MM-dd') === format(normalizeDate(date), 'yyyy-MM-dd')
      );
      let tag = null;
      if (shiftRecord) {
        if (shiftRecord.shiftType === 'weekOff') {
          tag = "W-Off";
        } else if (shiftRecord.shiftType === 'leave') {
          tag = "Leave";
        }
      }
      return { date, record, isWeekend, tag };
    });
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'mouse-over-popover' : undefined;

  const filteredRecords = generateRecords();
  const attendanceStatus = currentAttendanceStatus.length > 0 ? currentAttendanceStatus[0].attendanceStatus : 'Clock-in';

  // handle clockout button
  const handleClockInOut = useCallback(async () => {
    const currentDateTime = new Date();
    const day = String(currentDateTime.getDate()).padStart(2, '0');
    const month = String(currentDateTime.getMonth() + 1).padStart(2, '0');
    const year = currentDateTime.getFullYear();

    const hours = String(currentDateTime.getHours()).padStart(2, '0');
    const minutes = String(currentDateTime.getMinutes()).padStart(2, '0');
    const seconds = String(currentDateTime.getSeconds()).padStart(2, '0');

    const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;

    const getLocation = () => {
      return new Promise((resolve) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => resolve(position.coords),
            () => resolve(null) // Resolve with null if permission is denied or there's an error
          );
        } else {
          resolve(null); // Resolve with null if geolocation is not supported
        }
      });
    };
    try {
      const coords = await getLocation();
      const latitude = coords?.latitude || 'N/A'; // Use 'N/A' or a default value if not available
      const longitude = coords?.longitude || 'N/A';
      const data = { attendanceStatus, time: formattedDateTime, place: selectedButton, location: { latitude, longitude } };
      if (attendanceStatus === 'Clock-out') {
        const confirmation = await Swal.fire({
          icon: 'warning',
          title: 'Are you sure?',
          text: 'Do you want to clock out?',
          showCancelButton: true,
          confirmButtonColor: '#1B4965',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Clock out!',
        });
        if (!confirmation.isConfirmed) {
          return;
        }
      }
      setLoading(true);
      await addAttendance(token, userId, data);
      Swal.fire({
        icon: 'success',
        title: `${attendanceStatus} Success`,
        confirmButtonColor: '#1B4965',
      });
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: err?.response?.data?.message || 'Failed to Save Attendance',
        confirmButtonColor: '#1B4965',
      });
    } finally {
      setLoading(false);
      fetchCurrentDayAttendanceStatus();
      fetchShiftDetails();
      fetchUserAttendanceData();
      fetchUserAverageAttendanceStats();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attendanceStatus])

  function parseDate(dateString) {
    const [datePart, timePart] = dateString.split(' ');
    const [day, month, year] = datePart.split('/');
    const [hours, minutes, seconds] = timePart.split(':');

    return new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));
  }

  return (
    <span>
      <Box sx={{ padding: 2, background: '#f1f1f1', maxHeight: '85vh', overflow: 'auto' }}>
        <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'stretch' }}>
          <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Card sx={{ padding: 2, flexGrow: 1 }}>
              <Typography variant="h6" sx={{ marginBottom: 2 }}>Attendance Stats</Typography>
              <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ marginBottom: 2 }}>
                <Avatar sx={{ backgroundColor: '#FFD700', marginRight: 1, width: 30, height: 30 }}>
                  <CalendarToday />
                </Avatar>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography sx={{ fontSize: '15px' }}>This Week</Typography>
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography sx={{ fontSize: '12px' }} color="textSecondary">AVG CLOCK-IN</Typography>
                  <Typography sx={{ fontSize: '20px' }}>{calculateWeeklyClockInAverage()}</Typography>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: '12px' }} color="textSecondary">AVG CLOCK-OUT</Typography>
                  <Typography sx={{ fontSize: '20px' }}>{calculateWeeklyClockOutAverage()}</Typography>
                </Box>
              </Box>
              <Divider sx={{ marginBottom: 2 }} />
              <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ marginBottom: 2 }}>
                <Avatar sx={{ backgroundColor: '#00BFFF', marginRight: 1, width: 30, height: 30 }}>
                  <DateRange />
                </Avatar>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography sx={{ fontSize: '15px' }}>This Month</Typography>
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography sx={{ fontSize: '12px' }} color="textSecondary">AVG CLOCK-IN</Typography>
                  <Typography sx={{ fontSize: '20px' }}>{calculateMonthlyClockInAverage()}</Typography>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: '12px' }} color="textSecondary">AVG CLOCK-OUT</Typography>
                  <Typography sx={{ fontSize: '20px' }}>{calculateMonthlyClockOutAverage()}</Typography>
                </Box>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Card sx={{ flexGrow: 1 }}>
              <CardContent>
                <Typography variant="h6">Timings</Typography>
                <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ marginBottom: 2, marginTop: 1 }}>
                  {generateWeeklyDays().map((date, index) => {
                    const dayName = format(date, 'EEE').charAt(0);
                    const isSelected = selectedDay.toDateString() === date.toDateString();
                    return (
                      <Button key={index} variant={isSelected ? 'contained' : 'outlined'} onClick={() => setSelectedDay(date)}
                        sx={{ minWidth: 34, height: 36, borderRadius: '50%', borderColor: isSelected ? 'transparent' : '#1B4965', backgroundColor: isSelected ? '#1B4965' : '#F1F1F1', color: isSelected ? 'white' : '#1B4965', '&:hover': { backgroundColor: '#1B4965', color: 'white' } }}
                      >
                        {dayName}
                      </Button>
                    );
                  })}
                </Box>
                {(() => {
                  const shiftRecord = shiftData.find(record => format(new Date(record.date), 'yyyy-MM-dd') === format(selectedDay, 'yyyy-MM-dd'));
                  let shiftTimingText = "Shift Not Added";

                  const todayRecords = weeklyData.filter(record => {
                    const recordDate = parseDate(record.clockInTime);
                    const selectedDate = new Date(selectedDay);
                    return (
                      recordDate.getUTCFullYear() === selectedDate.getUTCFullYear() &&
                      recordDate.getUTCMonth() === selectedDate.getUTCMonth() &&
                      recordDate.getUTCDate() === selectedDate.getUTCDate()
                    );
                  });

                  let clockInTime = "00:00";
                  let clockOutTime = "00:00";
                  let duration = "00:00";

                  if (todayRecords.length > 0) {
                    const latestRecord = todayRecords[todayRecords.length - 1];

                    clockInTime = latestRecord && latestRecord.clockInTime
                      ? latestRecord.clockInTime.split(' ')[1]
                      : '-';

                    if (latestRecord?.clockOutTime) {
                      clockOutTime = latestRecord && latestRecord.clockOutTime
                        ? latestRecord.clockOutTime.split(' ')[1]
                        : '-';
                      duration = latestRecord?.grossHours;
                    }
                  }

                  if (shiftRecord) {
                    if (shiftRecord?.shiftType === "shift" && shiftRecord?.shiftData) {
                      const startTime = shiftRecord?.shiftData?.startTime;
                      const endTime = shiftRecord?.shiftData?.endTime;
                      shiftTimingText = `Shift Timings :- (${startTime} - ${endTime})`;
                    } else if (shiftRecord.shiftType === "weekOff") {
                      shiftTimingText = "Week Off";
                    } else if (shiftRecord.shiftType === "leave") {
                      shiftTimingText = "Leave";
                    } else if (shiftRecord.shiftType === "default") {
                      const startTime = shiftRecord?.startTime;
                      const endTime = shiftRecord?.endTime;
                      shiftTimingText = `Shift Timings :- (${startTime} - ${endTime})`;
                    }
                  }

                  return (
                    <Box>
                      <Typography variant="body1">Today ({clockInTime} - {clockOutTime})</Typography>
                      <Box sx={{ marginY: 1 }}>
                        <LinearProgress variant="determinate" value={75}
                          sx={{
                            height: 8,
                            borderRadius: 5,
                            '& .MuiLinearProgress-bar': {
                              backgroundColor: '#1B4965',
                            },
                          }}
                        />
                      </Box>
                      <Box display="flex" justifyContent="space-between">
                        <Typography variant="body2">Duration: {duration}</Typography>
                        <Typography variant="body2">Break: 1:00 hr</Typography>
                      </Box>
                      <Divider sx={{ marginY: 2 }} />
                      <Typography variant="body2">{shiftTimingText}</Typography>
                    </Box>
                  );
                })()}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Card sx={{ flexGrow: 1 }}>
              <CardContent>
                <Typography variant="h6">Actions</Typography>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <div>
                    <Typography variant="h4">{currentTime}</Typography>
                    <Typography variant="body2">{currentDate}</Typography>
                  </div>
                  <Button variant="contained" color="primary" onClick={handleClockInOut}
                    sx={{
                      marginY: 2,
                      backgroundColor: '#1B4965',
                      '&:hover': {
                        backgroundColor: '#1B4965',
                      }
                    }}>{attendanceStatus}
                  </Button>
                </div>
                <Button
                  variant={selectedButton === 'Remote Clock-in' ? 'outlined' : 'text'}
                  sx={{ color: '#1B4965', borderColor: '#1B4965', backgroundColor: selectedButton !== 'Remote Clock-in' ? 'white' : '#F1F1F1' }}
                  onClick={() => setSelectedButton('Remote Clock-in')}
                >
                  Remote Clock-in
                </Button>
                <Button
                  variant={selectedButton === 'Work From Home' ? 'outlined' : 'text'}
                  sx={{ color: '#1B4965', borderColor: '#1B4965', backgroundColor: selectedButton !== 'Work From Home' ? 'white' : '#F1F1F1' }}
                  onClick={() => setSelectedButton('Work From Home')}
                >
                  Work From Home
                </Button>
                <Divider sx={{ marginY: 2 }} />
                <Typography variant="h7" sx={{ color: '#1B4965' }}>Attendance Policy</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                  <Typography variant="h6" sx={{ flexGrow: 1 }}>
                    Logs
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <TimePeriod startDate={startDate} endDate={endDate} onDateRangeChange={handleDateRangeChange} />
                  </Box>
                </Box>
                <Divider sx={{ marginY: 2 }} />
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Clock-In</TableCell>
                        <TableCell>Clock-Out</TableCell>
                        <TableCell>Location</TableCell>
                        <TableCell>Effective Hours</TableCell>
                        <TableCell>Gross Hours</TableCell>
                        <TableCell>Arrival</TableCell>
                        <TableCell>Log</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredRecords?.map((item, index) => {
                        const clockIn = item?.record && item.record.clockInTime
                          ? item.record.clockInTime.split(' ')[1]
                          : '-';
                        let clockOut = item?.record && item.record.clockOutTime
                          ? item.record.clockOutTime.split(' ')[1]
                          : '-';
                        return (
                          <TableRow key={index}>
                            <TableCell>
                              <Typography variant="body2">{format(item.date, 'MMM dd, EEE')}</Typography>
                              {item.tag && (
                                <Button variant="contained" size="small"
                                  sx={{
                                    marginRight: 0.5,
                                    marginTop: 0.5,
                                    backgroundColor: '#FF6347',
                                    '&:hover': {
                                      backgroundColor: '#FF6347',
                                    },
                                  }}
                                >
                                  {item.tag}
                                </Button>
                              )}
                              {item.record && item.record.place && !item.tag && (
                                <Button variant="contained" size="small"
                                  sx={{
                                    marginRight: 0.5,
                                    marginTop: 0.5,
                                    backgroundColor: '#1B4965',
                                    '&:hover': {
                                      backgroundColor: '#1B4965',
                                    },
                                  }}
                                >
                                  {item.record.place === 'Remote Clock-in' ? 'Office' : item.record.place === 'Work From Home' ? 'WFH' : 'Other'}
                                </Button>
                              )}
                            </TableCell>
                            <TableCell>{clockIn}</TableCell>
                            <TableCell>{clockOut}</TableCell>
                            <TableCell>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                {item?.record?.location ? (
                                  <>
                                    <LocationOnIcon onMouseEnter={handlePopoverOpen} sx={{ marginRight: 1, color: '#1B4965' }} />
                                    <Popover id={id} open={open} anchorEl={anchorEl} onClose={handlePopoverClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} transformOrigin={{ vertical: 'top', horizontal: 'left' }} sx={{ boxShadow: 3, borderRadius: 1, }} disableRestoreFocus>
                                      <Box sx={{ p: 0, width: 300, overflow: 'hidden', borderRadius: 1 }} onMouseLeave={handlePopoverClose}>
                                        <MapContainer center={[item.record.location.latitude, item.record.location.longitude]} zoom={15} style={{ width: '100%', height: '200px', borderRadius: '8px' }}>
                                          <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
                                          <Marker
                                            position={[item.record.location.latitude, item.record.location.longitude]}
                                            icon={new L.Icon({
                                              iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
                                              iconSize: [25, 41],
                                              iconAnchor: [12, 41],
                                              popupAnchor: [1, -34],
                                            })}
                                          >
                                            <Popup>
                                              Latitude: {item.record.location.latitude}<br />
                                              Longitude: {item.record.location.longitude}
                                            </Popup>
                                          </Marker>
                                        </MapContainer>
                                      </Box>
                                    </Popover>
                                  </>
                                ) : (
                                  <span>-</span>
                                )}
                              </div>
                            </TableCell>
                            <TableCell>{item.record && item.record.effectiveHours ? `${item.record.effectiveHours} hrs` : '-'}</TableCell>
                            <TableCell>{item.record && item.record.grossHours ? `${item.record.grossHours} hrs` : '-'}</TableCell>
                            <TableCell>-</TableCell>
                            <TableCell>
                              {item.record && item.record.clockInTime && !item.record.clockOutTime ? (
                                <Info color="warning" />
                              ) : (
                                item.record ? <CheckCircle color="success" /> : '-'
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      {(loading) && (<Loader />)}
    </span >
  )
}

export default Attendance