import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Swal from 'sweetalert2';
import { Button, Modal, Box, Typography, FormControl, Select, MenuItem, InputLabel } from '@mui/material';

import Loader from '../../../UiUxComponents/Loader/Loader';
import { selectToken } from '../../../../Redux/selector';
import { getAllClients } from '../../../../utils/Services/clientServices';
import { assignEmployee, assignedEmployeeData } from '../../../../utils/Services/subscriberConfigurationServices/assignedClientServices';
import { getAllUsersDetails } from '../../../../utils/Services/userServices';

function AssignEmployees() {
  const [open, setOpen] = useState(false);
  const [assignedData, setAssignedData] = useState([]);
  const [clientsData, setClientsData] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [loading, setLoading] = useState(false);
  const token = useSelector(selectToken);
  const { subscriberId } = useParams();

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setSelectedClient('');
    setSelectedEmployee('');
  };

  // Fetch assigned employee details
  const fetchAssignedEmployeeDetail = async () => {
    setLoading(true);
    try {
      const response = await assignedEmployeeData(token, subscriberId);
      setAssignedData(response?.data?.data || []);
    } catch (error) {
      console.error('Error:', error);
      Swal.fire({
        icon: 'error',
        title: 'Failed ',
        text: 'Failed to fetch data',
        confirmButtonColor: '#1B4965',
        customClass: {
            container: 'swal-container',
        }
    })
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchAssignedEmployeeDetail();
  }, []);

  // Fetch client details
  const fetchClientDetail = async () => {
    setLoading(true);
    try {
      const response = await getAllClients(token, subscriberId);
      setClientsData(response?.data || []);
    } catch (error) {
      console.error('Error:', error);
      Swal.fire({
        icon: 'error',
        title: 'Failed ',
        text: 'Failed to fetch data',
        confirmButtonColor: '#1B4965',
        customClass: {
            container: 'swal-container',
        }
    })
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchClientDetail();
  }, []);

  // Fetch user details
  const fetchUserDetail = async () => {
    setLoading(true);
    try {
      const response = await getAllUsersDetails(token, subscriberId);
      setUsersData(response?.data?.data || []);
    } catch (error) {
      console.error('Error:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'There was an error. Please try again later.',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchUserDetail();
  }, []);

  const handleAssign = async () => {
    if (selectedEmployee && !assignedData.some(emp => emp.client.id === selectedClient && emp.user.id === selectedEmployee)) {
      const selectedClientObj = clientsData.find(client => client.companyName === selectedClient);
      const selectedEmployeeObj = usersData.find(user => user.fullName === selectedEmployee);

      if (selectedClientObj && selectedEmployeeObj) {
        const newAssignment = {
          clientId: selectedClientObj._id,
          clientName: selectedClientObj.companyName,
          userId: selectedEmployeeObj._id,
          userName: selectedEmployeeObj.fullName
        };

        try {
          setLoading(true);
          await assignEmployee(token, subscriberId, newAssignment);
          handleClose();
          fetchAssignedEmployeeDetail();
        } catch (error) {
          console.error('Error assigning employee:', error);
          console.log(error?.response?.data?.message)
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: `${error?.response?.data?.message}`,
            confirmButtonColor: '#1B4965',
            customClass: {
              container: 'swal-container',
            }
          });
        } finally {
          setLoading(false);
        }
      } else {
        console.error('Selected client or employee not found.');
      }
    }
  };

  return (
    <Container sx={{ padding: '20px' }}>
      <Button variant="contained" color="primary" onClick={handleOpen}
        sx={{
          backgroundColor: '#1B4965',
          color: 'white',
          '&:hover': {
            backgroundColor: '#1B4965',
          },
          marginBottom: '20px'
        }}>Assign Employee</Button>
      <TableWrapper>
        <Table>
          <thead>
            <tr>
              <Th>Client</Th>
              <Th>Employee</Th>
            </tr>
          </thead>
          <tbody>
            {assignedData.length > 0 ? (
              assignedData.map((row, index) => (
                <tr key={index}>
                  <Td>{row.client?.name || 'N/A'}</Td>
                  <Td>{row.user?.name || 'N/A'}</Td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={2} align="center">No employees assigned yet.</td>
              </tr>
            )}
          </tbody>
        </Table>
      </TableWrapper>
      <Modal open={open} onClose={handleClose} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <Box sx={{
          width: 300, height: '100vh', backgroundColor: 'white', boxShadow: 24, p: 4, position: 'relative',
          left: 0, transition: 'transform 0.3s ease-in-out', transform: open ? 'translateX(0)' : 'translateX(-100%)',
        }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', marginTop: '20px' }}>
            <Typography variant="h6" sx={{ color: '#1B4965' }}>Assign Employees</Typography>
            <Button
              onClick={handleClose}
              variant="contained"
              sx={{
                backgroundColor: '#1B4965',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#1B4965',
                },
              }}
            >
              Back
            </Button>
          </Box>
          <FormControl variant='outlined' required fullWidth margin='dense'>
            <InputLabel id="client">Client</InputLabel>
            <Select labelId='client' id='client' name='client' label="Client" value={selectedClient} onChange={(e) => setSelectedClient(e.target.value)}>
              {clientsData.map(client => (
                <MenuItem key={client._id} value={client.companyName}>
                  {client.companyName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant='outlined' required fullWidth margin='dense'>
            <InputLabel id="employee">Employee</InputLabel>
            <Select labelId='employee' id='employee' name='employee' label="Employee" value={selectedEmployee} onChange={(e) => setSelectedEmployee(e.target.value)}>
              {usersData.map(user => (
                <MenuItem key={user._id} value={user.fullName}>
                  {user.fullName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button variant="contained" color="primary" onClick={handleAssign}
            sx={{
              marginTop: '20px', width: '100%', backgroundColor: '#1B4965',
              color: 'white', '&:hover': { backgroundColor: '#1B4965' },
            }}>
            Assign Employee
          </Button>
        </Box>
      </Modal>
      {loading && <Loader />}
    </Container>
  );
}

export default AssignEmployees;

const Container = styled.div`
  padding: 20px;
  height: 70vh;
  overflow: hidden;
`;

const TableWrapper = styled.div`
  max-height: 100%;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #1B4965 #f0f0f0;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #1B4965;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background: #f0f0f0;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  padding: 10px;
  background-color: #1B4965;
  color: #fff;
  text-align: left;
`;

const Td = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
`;
