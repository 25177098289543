import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from 'styled-components';

import Swal from 'sweetalert2';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import Loader from "../../../../../UiUxComponents/Loader/Loader";
import { selectToken } from "../../../../../../Redux/selector";
import { getLoctionDataWithMembers, updateLocation } from "../../../../../../utils/Services/subscriberConfigurationServices/locationServices";

function LocationData() {
  const [locationDetails, setLocationDetails] = useState();
  const [locationUsers, setLocationUsers] = useState([]);
  const [temporaryBranchID, setTemporaryBranchID] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const token = useSelector(selectToken);
  const { locationId } = useParams();

  // To get location details
  const fetchLocationDetails = async () => {
    setLoading(true);
    try {
      const response = await getLoctionDataWithMembers(token, locationId);
      setLocationDetails(response?.data?.location)
      setLocationUsers(response?.data?.members)
      setTemporaryBranchID(response?.data?.location?.branchID || []);
    } catch (err) {
      console.error('Error fetching location details:', err);
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: 'Failed to fetch location details',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchLocationDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle to edit the fields
  const handleEditClick = async () => {
    if (isEditing) {
      await fetchLocationDetails();
    }
    setIsEditing(prevEditing => {
      const newEditingState = !prevEditing;
      if (newEditingState) {
        Swal.fire({
          icon: 'info',
          title: 'Editing Enabled',
          text: 'Now you can edit the fields.',
          confirmButtonColor: '#1B4965',
          customClass: {
            container: 'swal-container',
          }
        });
      }
      return newEditingState;
    });
  };

  // handle input change
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setLocationDetails(prevDetails => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleBranchIDChange = (index, field, value) => {
    const updatedBranchID = [...temporaryBranchID];
    updatedBranchID[index][field] = value;
    setTemporaryBranchID(updatedBranchID);
  };

  // Dropdown for Branch Manager
  const selectedBranchManagerId = locationUsers?.find(location => location.fullName === locationDetails?.branchManager?.name)?._id || '';
  const handleSelectChange = (e) => {
    const selectedId = e.target.value;
    const selectedBranchManager = locationUsers?.find(location => location._id === selectedId);
    setLocationDetails({
      ...locationDetails,
      branchManager: {
        _id: selectedId,
        name: selectedBranchManager?.fullName || ''
      }
    });
  };

  // To add new rows
  const handleAddData = () => {
    setTemporaryBranchID(prev => [
      ...prev,
      { branchIDName: '', branchIDValue: '' },
    ]);
  };

  // Hanlde Update
  const handleUpdate = async () => {
    setLoading(true);
    try {
      await updateLocation(token, locationId, { ...locationDetails, branchID: temporaryBranchID });
      Swal.fire({
        icon: 'success',
        title: 'Location Details Updated',
        text: 'The location have been successfully updated.',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Update Failed',
        text: 'There was an error updating the location details. Please try again.',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });
      console.error('Error updating location details:', err);
    } finally {
      setLoading(false);
      setIsEditing(false);
      fetchLocationDetails();
    }
  };

  return (
    <span>
      <Container style={{ padding: '20px', height: '70vh', overflow: 'auto' }}>
        <Grid container spacing={2} justifyContent="flex-end" sx={{ marginBottom: '20px' }}>
          <Grid item>
            {isEditing && (
              <Button variant="contained"
                sx={{
                  backgroundColor: '#51b86c',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: '#51b86c',
                    opacity: 1
                  }
                }}
                endIcon={<AddCircleIcon />}
                onClick={handleAddData}>Add New Document
              </Button>
            )}
          </Grid>
          <Grid item>
            <Button variant="contained"
              sx={{
                backgroundColor: '#5A9BD5',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#5A9BD5',
                  opacity: 1
                }
              }}
              endIcon={<EditIcon />}
              onClick={handleEditClick}>{isEditing ? 'Cancel' : 'Edit'}
            </Button>
          </Grid>
          <Grid item>
            {isEditing && (
              <Button variant="outlined"
                sx={{
                  backgroundColor: '#1B4965',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: '#1B4965',
                    opacity: 1
                  }
                }}
                endIcon={<RestartAltIcon />}
                onClick={() => {
                  if (isEditing) {
                    handleUpdate();
                  }
                }}
              > Update
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <TextField id="branchName" name="branchName" label="Branch Name" variant="outlined" fullWidth margin="dense"
              InputProps={{ readOnly: !isEditing }}
              onChange={(e) => setLocationDetails({ ...locationDetails, branchName: e.target.value })}
              value={locationDetails?.branchName || ''}
              sx={{
                '& .MuiInputLabel-root': { fontSize: '14px' },
                '& .MuiInputBase-root': { fontSize: '14px' },
                width: '100%'
              }}
            />
          </Grid>
          <Grid item xs={6}>
            {isEditing ? (
              <FormControl variant='outlined' required fullWidth margin='dense'>
                <InputLabel id="branchManager">Branch Manager</InputLabel>
                <Select labelId='branchManager' id='branchManager' label="Branch Manager" margin="dense"
                  value={selectedBranchManagerId}
                  onChange={handleSelectChange}
                  sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '100%' }}
                >
                  {locationUsers?.map((users) => (
                    <MenuItem key={users._id} value={users._id}>
                      {users.fullName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <TextField id="branchManager" name="branchManager" label="Branch Manager" variant="outlined" fullWidth margin="dense" InputProps={{ readOnly: !isEditing }}
                onChange={handleInputChange}
                value={locationDetails?.branchManager?.name || ''}
                sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '100%' }}
              />
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <TextField id="contactNumber" name="contactNumber" label="Contact Number" variant="outlined" fullWidth margin="dense"
              InputProps={{ readOnly: !isEditing }}
              onChange={(e) => setLocationDetails({ ...locationDetails, contactNumber: e.target.value })}
              value={locationDetails?.contactNumber || ''}
              sx={{
                '& .MuiInputLabel-root': { fontSize: '14px' },
                '& .MuiInputBase-root': { fontSize: '14px' },
                width: '100%'
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField id="street" name="address.street" label="Street" variant="outlined" fullWidth margin="dense"
              InputProps={{ readOnly: !isEditing }}
              onChange={(e) => setLocationDetails(prevDetails => ({
                ...prevDetails,
                address: {
                  ...prevDetails.address,
                  street: e.target.value
                }
              }))}
              value={locationDetails?.address?.street || ''}
              sx={{
                '& .MuiInputLabel-root': { fontSize: '14px' },
                '& .MuiInputBase-root': { fontSize: '14px' },
                width: '100%'
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <TextField id="city" name="addresscity" label="City" variant="outlined" fullWidth margin="dense"
              InputProps={{ readOnly: !isEditing }}
              onChange={(e) => setLocationDetails(prevDetails => ({
                ...prevDetails,
                address: {
                  ...prevDetails.address,
                  city: e.target.value
                }
              }))}
              value={locationDetails?.address?.city || ''}
              sx={{
                '& .MuiInputLabel-root': { fontSize: '14px' },
                '& .MuiInputBase-root': { fontSize: '14px' },
                width: '100%'
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField id="states" name="address.states" label="State" variant="outlined" fullWidth margin="dense"
              InputProps={{ readOnly: !isEditing }}
              onChange={(e) => setLocationDetails(prevDetails => ({
                ...prevDetails,
                address: {
                  ...prevDetails.address,
                  states: e.target.value
                }
              }))}
              value={locationDetails?.address?.states || ''}
              sx={{
                '& .MuiInputLabel-root': { fontSize: '14px' },
                '& .MuiInputBase-root': { fontSize: '14px' },
                width: '100%'
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField id="country" name="address.country" label="Country" variant="outlined" fullWidth margin="dense"
              InputProps={{ readOnly: !isEditing }}
              onChange={(e) => setLocationDetails(prevDetails => ({
                ...prevDetails,
                address: {
                  ...prevDetails.address,
                  country: e.target.value
                }
              }))}
              value={locationDetails?.address?.country || ''}
              sx={{
                '& .MuiInputLabel-root': { fontSize: '14px' },
                '& .MuiInputBase-root': { fontSize: '14px' },
                width: '100%'
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField id="postalCode" name="address.postalCode" label="Postal Code" variant="outlined" fullWidth margin="dense"
              InputProps={{ readOnly: !isEditing }}
              onChange={(e) => setLocationDetails(prevDetails => ({
                ...prevDetails,
                address: {
                  ...prevDetails.address,
                  postalCode: e.target.value
                }
              }))}
              value={locationDetails?.address?.postalCode || ''}
              sx={{
                '& .MuiInputLabel-root': { fontSize: '14px' },
                '& .MuiInputBase-root': { fontSize: '14px' },
                width: '100%'
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          {temporaryBranchID.map((branchId, index) => (
            <React.Fragment key={index}>
              <Grid item xs={5}>
                <TextField required id={`branchIDName-${index}`} name={`branchIDName-${index}`} label="Branch ID Name" variant="outlined" fullWidth margin="dense"
                  InputProps={{ readOnly: !isEditing }}
                  onChange={(e) => handleBranchIDChange(index, 'branchIDName', e.target.value)}
                  value={branchId.branchIDName || ''}
                  sx={{
                    '& .MuiInputLabel-root': { fontSize: '14px' },
                    '& .MuiInputBase-root': { fontSize: '14px' },
                    width: '100%'
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField required id={`branchIDValue-${index}`} name={`branchIDValue-${index}`} label="Branch ID Value" variant="outlined" fullWidth margin="dense"
                  InputProps={{ readOnly: !isEditing }}
                  onChange={(e) => handleBranchIDChange(index, 'branchIDValue', e.target.value)}
                  value={branchId.branchIDValue || ''}
                  sx={{
                    '& .MuiInputLabel-root': { fontSize: '14px' },
                    '& .MuiInputBase-root': { fontSize: '14px' },
                    width: '100%'
                  }}
                />
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Container>
      {(loading) && (
        <Loader />
      )}
    </span>
  )
}

export default LocationData

const Container = styled.div`
    padding: 20px;
    height: 70vh;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: #1B4965 #f0f0f0;

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #1B4965;
        border-radius: 20px;
    }

    &::-webkit-scrollbar-track {
        background: #f0f0f0;
    }
`;