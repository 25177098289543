import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import Swal from 'sweetalert2';
import { Button, Grid, IconButton, TextField } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import Loader from '../../../UiUxComponents/Loader/Loader';
import { selectToken, selectUser } from '../../../../Redux/selector';
import { getuserDetailsByTabName, updateUser } from '../../../../utils/Services/userServices'

function IDProofs() {
    const [idProofs, setIdProofs] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const token = useSelector(selectToken);
    const user = useSelector(selectUser);
    const { userId } = useParams();
    const tabName = "idProofs"

    // To get education details
    const fetchIdProofs = async () => {
        setLoading(true);
        try {
            const response = await getuserDetailsByTabName(token, tabName, userId);
            const data = response?.data[0]?.idProofs || [];
            setIdProofs(data)
        } catch (err) {
            console.error('Error fetching user id proofs:', err);
            Swal.fire({
                icon: 'error',
                title: 'Failed ',
                text: 'Failed to fetch ID Proofs',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchIdProofs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Handle to edit the fields
    const handleEditClick = async () => {
        if (isEditing) {
            await fetchIdProofs();
        }
        setIsEditing(prevEditing => {
            const newEditingState = !prevEditing;
            if (newEditingState) {
                Swal.fire({
                    icon: 'info',
                    title: 'Editing Enabled',
                    text: 'Now you can edit the fields.',
                    confirmButtonColor: '#1B4965',
                    customClass: {
                        container: 'swal-container',
                    }
                });
            }
            return newEditingState;
        });
    };

    // To add new rows
    const handleAddData = () => {
        setIdProofs([...idProofs, { idProofName: '', idProofValue: '', idProofUploadDetails: null }]);
    };

    // Handle File Change
    const handleFileChange = (index, event) => {
        const file = event.target.files[0];
        const updatedIdProofs = [...idProofs];
        updatedIdProofs[index].idProofUploadDetails = file;
        setIdProofs(updatedIdProofs);
    };

    // Check if file upload is required
    const isFileUploadComplete = () => {
        return idProofs.every((proof) => proof.idProofUploadDetails !== null);
    };

    // Handle viewing the PDF
    const handleViewPdf = (pdfUrl) => {
        window.open(pdfUrl, '_blank');
    }

    // Hanlde Update
    const handleUpdate = async () => {
        if (!isFileUploadComplete()) {
            Swal.fire({
                icon: 'warning',
                title: 'Missing File',
                text: 'Please upload a file for each ID proof.',
                confirmButtonColor: '#1B4965',
            });
            return;
        }
        setLoading(true);
        try {
            const userPersonalDetails = user;
            const companyDetails = user?.companyId
            const updatedData = { userPersonalDetails, companyDetails, idProofs }
            await updateUser(token, userId, tabName, updatedData);
            Swal.fire({
                icon: 'success',
                title: 'Education Details Updated',
                text: 'The ID Proofs have been successfully updated.',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
        } catch (err) {
            Swal.fire({
                icon: 'error',
                title: 'Update Failed',
                text: 'There was an error updating the ID Proofs. Please try again.',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
            console.error('Error updating id proofs:', err);
        } finally {
            setLoading(false);
            setIsEditing(false);
            fetchIdProofs();
        }
    };

    return (
        <span>
            <Container style={{ padding: '20px', height: '70vh', overflow: 'auto' }}>
                <Grid container spacing={2} justifyContent="flex-end" sx={{ marginBottom: '20px' }}>
                    <Grid item>
                        {isEditing && (
                            <Button variant="contained"
                                sx={{
                                    backgroundColor: '#51b86c',
                                    color: '#fff',
                                    '&:hover': {
                                        backgroundColor: '#51b86c',
                                        opacity: 1
                                    }
                                }}
                                endIcon={<AddCircleIcon />}
                                onClick={handleAddData}>Add
                            </Button>
                        )}
                    </Grid>
                    <Grid item>
                        <Button variant="contained"
                            sx={{
                                backgroundColor: '#5A9BD5',
                                color: '#fff',
                                '&:hover': {
                                    backgroundColor: '#5A9BD5',
                                    opacity: 1
                                }
                            }}
                            endIcon={<EditIcon />}
                            onClick={handleEditClick}>{isEditing ? 'Cancel' : 'Edit'}
                        </Button>
                    </Grid>
                    <Grid item>
                        {isEditing && (
                            <Button variant="outlined"
                                sx={{
                                    backgroundColor: '#1B4965',
                                    color: '#fff',
                                    '&:hover': {
                                        backgroundColor: '#1B4965',
                                        opacity: 1
                                    }
                                }}
                                endIcon={<RestartAltIcon />}
                                onClick={() => {
                                    if (isEditing) {
                                        handleUpdate();
                                    }
                                }}
                            > Update
                            </Button>
                        )}
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    {idProofs.map((idProof, index) => (
                        <React.Fragment key={index}>
                            <Grid item xs={4}>
                                <TextField required id={`idProofName-${index}`} name={`idProofName-${index}`} label="ID Proof Name" variant="outlined" fullWidth margin="dense"
                                    disabled={!isEditing}
                                    onChange={(e) => {
                                        const updatedIdProofs = [...idProofs];
                                        updatedIdProofs[index].idProofName = e.target.value;
                                        setIdProofs(updatedIdProofs);
                                    }}
                                    value={idProof.idProofName || ''}
                                    sx={{
                                        '& .MuiInputLabel-root': { fontSize: '14px' },
                                        '& .MuiInputBase-root': { fontSize: '14px' },
                                        width: '100%'
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField required id={`idProofValue-${index}`} name={`idProofValue-${index}`} label="ID Proof Value" variant="outlined" fullWidth margin="dense"
                                    disabled={!isEditing}
                                    onChange={(e) => {
                                        const updatedIdProofs = [...idProofs];
                                        updatedIdProofs[index].idProofValue = e.target.value;
                                        setIdProofs(updatedIdProofs);
                                    }}
                                    value={idProof.idProofValue || ''}
                                    sx={{
                                        '& .MuiInputLabel-root': { fontSize: '14px' },
                                        '& .MuiInputBase-root': { fontSize: '14px' },
                                        width: '100%'
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                {idProof.idProofUploadDetails ? (
                                    <div style={{ display: 'flex' }}>
                                        <Button component="label" variant="contained" startIcon={<CloudUploadIcon />} sx={{ background: 'linear-gradient(90deg, #1B4965 63.83%, #051925 82.97%)', backgroundSize: '200%' }} onClick={() => handleViewPdf(idProof.idProofUploadDetails[0].location || idProof.idProofUploadDetails[0])}>
                                            View ID Card
                                        </Button>
                                        {isEditing && (
                                            <IconButton onClick={() => {
                                                const updatedIdProofs = [...idProofs];
                                                updatedIdProofs[index].idProofUploadDetails = null;
                                                setIdProofs(updatedIdProofs);
                                            }}>
                                                <DeleteForeverIcon sx={{ fontSize: '22px', cursor: 'pointer', }} />
                                            </IconButton>
                                        )}
                                    </div>
                                ) : (
                                    <div>
                                        <Button component="label" role={undefined} variant="contained" tabIndex={-1} startIcon={<CloudUploadIcon />} sx={{ background: 'linear-gradient(90deg, #1B4965 63.83%, #051925 82.97%)', backgroundSize: '200%' }}>
                                            Upload File
                                            <input type="file" accept="application/pdf" style={{ display: 'none' }} onChange={(e) => handleFileChange(index, e)} />
                                        </Button>
                                    </div>
                                )}
                            </Grid>
                        </React.Fragment>
                    ))}
                </Grid>
            </Container>
            {(loading) && (
                <Loader />
            )}
        </span>
    )
}

export default IDProofs

const Container = styled.div`
    padding: 20px;
    height: 70vh;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: #1B4965 #f0f0f0;

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #1B4965;
        border-radius: 20px;
    }

    &::-webkit-scrollbar-track {
        background: #f0f0f0;
    }
`;