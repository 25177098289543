import React from 'react';
import Navbar from '../../Components/UiUxComponents/Navbar/Navbar';
import SidebarContainer from '../../Components/UiUxComponents/Sidebar/SidebarContainer/SidebarContainer';
import useInactivityTimeout from '../useInactivityTimeout';
import useCheckValidSystemAdmin from '../authHelper/useCheckValidSystemAdmin';

interface ContainerProps {
  children?: React.ReactNode;
}

const ProtectedSystemAdminContainer: React.FC<ContainerProps> = ({ children }) => {
    useCheckValidSystemAdmin();

  const currentPath = window.location.pathname;
  const excludedRoutes = ['/', '/change-password', '/login-with-otp', '/forgot-password'];
  const isExcludedRoute = excludedRoutes.includes(currentPath);

  useInactivityTimeout(isExcludedRoute);

  return (
    <div style={{ zIndex: 1 }}>
      <Navbar />
      <SidebarContainer/>
    </div>
  );
};

export default ProtectedSystemAdminContainer;
