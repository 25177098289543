import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import './CustomCalendar.css'

const CustomCalendar = () => {
  const date = new Date()
  return (
    <div style={{marginTop: '20px'}}>
      <Calendar value={date} onChange={null} showNavigation={true} prev2Label={null} next2Label={null}/>
    </div>
  );
};

export default CustomCalendar;
