import { useRef, useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";

import Swal from "sweetalert2";
import { Box, TextField, Button} from "@mui/material";

import '../Login/Login.css'
import Applogo from '../../../assets/NavbarImages/app-logo-color.png'
import Wave from "../../../assets/Login/wave.svg";
import LoginImg from "../../../assets/Login/bg2.svg";
import ClientLoginImg from "../../../assets/Login/bg3.svg"
import Loader from "../../../Components/UiUxComponents/Loader/Loader";
import { getUserEmail } from '../../../utils/Services/authServices'

function LoginWithOtp() {
  const [companyCode, setCompanyCode] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [showOTP, setShowOTP] = useState(false);
  const [workEmail, setWokEmail] = useState("");
  const [otp, setOtp] = useState(['', '', '', '', '']);
  const [loading, setLoading] = useState(false);
  const otpInputs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];
  const navigate = useNavigate();
  const location = useLocation();

  // Handle Change Company Code
  const handleCompanyCodeChange = (event) => {
    setCompanyCode(event.target.value);
  };

  // Handle Change Email
  const handleEmailChange = (event) => {
    setUserEmail(event.target.value);
  };

  // Handle Change OTP
  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    if (value !== '' && index < 4) {
      otpInputs[index + 1].current.focus();
    }
  };

  // Handle Key Press Down
  const handleKeyDown = (index, event) => {
    if (event.key === 'Backspace' && index > 0 && otp[index] === '') {
      otpInputs[index - 1].current.focus();
    }
  };

  const loginWithPasswordPath = location.pathname.startsWith('/client-login-with-otp')
    ? '/client-login'
    : '/';

  // To send OTP
  const handleSendOTP = async (event) => {
    event.preventDefault();
    const formData = { companyCode: companyCode, userEmail: userEmail };
    if (!formData.companyCode || !formData.userEmail) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please fill all the required details ',
        confirmButtonColor: "#1B4965",
      });
      return;
    }
    setLoading(true);
    try {
      const response = await getUserEmail(formData);
      Swal.fire({
        icon: 'success',
        title: 'OTP Sent Successfully',
        text: 'Check Your Mail',
        showConfirmButton: false,
        timer: 1500,
      });
      setShowOTP(true);
      setWokEmail(response.data.workmail)
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: err?.response?.data?.msg,
        text: err.response ? err.response.data.message : 'Something went wrong',
        confirmButtonColor: "#1B4965",
      });
    } finally {
      setLoading(false);
    }
  }

  // To Submit OTP
  const handleSubmitOTP = (event) => {
    event.preventDefault();
    const enteredOtp = otp.join('');
    if (!enteredOtp) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please enter OTP to login',
        confirmButtonColor: "#1B4965",
      });
      return;
    } else {
      if (location.pathname.startsWith('/client-login-with-otp')) {
        navigate('/client-login')
      } else {
        navigate("/")
      }
    }
  };

  return (
    <div className="logincontainer">
      <img src={Wave} alt="" className="wave" />
      <div className="login-container-form">
        <div className="login-img" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <img src={location.pathname.startsWith('/client-login-with-otp') ? ClientLoginImg : LoginImg} alt="" />
        </div>
        <div className="login-form" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', }}>
          <div className="login-form-container">
            <img src={Applogo} alt="" style={{ height: '100px' }} />
            <form onSubmit={handleSubmitOTP} style={{ marginLeft: '0%', width: 'calc(100% - 0px)' }}>
            <Box>
                <TextField label="Company Code" variant="outlined" fullWidth margin="normal" onChange={handleCompanyCodeChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#1B4965",
                      },
                      "&:hover fieldset": {
                        borderColor: "#1B4965",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#051925",
                      },
                    },
                  }}
                />
              </Box>
              <Box>
                <TextField label="Work Email" variant="outlined" fullWidth margin="normal" onChange={handleEmailChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#1B4965",
                      },
                      "&:hover fieldset": {
                        borderColor: "#1B4965",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#051925",
                      },
                    },
                  }}
                />
              </Box>
              {!showOTP && (
                <div>
                  <Button type="submit" variant="contained" fullWidth onClick={handleSendOTP}
                    style={{ width: "calc(100% - 0px)", borderRadius: "25px", outline: "none", border: "none", backgroundColor: '#1B4965', fontSize: "1.2rem", color: "#fff", fontFamily: "'Poppins', sans-serif", textTransform: "uppercase", margin: "1rem 0", cursor: "pointer", transition: "0.5s", }}>
                    Send OTP
                  </Button>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                    <Link to={loginWithPasswordPath} className="loginpassword">Login With Password</Link>
                  </div>
                </div>
              )}
              {showOTP && (
                <div className='otp-card'>
                  <p>OTP has been sent to {workEmail}</p>
                  <div className='otp-card-inputs'>
                    {otp.map((digit, index) => (
                      <input key={index} ref={otpInputs[index]} type="text" maxLength="1" value={digit} onChange={(e) => handleOtpChange(index, e.target.value)} onKeyDown={(e) => handleKeyDown(index, e)} />
                    ))}
                  </div>
                  <Button type="submit" variant="contained" fullWidth
                    style={{ width: "calc(100% - 0px)", borderRadius: "25px", outline: "none", border: "none", backgroundColor: '#1B4965', fontSize: "1.2rem", color: "#fff", fontFamily: "'Poppins', sans-serif", textTransform: "uppercase", margin: "1rem 0", cursor: "pointer", transition: "0.5s", }}>
                    Submit
                  </Button>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
      {(loading) && (
        <Loader />
      )}
    </div>
  );
}

export default LoginWithOtp;
