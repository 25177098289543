import axios from 'axios';
const backEndUrl = `${process.env.REACT_APP_BACKEND_URL}/api/subscriber-configuration/assign-Client`;

// To Assign Employees to client
export const assignEmployee = (token, companyId, assignmentData) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            };
            let response = await axios.post(`${backEndUrl}/assignEmployees/${companyId}`, assignmentData, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// Get Assigned Employees Data
export const assignedEmployeeData = (token, companyId) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            };
            let response = await axios.get(`${backEndUrl}/assignedEmployees/${companyId}`, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// To get Assigned Employees Data according to the client
export const getAssignedEmployees = (token, clientId, companyId) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            };
            let response = await axios.get(`${backEndUrl}/get-assignedEmployees/${clientId}/${companyId}`, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};