import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from 'styled-components';

import Swal from 'sweetalert2';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

import Loader from "../../../../../UiUxComponents/Loader/Loader";
import { selectToken } from "../../../../../../Redux/selector";
import { getDesignationDataWithMembers, updateDesignation } from "../../../../../../utils/Services/subscriberConfigurationServices/designationServices";

function DesignationData() {
  const [designationDetails, setDesignationDetails] = useState();
  const [designationUsers, setDesignationUsers] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const token = useSelector(selectToken);
  const { designationId } = useParams();

  // To get designation details
  const fetchDesignationDetails = async () => {
    setLoading(true);
    try {
      const response = await getDesignationDataWithMembers(token, designationId);
      setDesignationDetails(response?.data?.designation)
      setDesignationUsers(response?.data?.members)
    } catch (err) {
      console.error('Error fetching designation details:', err);
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: 'Failed to fetch designation details',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchDesignationDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle to edit the fields
  const handleEditClick = async () => {
    if (isEditing) {
      await fetchDesignationDetails();
    }
    setIsEditing(prevEditing => {
      const newEditingState = !prevEditing;
      if (newEditingState) {
        Swal.fire({
          icon: 'info',
          title: 'Editing Enabled',
          text: 'Now you can edit the fields.',
          confirmButtonColor: '#1B4965',
          customClass: {
            container: 'swal-container',
          }
        });
      }
      return newEditingState;
    });
  };

  // handle input change
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDesignationDetails(prevDetails => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  // Dropdown for Designation Head
  const selectedDesignationHeadId = designationUsers?.find(designation => designation.fullName === designationDetails?.designationHead?.name)?._id || '';
  const handleSelectChange = (e) => {
    const selectedId = e.target.value;
    const selectedDesignationHead = designationUsers?.find(designation => designation._id === selectedId);
    setDesignationDetails({
      ...designationDetails,
      designationHead: {
        _id: selectedId,
        name: selectedDesignationHead?.fullName || ''
      }
    });
  };

  // Hanlde Update
  const handleUpdate = async () => {
    setLoading(true);
    try {
      await updateDesignation(token, designationId, designationDetails);
      Swal.fire({
        icon: 'success',
        title: 'Designation Details Updated',
        text: 'The designation have been successfully updated.',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Update Failed',
        text: 'There was an error updating the designation details. Please try again.',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });
      console.error('Error updating designation details:', err);
    } finally {
      setLoading(false);
      setIsEditing(false);
      fetchDesignationDetails();
    }
  };

  return (
    <span>
      <Container style={{ padding: '20px', height: '70vh', overflow: 'auto' }}>
        <Grid container spacing={2} justifyContent="flex-end" sx={{ marginBottom: '20px' }}>
          <Grid item>
            <Button variant="contained"
              sx={{
                backgroundColor: '#5A9BD5',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#5A9BD5',
                  opacity: 1
                }
              }}
              endIcon={<EditIcon />}
              onClick={handleEditClick}>{isEditing ? 'Cancel' : 'Edit'}
            </Button>
          </Grid>
          <Grid item>
            {isEditing && (
              <Button variant="outlined"
                sx={{
                  backgroundColor: '#1B4965',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: '#1B4965',
                    opacity: 1
                  }
                }}
                endIcon={<RestartAltIcon />}
                onClick={() => {
                  if (isEditing) {
                    handleUpdate();
                  }
                }}
              > Update
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <TextField id="designationName" name="designationName" label="Designation Name" variant="outlined" fullWidth margin="dense"
              InputProps={{ readOnly: !isEditing }}
              onChange={(e) => setDesignationDetails({ ...designationDetails, designationName: e.target.value })}
              value={designationDetails?.designationName || ''}
              sx={{
                '& .MuiInputLabel-root': { fontSize: '14px' },
                '& .MuiInputBase-root': { fontSize: '14px' },
                width: '100%'
              }}
            />
          </Grid>
          <Grid item xs={6}>
            {isEditing ? (
              <FormControl variant='outlined' required fullWidth margin='dense'>
                <InputLabel id="designation">Designation Head</InputLabel>
                <Select labelId='designation' id='designation' label="Designation" margin="dense"
                  value={selectedDesignationHeadId}
                  onChange={handleSelectChange}
                  sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '100%' }}
                >
                  {designationUsers?.map((users) => (
                    <MenuItem key={users._id} value={users._id}>
                      {users.fullName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <TextField id="designation" name="designation" label="Designation Head" variant="outlined" fullWidth margin="dense" InputProps={{ readOnly: !isEditing }}
                onChange={handleInputChange}
                value={designationDetails?.designationHead?.name || ''}
                sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '100%' }}
              />
            )}
          </Grid>
        </Grid>
        <TextField id="description" name="description" label="Description" variant="outlined" fullWidth margin="dense"
          InputProps={{ readOnly: !isEditing }}
          onChange={(e) => setDesignationDetails({ ...designationDetails, description: e.target.value })}
          value={designationDetails?.description || ''}
          sx={{
            '& .MuiInputLabel-root': { fontSize: '14px' },
            '& .MuiInputBase-root': { fontSize: '14px' },
            width: '100%'
          }}
        />
      </Container>
      {(loading) && (
        <Loader />
      )}
    </span>
  )
}

export default DesignationData

const Container = styled.div`
    padding: 20px;
    height: 70vh;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: #1B4965 #f0f0f0;

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #1B4965;
        border-radius: 20px;
    }

    &::-webkit-scrollbar-track {
        background: #f0f0f0;
    }
`;