import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ProtectedSystemAdminContainer from '../utils/ProtectedContainers/ProtectedSystemAdminContainer';
import SystemAdminDahboard from '../Pages/SystemAdmin/SystemAdminDashboard/SystemAdminDahboard';
import SubscriberList from '../Pages/SystemAdmin/SubscribersList/SubscriberList'
import SubscriberDetails from '../Pages/SystemAdmin/SubscribersList/SubscriberDetails/SubscriberDetails';

const SystemAdminRoutes: React.FC = () => (
  <Routes>
    <Route element={<ProtectedSystemAdminContainer />}>
      <Route path="/" element={<SystemAdminDahboard />} />
      <Route path="/subscriberList" element={<SubscriberList />} />
      <Route path="/subscriber-details/:subscriberId" element={<SubscriberDetails />} />
    </Route>
  </Routes>
);

export default SystemAdminRoutes;
