import React from 'react';
import { Route, Routes } from 'react-router-dom';

import LoginRoutes from './routes/LoginRoutes';
import SystemAdminRoutes from './routes/SystemAdminRoutes';
import CompanyAdminRoutes from './routes/CompanyAdminRoutes';
import UserRoutes from './routes/UserRoutes';
import ClientCoordinatorsRoutes from './routes/ClientCoordinatorsRoutes';

const RoutesConfig: React.FC = () => (
  <Routes>
    <Route path="/*" element={<LoginRoutes />} />
    <Route path="/systemadmin/*" element={<SystemAdminRoutes />} />
    <Route path="/subscriber/*" element={<CompanyAdminRoutes />} />
    <Route path="/user/*" element={<UserRoutes />} />
    <Route path='/client/*' element={<ClientCoordinatorsRoutes/>}/>
  </Routes>
);

export default RoutesConfig;
