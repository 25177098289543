import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Swal from 'sweetalert2';
import { Box, Button } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';

import './DailyReport.css'
import { selectToken } from '../../../Redux/selector';
import Loader from '../../../Components/UiUxComponents/Loader/Loader';
import AddTask from '../../../Components/UserComponents/DailyReportsComponents/AddTask/AddTask';
import { getDailyReport, deleteDailyReport, submitDailyReport } from '../../../utils/Services/dailyReportsServices';

function DailyReports() {
  const getFormattedDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const [selectedDate, setSelectedDate] = useState(getFormattedDate(new Date()));
  const [dailyReportData, setDailyReportData] = useState([]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [isEditing, setIsEditing] = useState()
  const [loading, setLoading] = useState(false);
  const token = useSelector(selectToken);
  const { userId } = useParams();

  // To get user daily report data
  const fetchDailyReport = async () => {
    setLoading(true);
    try {
      const data = { selectedDate }
      const response = await getDailyReport(token, userId, data);
      setDailyReportData(response?.data || [])
    } catch (err) {
      console.error('Error fetching user daily report:', err);
      Swal.fire({
        icon: 'error',
        title: 'Failed ',
        text: 'Failed to fetch user daily report',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchDailyReport();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  // To Handle Date Change
  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  // Open AddTask form
  const openTaskForm = () => {
    setIsFormOpen(true);
    setIsEditing(false)
  };

  // Close AddTask form
  const closeTaskForm = () => {
    setIsFormOpen(false);
    setSelectedTask(null)
    fetchDailyReport();
  };

  // handle to edit task data
  const handleEdit = (taskId) => {
    const taskToEdit = dailyReportData.task.find(task => task._id === taskId);
    if (taskToEdit) {
      setSelectedTask(taskToEdit);
      setIsFormOpen(true);
      setIsEditing(true)
    }
  };

  // handle to delete task data
  const handleDelete = async (taskId) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you really want to delete this task?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#1B4965',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      customClass: {
        container: 'swal-container',
      }
    });

    if (result.isConfirmed) {
      const data = {
        selectedDate,
      };
      try {
        setLoading(true);
        console.log(token);
        await deleteDailyReport(token, userId, taskId, data);
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Daily Report deleted successfully!',
          confirmButtonColor: '#1B4965',
          customClass: {
            container: 'swal-container',
          }
        });
      } catch (error) {
        console.error('Error:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to delete daily report. Please try again later.',
          confirmButtonColor: '#1B4965',
          customClass: {
            container: 'swal-container',
          }
        });
      } finally {
        setLoading(false);
        fetchDailyReport();
      }
    }
  };

  // To submit daily report
  const handleSubmit = async () => {
    const confirmResult = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to submit the daily report?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#1B4965',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, submit!',
      cancelButtonText: 'Cancel',
      customClass: {
        container: 'swal-container',
      }
    });

    if (confirmResult.isConfirmed) {
      const data = {
        selectedDate,
      };
      try {
        setLoading(true);
        await submitDailyReport(token, userId, data);
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Daily Report Submitted successfully!',
          confirmButtonColor: '#1B4965',
          customClass: {
            container: 'swal-container',
          }
        });
      } catch (error) {
        console.error('Error:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to submit daily report. Please try again later.',
          confirmButtonColor: '#1B4965',
          customClass: {
            container: 'swal-container',
          }
        });
      } finally {
        setLoading(false);
        fetchDailyReport();
      }
    }
  };

  return (
    <div className='container'>
      <Box sx={{ padding: 2, maxHeight: '85vh', overflow: 'auto' }} className="custom-scrollbar">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
          <h2 style={{ textAlign: 'left', color: '#1B4965' }}>Daily Report:- {selectedDate}</h2>
          <div>
            <label htmlFor="report-date" style={{ marginRight: '10px', color: '#1B4965', fontWeight: 'bold' }}>Select Date:</label>
            <input type="date" id="report-date" value={selectedDate} onChange={handleDateChange}
              style={{ padding: '10px', borderRadius: '5px', border: '1px solid #1B4965', color: '#1B4965', backgroundColor: '#F0F4F8', fontSize: '16px', fontWeight: 'bold', outline: 'none', cursor: 'pointer' }}
            />
          </div>
        </div>
        <div style={{ marginBottom: '40px' }}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {(dailyReportData?.editable === true || dailyReportData?.editable == null) && (
              <Button variant="contained" color="primary"
                sx={{
                  marginY: 2,
                  backgroundColor: '#1B4965',
                  '&:hover': {
                    backgroundColor: '#1B4965',
                  }
                }} onClick={openTaskForm}>Add Task</Button>
            )}
          </div>
          <div style={{ flex: 1, border: '1px solid #1B4965', borderRadius: '5px', padding: '10px', backgroundColor: '#ffffff', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <thead>
                <tr>
                  <th style={{ borderBottom: '2px solid #1B4965', padding: '12px', textAlign: 'left', backgroundColor: '#F0F4F8', color: '#1B4965' }}>S.No</th>
                  <th style={{ borderBottom: '2px solid #1B4965', padding: '12px', textAlign: 'left', backgroundColor: '#F0F4F8', color: '#1B4965' }}>Title</th>
                  <th style={{ borderBottom: '2px solid #1B4965', padding: '12px', textAlign: 'left', backgroundColor: '#F0F4F8', color: '#1B4965' }}>Client Name</th>
                  <th style={{ borderBottom: '2px solid #1B4965', padding: '12px', textAlign: 'left', backgroundColor: '#F0F4F8', color: '#1B4965' }}>No. of hours</th>
                  <th style={{ borderBottom: '2px solid #1B4965', padding: '12px', textAlign: 'left', backgroundColor: '#F0F4F8', color: '#1B4965' }}>Status</th>
                  <th style={{ borderBottom: '2px solid #1B4965', padding: '12px', textAlign: 'left', backgroundColor: '#F0F4F8', color: '#1B4965' }}>Comments</th>
                  {(dailyReportData?.editable === true || dailyReportData?.editable == null) && (
                    <th style={{ borderBottom: '2px solid #1B4965', padding: '12px', textAlign: 'left', backgroundColor: '#F0F4F8', color: '#1B4965' }}>Action</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {dailyReportData.length === 0 ? (
                  <tr>
                    <td colSpan="5" style={{ textAlign: 'center', padding: '20px', color: '#1B4965' }}>No Task is Added</td>
                  </tr>
                ) : (
                  dailyReportData.task
                    .sort((a, b) => a.clientName.localeCompare(b.clientName))
                    .map((task, index) => (
                      <tr key={task._id}>
                        <td style={{ borderBottom: '1px solid #1B4965', padding: '12px' }}>{index + 1}</td>
                        <td style={{ borderBottom: '1px solid #1B4965', padding: '12px' }}>{task.taskTitle}</td>
                        <td style={{ borderBottom: '1px solid #1B4965', padding: '12px' }}>{task.clientName}</td>
                        <td style={{ borderBottom: '1px solid #1B4965', padding: '12px' }}>{task.hours}</td>
                        <td style={{ borderBottom: '1px solid #1B4965', padding: '12px' }}>{task.taskStatus}</td>
                        <td style={{ borderBottom: '1px solid #1B4965', padding: '12px' }}>{task.comments}</td>
                        {(dailyReportData?.editable === true || dailyReportData?.editable == null) && (
                          <td style={{ borderBottom: '1px solid #1B4965', padding: '12px', }}>
                            <Edit style={{ cursor: 'pointer', marginRight: '15px', color: 'green' }} onClick={() => handleEdit(task._id)} />
                            <Delete style={{ cursor: 'pointer', color: 'red' }} onClick={() => handleDelete(task._id)} />
                          </td>
                        )}
                      </tr>
                    ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Box>
      {isFormOpen && (
        <AddTask
          open={isFormOpen}
          handleClose={closeTaskForm}
          selectedDate={selectedDate}
          selectedTask={selectedTask}
          isEditing={isEditing}
        />
      )}
      {(loading) && (
        <Loader />
      )}
      <footer className="footer">
        <div style={{ padding: '10px', backgroundColor: '#1B4965', color: 'white' }}>
          <button onClick={handleSubmit} style={{ padding: '15px', borderRadius: '4px', backgroundColor: '#F0F4F8', cursor: 'pointer', marginLeft: '35%' }}>Submit Daily Task</button>
          <p style={{ marginTop: '10px', fontSize: '14px', color: 'red', marginLeft: '25%' }}>Note: Submit at end of the day. Once you submit, you can't submit again.</p>
        </div>
      </footer>
    </div>
  )
}

export default DailyReports