import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Swal from 'sweetalert2';
import { MaterialReactTable, useMaterialReactTable, type MRT_ColumnDef, } from 'material-react-table';
import { ListItemIcon, MenuItem } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import './Departments.css'
import Loader from '../../../UiUxComponents/Loader/Loader';
import { DepartmentType } from '../../../../types'
import { selectToken } from '../../../../Redux/selector';
import Pagination from '../../../Pagination/Pagination';
import DepartmentRegistration from '../../../Registrations/DepartmentRegistration/DepartmentRegistration'
import { getDepartment, deleteDepartment } from '../../../../utils/Services/subscriberConfigurationServices/departmentsServices';

function Departments() {
  const [data, setData] = useState<DepartmentType[]>([]);
  const [openModal, setDepartmentRegistrationModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const token = useSelector(selectToken);
  const { subscriberId } = useParams();
  const navigate = useNavigate();

  // To fetch Department Details
  const fetchDepartmentDetail = async () => {
    setLoading(true);
    try {
      const response = await getDepartment(token, subscriberId);
      setData(response?.data);
    } catch (error) {
      console.error('Error:', error);
      Swal.fire({
        icon: 'error',
        title: 'Failed ',
        text: 'Failed to fetch data',
        confirmButtonColor: '#1B4965',
        customClass: {
            container: 'swal-container',
        }
    })
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchDepartmentDetail();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!openModal) {
      fetchDepartmentDetail();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  // To open Department Registration Modal
  const openDepartmentRegistrationModal = () => {
    setDepartmentRegistrationModal(true);
  };

  // To close Department Registration Modal
  const closeDepartmentRegistrationModal = () => {
    setDepartmentRegistrationModal(false);
  };

  // To Handle page change in pagination
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteDepartmentById = async (departmentId: string | undefined) => {
    if (departmentId) {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1B4965',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          container: 'swal-container',
        }
      });

      if (result.isConfirmed) {
        setLoading(true);
        try {
          await deleteDepartment(token, departmentId);
          Swal.fire({
            icon: 'success',
            title: 'Department Deleted Successfully',
            confirmButtonColor: '#1B4965',
            customClass: {
              container: 'swal-container',
            }
          });
        } catch (error) {
          console.error('Error department user:', error);
          Swal.fire({
            icon: 'error',
            title: 'Failed to department user',
            confirmButtonColor: '#1B4965',
            customClass: {
              container: 'swal-container',
            }
          });
        } finally {
          setLoading(false);
          fetchDepartmentDetail();
        }
      }
    }
  };

  const columns = useMemo<MRT_ColumnDef<DepartmentType>[]>(
    () => [
      { accessorKey: 'departmentName', header: 'Department Name' },
      {
        accessorFn: row => row.departmentHead.name,
        id: 'departmentHeadName',
        header: 'Department Head Name',
      },
      { accessorKey: 'description', header: 'Description' },
    ], []
  );

  // To set table header styles
  const muiTableHeadCellProps = {
    sx: {
      backgroundColor: '#1B4965',
      color: '#fff',
      fontWeight: 'bold',
      '& .MuiTableSortLabel-root .MuiTableSortLabel-icon': {
        color: '#fff !important',
      },
      '& .MuiTableSortLabel-icon': {
        color: '#fff',
      },
      '& .MuiIconButton-root': {
        color: '#fff',
      },
      '&:first-of-type': {
        borderTopLeftRadius: '12px',
      },
      '&:last-of-type': {
        borderTopRightRadius: '12px',
      },
      fontSize: '14px'
    },
  };

  // To set table body row styles
  const muiTableBodyRowProps = (row: any) => ({
    sx: {
      backgroundColor: getRowBackgroundColor(row),
    }
  })

  // To set table body cell styles
  const muiTableBodyCellProps = (row: any) => ({
    sx: {
      fontSize: '13px',
    }
  })

  // To set background color for even rows in tables
  const getRowBackgroundColor = (row: any) => {
    return row?.row?.index % 2 === 0 ? "#ffffff" : "#d6e1ed"
  };

  // To Render table
  const table = useMaterialReactTable({
    columns,
    data: data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    enableTopToolbar: false,
    muiTableHeadCellProps,
    muiTableBodyRowProps,
    muiTableBodyCellProps,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enablePagination: false,
    enableRowActions: true,
    renderBottomToolbarCustomActions: () => (
      <Pagination
        count={data.length}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    ),
    initialState: {
      density: 'comfortable',
      columnPinning: { right: ['mrt-row-actions'] }
    },
    muiTablePaperProps: ({ table }) => ({
      style: {
        zIndex: -1,
      },
    }),
    muiTableContainerProps: { sx: { maxHeight: '65vh' } },
    renderRowActionMenuItems: ({ row, closeMenu }) => {
      const { _id } = row.original;
      return [
        <MenuItem
          key={0}
          onClick={() => {
            navigate(`/subscriber/view-department/${_id}`);
            closeMenu();
          }}
          sx={{ m: 0, fontSize: '13px' }}
        >
          <ListItemIcon>
            <AccountCircle style={{ fontSize: '18px' }} />
          </ListItemIcon>
          View Department
        </MenuItem>,
        <MenuItem
          key={1}
          onClick={() => {
            deleteDepartmentById(_id)
            closeMenu();
          }}
          sx={{ m: 0, fontSize: '13px' }}
        >
          <ListItemIcon>
            <DeleteForeverIcon style={{ fontSize: '18px' }} />
          </ListItemIcon>
          Delete Department
        </MenuItem>,
      ];
    },
  })

  return (
    <div>
      <div className='departments-dashboard'>
        <div className='departments-title'>
          <p>Departments</p>
        </div>
        <button className='add-department' onClick={openDepartmentRegistrationModal}>Create New</button>
      </div>
      <div className='departmentstable'>
        <MaterialReactTable table={table} />
      </div>
      <DepartmentRegistration open={openModal} onClose={closeDepartmentRegistrationModal} />
      {(loading) && (
        <Loader />
      )}
    </div>
  )
}

export default Departments
