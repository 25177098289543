import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Swal from 'sweetalert2';
import { MaterialReactTable, useMaterialReactTable, type MRT_ColumnDef, } from 'material-react-table';
import { Button, ListItemIcon, MenuItem, } from '@mui/material';
import { AccountCircle, Send } from '@mui/icons-material';

import './SubscriberList.css'
import { Roles } from '../../../utils/constant';
import Loader from '../../../Components/UiUxComponents/Loader/Loader'
import { CompanyType } from '../../../types';
import { selectToken } from '../../../Redux/selector';
import Pagination from '../../../Components/Pagination/Pagination';
import SubscriberRegistration from '../../../Components/Registrations/SubscriberRegistration/SubscriberRegistration';
import { getCompanyDetails } from '../../../utils/Services/companyServices'

const Sysadmindashboard = () => {
  const [data, setData] = useState<CompanyType[]>([]);
  const [openModal, setCompanyRegistrationModal] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [activeList, setActiveList] = useState<'subscribers' | 'clients'>('subscribers');
  const [loading, setLoading] = useState<boolean>(false);
  const token = useSelector(selectToken);
  const navigate = useNavigate();

  // To fetch Company Details
  const fetchCompanyDetails = async () => {
    setLoading(true);
    try {
      const result = await getCompanyDetails(token);
      const transformedData = result.data.map((company: CompanyType) => ({
        ...company,
        companyAdminName: company.employees && company?.employees?.length > 0 ? company?.employees[0]?.fullName : 'N/A',
        location: company.city && company.country ? `${company.city}, ${company.country}` : 'N/A'
      }));
      setData(transformedData);
    } catch (error) {
      console.error('Error fetching company details:', error);
      Swal.fire({
        icon: 'error',
        title: 'Failed ',
        text: 'Failed to fetch company details',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchCompanyDetails();
  }, []);
  useEffect(() => {
    if (!openModal) {
      fetchCompanyDetails();
    }
  }, [openModal]);

  // To open Company Registration Modal
  const openCompanyRegistrationModal = () => {
    setCompanyRegistrationModal(true);
  };

  // To close Company Registration Modal
  const closeCompanyRegistrationModal = () => {
    setCompanyRegistrationModal(false);
  };

  // To Handle page change in pagination
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // To display columns
  const columns = useMemo<MRT_ColumnDef<CompanyType>[]>(
    () => [
      { accessorKey: 'companyCode', header: 'Company Code' },
      { accessorKey: 'companyName', header: 'Company Name' },
      { accessorKey: 'companyOwnerName', header: 'Company Owner Name' },
      { accessorKey: 'companyAdminName', header: 'Company Admin Name' },
      { accessorKey: 'location', header: 'Location' },
    ],
    [activeList],
  );

  // To set table header styles
  const muiTableHeadCellProps = {
    sx: {
      backgroundColor: '#1B4965',
      color: '#fff',
      fontWeight: 'bold',
      '& .MuiTableSortLabel-root .MuiTableSortLabel-icon': {
        color: '#fff !important',
      },
      '& .MuiTableSortLabel-icon': {
        color: '#fff',
      },
      '& .MuiIconButton-root': {
        color: '#fff',
      },
      '&:first-of-type': {
        borderTopLeftRadius: '12px',
      },
      '&:last-of-type': {
        borderTopRightRadius: '12px',
      },
      fontSize: '14px'
    },
  };

  // To set table body row styles
  const muiTableBodyRowProps = (row: any) => ({
    sx: {
      backgroundColor: getRowBackgroundColor(row),
    }
  })

  // To set table body cell styles
  const muiTableBodyCellProps = (row: any) => ({
    sx: {
      fontSize: '13px'
    }
  })

  // To set background color for even rows in tables
  const getRowBackgroundColor = (row: any) => {
    return row?.row?.index % 2 === 0 ? "#ffffff" : "#d6e1ed"
  };

  // Filter data based on active list
  const filteredData = useMemo(() => {
    return data.filter((company) =>
      activeList === 'subscribers' ? company.companyRole === Roles.COMPANY_SUBSCRIBER : company.companyRole === Roles.COMPANY_CLIENT
    );
  }, [data, activeList]);

  // To Render table
  const table = useMaterialReactTable({
    columns,
    data: filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    enableTopToolbar: false,
    muiTableHeadCellProps,
    muiTableBodyRowProps,
    muiTableBodyCellProps,
    enablePagination: false,
    enableRowActions: true,
    renderBottomToolbarCustomActions: () => (
      <Pagination
        count={data.length}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    ),
    initialState: {
      columnPinning: { right: ['mrt-row-actions'] }
    },
    muiTablePaperProps: ({ table }) => ({
      style: {
        zIndex: -1
      },
    }),
    renderRowActionMenuItems: ({ row, closeMenu }) => [
      <MenuItem
        key={0}
        onClick={() => {
          navigate(`/systemadmin/subscriber-details/${row.original._id}`);
          closeMenu();
        }}
        sx={{ m: 0, fontSize: '13px' }}
      >
        <ListItemIcon>
          <AccountCircle style={{ fontSize: '18px' }} />
        </ListItemIcon>
        View Details
      </MenuItem>,
      <MenuItem
        key={1}
        onClick={() => {
          alert("Write send email logic")
          closeMenu();
        }}
        sx={{ m: 0, fontSize: '13px' }}
      >
        <ListItemIcon >
          <Send style={{ fontSize: '18px' }} />
        </ListItemIcon>
        Send Email
      </MenuItem >,
    ],
  })

  return (
    <div>
      <div className='sysadmin-dashboard'>
        <div className="sysadmin-title">
          <div style={{ display: 'flex', justifyContent: 'space-between', gap: '20px' }}>
            <Button
              variant={activeList === 'subscribers' ? 'contained' : 'outlined'}
              sx={{
                background: activeList === 'subscribers' ? 'linear-gradient(90deg, #1B4965 63.83%, #051925 82.97%)' : 'transparent',
                color: activeList === 'subscribers' ? 'white' : '#1B4965',
                border: '1px solid #1B4965',
                height: '35px',
                textAlign: 'center',
                fontSize: '14px',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
              onClick={() => setActiveList('subscribers')}
            >
              Subscribers List
            </Button>
            <Button
              variant={activeList === 'clients' ? 'contained' : 'outlined'}
              sx={{
                background: activeList === 'clients' ? 'linear-gradient(90deg, #1B4965 63.83%, #051925 82.97%)' : 'transparent',
                color: activeList === 'clients' ? 'white' : '#1B4965',
                border: '1px solid #1B4965',
                width: '130px',
                height: '35px',
                textAlign: 'center',
                fontSize: '14px',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
              onClick={() => setActiveList('clients')}
            >
              Client List
            </Button>
          </div>
        </div>
        <button className='sysadmin-addcompany' onClick={openCompanyRegistrationModal}>Add Company</button>
      </div>
      <div className='sysadmin-companytable'>
        <MaterialReactTable table={table} />
      </div>
      <SubscriberRegistration open={openModal} onClose={closeCompanyRegistrationModal} />
      {(loading) && (
        <Loader />
      )}
    </div>
  );
};

export default Sysadmindashboard
