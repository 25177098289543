import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from 'styled-components';

import Swal from 'sweetalert2';
import { Button, Grid, IconButton, TextField } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';

import Loader from '../../../UiUxComponents/Loader/Loader';
import { selectToken } from '../../../../Redux/selector';
import { getuserDetailsByTabName, updateUser } from '../../../../utils/Services/userServices'

function EducationDetails() {
    const [educationInformationDetails, setEducationInformationDetails] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const token = useSelector(selectToken);
    const { userId } = useParams();
    const tabName = "educationDetails"

    // To get education details
    const fetchEducationDetails = async () => {
        setLoading(true);
        try {
            const response = await getuserDetailsByTabName(token, tabName, userId);
            const data = response?.data[0]?.qualification || [];
            setEducationInformationDetails(data)
        } catch (err) {
            console.error('Error fetching user education details:', err);
            Swal.fire({
                icon: 'error',
                title: 'Failed ',
                text: 'Failed to fetch user education details',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchEducationDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Handle to edit the fields
    const handleEditClick = async () => {
        if (isEditing) {
            await fetchEducationDetails();
        }
        setIsEditing(prevEditing => {
            const newEditingState = !prevEditing;
            if (newEditingState) {
                Swal.fire({
                    icon: 'info',
                    title: 'Editing Enabled',
                    text: 'Now you can edit the fields.',
                    confirmButtonColor: '#1B4965',
                    customClass: {
                        container: 'swal-container',
                    }
                });
            }
            return newEditingState;
        });
    };

    // To add new row
    const handleAddData = () => {
        setEducationInformationDetails([
            ...educationInformationDetails,
            { educationQualification: '', nameOfInstitution: '', subjects: '', yearOfPassing: '', percentage: '' }
        ]);
    };

    // Handle change of input field
    const handleChange = (index, field, value) => {
        const updatedEducationDetails = [...educationInformationDetails];
        updatedEducationDetails[index] = { ...updatedEducationDetails[index], [field]: value };
        setEducationInformationDetails(updatedEducationDetails);
    };

    // Handle remove row
    const handleRemoveData = (index) => {
        const updatedEducationDetail = educationInformationDetails.filter((_, i) => i !== index);
        setEducationInformationDetails(updatedEducationDetail);
    };

    // Hanlde Update
    const handleUpdate = async () => {
        setLoading(true);
        try {
            const data = { qualification: educationInformationDetails }
            const updatedData = { data }
            await updateUser(token, userId, tabName, updatedData);
            Swal.fire({
                icon: 'success',
                title: 'Education Details Updated',
                text: 'The education details have been successfully updated.',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
        } catch (err) {
            Swal.fire({
                icon: 'error',
                title: 'Update Failed',
                text: 'There was an error updating the education details. Please try again.',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
            console.error('Error updating education details:', err);
        } finally {
            setLoading(false);
            setIsEditing(false);
            fetchEducationDetails();
        }
    };

    return (
        <span>
            <Container>
                <Grid container spacing={2} justifyContent="flex-end" sx={{ marginBottom: '20px' }}>
                    <Grid item>
                        {isEditing && (
                            <Button variant="contained"
                                sx={{
                                    backgroundColor: '#51b86c',
                                    color: '#fff',
                                    '&:hover': {
                                        backgroundColor: '#51b86c',
                                        opacity: 1
                                    }
                                }}
                                endIcon={<AddCircleIcon />}
                                onClick={handleAddData}>Add
                            </Button>
                        )}
                    </Grid>
                    <Grid item>
                        <Button variant="contained"
                            sx={{
                                backgroundColor: '#5A9BD5',
                                color: '#fff',
                                '&:hover': {
                                    backgroundColor: '#5A9BD5',
                                    opacity: 1
                                }
                            }}
                            endIcon={<EditIcon />}
                            onClick={handleEditClick}>{isEditing ? 'Cancel' : 'Edit'}
                        </Button>
                    </Grid>
                    <Grid item>
                        {isEditing && (
                            <Button variant="outlined"
                                sx={{
                                    backgroundColor: '#1B4965',
                                    color: '#fff',
                                    '&:hover': {
                                        backgroundColor: '#1B4965',
                                        opacity: 1
                                    }
                                }}
                                endIcon={<RestartAltIcon />}
                                onClick={() => {
                                    if (isEditing) {
                                        handleUpdate();
                                    }
                                }}
                            > Update
                            </Button>
                        )}
                    </Grid>
                </Grid>
                {educationInformationDetails.map((education, index) => (
                    <Grid container spacing={2} alignItems="center" key={index}>
                        <Grid item xs={2}>
                            <TextField required id={`educationQualification-${index}`} name="educationQualification" label="Education Qualification" variant="outlined" fullWidth margin="dense"
                                disabled={!isEditing}
                                onChange={(e) => handleChange(index, 'educationQualification', e.target.value)}
                                value={education.educationQualification || ''}
                                sx={{
                                    '& .MuiInputLabel-root': { fontSize: '14px' },
                                    '& .MuiInputBase-root': { fontSize: '14px' },
                                    width: '100%'
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField required id={`nameOfInstitution-${index}`} name="nameOfInstitution" label="Name of Institution" variant="outlined" fullWidth margin="dense"
                                disabled={!isEditing}
                                value={education.nameOfInstitution || ''}
                                onChange={(e) => handleChange(index, 'nameOfInstitution', e.target.value)}
                                sx={{
                                    '& .MuiInputLabel-root': { fontSize: '14px' },
                                    '& .MuiInputBase-root': { fontSize: '14px' },
                                    width: '100%'
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField id={`subjects-${index}`} name="subjects" label="Subjects" variant="outlined" fullWidth margin="dense"
                                disabled={!isEditing}
                                value={education.subjects || ''}
                                onChange={(e) => handleChange(index, 'subjects', e.target.value)}
                                sx={{
                                    '& .MuiInputLabel-root': { fontSize: '14px' },
                                    '& .MuiInputBase-root': { fontSize: '14px' },
                                    width: '100%'
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField required id={`yearOfPassing-${index}`} name="yearOfPassing" label="Year of Passing" variant="outlined" fullWidth margin="dense"
                                disabled={!isEditing}
                                value={education.yearOfPassing || ''}
                                onChange={(e) => handleChange(index, 'yearOfPassing', e.target.value)}
                                sx={{
                                    '& .MuiInputLabel-root': { fontSize: '14px' },
                                    '& .MuiInputBase-root': { fontSize: '14px' },
                                    width: '100%'
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField required id={`percentage-${index}`} name="percentage" label="Percentage" variant="outlined" fullWidth margin="dense"
                                disabled={!isEditing}
                                value={education.percentage || ''}
                                onChange={(e) => handleChange(index, 'percentage', e.target.value)}
                                sx={{
                                    '& .MuiInputLabel-root': { fontSize: '14px' },
                                    '& .MuiInputBase-root': { fontSize: '14px' },
                                    width: '100%'
                                }}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton aria-label="delete" color="error" onClick={() => handleRemoveData(index)} sx={{ visibility: isEditing ? 'visible' : 'hidden' }}>
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                ))}
            </Container>
            {(loading) && (
                <Loader />
            )}
        </span>
    )
}

export default EducationDetails

const Container = styled.div`
    padding: 20px;
    height: 70vh;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: #1B4965 #f0f0f0;

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #1B4965;
        border-radius: 20px;
    }

    &::-webkit-scrollbar-track {
        background: #f0f0f0;
    }
`;
