import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import Swal from 'sweetalert2';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

import Loader from '../../../UiUxComponents/Loader/Loader';
import { selectToken, selectUser } from '../../../../Redux/selector';
import { getuserDetailsByTabName, updateUser } from '../../../../utils/Services/userServices'
import { getLocation } from '../../../../utils/Services/subscriberConfigurationServices/locationServices';
import { getAllShift } from '../../../../utils/Services/shiftServices';

function CompanyDetails() {
    const [officeInformationDetails, setOfficeInformatinDetails] = useState()
    const [locationDetails, setLocationDetails] = useState([])
    const [shiftDetails, setShiftDetails] = useState([])
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const token = useSelector(selectToken);
    const { userId } = useParams();
    const tabName = "companyDetails"
    const user = useSelector(selectUser);
    const subscriberId = user?.companyId?._id

    // To get user office details
    const fetchOfficeDetails = async () => {
        setLoading(true);
        try {
            const response = await getuserDetailsByTabName(token, tabName, userId);
            setOfficeInformatinDetails(response?.data[0])
        } catch (err) {
            console.error('Error fetching user personal details:', err);
            Swal.fire({
                icon: 'error',
                title: 'Failed ',
                text: 'Failed to fetch user company details',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchOfficeDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // To fetch location Details
    const fetchLocationDetail = async () => {
        try {
            const response = await getLocation(token, subscriberId);
            setLocationDetails(response?.data || [])
        } catch (error) {
            console.error('Error:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'There was an error. Please try again later.',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
        }
    }
    useEffect(() => {
        fetchLocationDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // To fetch shift Details
    const fetchShifts = async () => {
        try {
            const response = await getAllShift(token, subscriberId);
            setShiftDetails(response?.data || [])
        } catch (error) {
            console.error('Error:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'There was an error. Please try again later.',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
        }
    }
    useEffect(() => {
        fetchShifts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Handle to edit the fields
    const handleEditClick = async () => {
        if (isEditing) {
            await fetchOfficeDetails();
        }
        setIsEditing(prevEditing => {
            const newEditingState = !prevEditing;
            if (newEditingState) {
                Swal.fire({
                    icon: 'info',
                    title: 'Editing Enabled',
                    text: 'Now you can edit the fields.',
                    confirmButtonColor: '#1B4965',
                    customClass: {
                        container: 'swal-container',
                    }
                });
            }
            return newEditingState;
        });
    };

    // Dropdown for location
    const selectedLocationId = locationDetails?.find(location => location.branchName === officeInformationDetails?.location?.name)?._id || '';
    const handleSelectChange2 = (e) => {
        const selectedId = e.target.value;
        const selectedLocation = locationDetails?.find(location => location._id === selectedId);
        setOfficeInformatinDetails({
            ...officeInformationDetails,
            location: {
                _id: selectedId,
                name: selectedLocation?.branchName || ''
            }
        });
    };

    // Dropdown for shifts
    const selectedShiftId = shiftDetails?.find(shift => shift.shiftTitle === officeInformationDetails?.shiftData?.name)?._id || '';
    const handleSelectChange3 = (e) => {
        const selectedId = e.target.value;
        const selectedShift = shiftDetails?.find(shift => shift._id === selectedId);
        setOfficeInformatinDetails({
            ...officeInformationDetails,
            shiftData: {
                _id: selectedId,
                name: selectedShift?.shiftTitle || ''
            }
        });
    };

    // handle input change
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setOfficeInformatinDetails(prevDetails => ({
            ...prevDetails,
            [name]: value,
        }));
    };

    // Hanlde Update
    const handleUpdate = async () => {
        setLoading(true);
        try {
            const updatedData = { officeInformationDetails }
            await updateUser(token, userId, tabName, updatedData);
            Swal.fire({
                icon: 'success',
                title: 'Company Details Updated',
                text: 'The company details have been successfully updated.',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
        } catch (err) {
            Swal.fire({
                icon: 'error',
                title: 'Update Failed',
                text: 'There was an error updating the comapny details. Please try again.',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
            console.error('Error updating company details:', err);
        } finally {
            setLoading(false);
            setIsEditing(false);
            fetchOfficeDetails();
        }
    };

    return (
        <span>
            <Container style={{ padding: '20px', height: '70vh', overflow: 'auto' }}>
                <Grid container spacing={2} justifyContent="flex-end" sx={{ marginBottom: '20px' }}>
                    <Grid item>
                        <Button variant="contained"
                            sx={{
                                backgroundColor: '#5A9BD5',
                                color: '#fff',
                                '&:hover': {
                                    backgroundColor: '#5A9BD5',
                                    opacity: 1
                                }
                            }}
                            endIcon={<EditIcon />}
                            onClick={handleEditClick}>{isEditing ? 'Cancel' : 'Edit'}
                        </Button>
                    </Grid>
                    <Grid item>
                        {isEditing && (
                            <Button variant="outlined"
                                sx={{
                                    backgroundColor: '#1B4965',
                                    color: '#fff',
                                    '&:hover': {
                                        backgroundColor: '#1B4965',
                                        opacity: 1
                                    }
                                }}
                                endIcon={<RestartAltIcon />}
                                onClick={() => {
                                    if (isEditing) {
                                        handleUpdate();
                                    }
                                }}
                            > Update
                            </Button>
                        )}
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                        <TextField id="employeeCode" name="employeeCode" label="Employee Code" variant="outlined" fullWidth margin="dense" disabled={!isEditing} onChange={(e) => setOfficeInformatinDetails({ ...officeInformationDetails, employeeCode: e.target.value })} value={officeInformationDetails?.employeeCode || ''}
                            sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '100%' }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField id="jobTitle" name="jobTitle" label="Job Title" variant="outlined" fullWidth margin="dense" disabled={!isEditing} onChange={(e) => setOfficeInformatinDetails({ ...officeInformationDetails, jobTitle: e.target.value })} value={officeInformationDetails?.jobTitle || ''}
                            sx={{
                                '& .MuiInputLabel-root': { fontSize: '14px' },
                                '& .MuiInputBase-root': { fontSize: '14px' },
                                width: '100%'
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                        <FormControl variant='outlined' required fullWidth margin='dense'>
                            <InputLabel id="employeeType">Employee Type</InputLabel>
                            <Select labelId='employeeType' id='employeeType' label="Employee Type" disabled={!isEditing} value={officeInformationDetails?.employeeType || ''} onChange={(e) => setOfficeInformatinDetails({ ...officeInformationDetails, employeeType: e.target.value })} margin="dense" sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '100%' }}>
                                <MenuItem value="administrator">Administrator</MenuItem>
                                <MenuItem value="consultant">Consultant</MenuItem>
                                <MenuItem value="contractor">Contractor</MenuItem>
                                <MenuItem value="fulltimehourly">Full-time Hourly</MenuItem>
                                <MenuItem value="fulltimeinternship">Full-time Internship</MenuItem>
                                <MenuItem value="fulltimesalaried">Full-time Salaried</MenuItem>
                                <MenuItem value="parttimehourly">Part-time Hourly</MenuItem>
                                <MenuItem value="parttimeinternship">Part-time Internship</MenuItem>
                                <MenuItem value="parttimesalaried">Part-time Salaried</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        {/* <FormControl variant='outlined' required fullWidth margin='dense'>
                            <InputLabel id="employeeStatus">Employee Status</InputLabel>
                            <Select labelId='employeeStatus' id='employeeStatus' label="Employee Status" inputProps={{ readOnly: !isEditing }} value={officeInformationDetails?.employeeStatus || ''} onChange={(e) => setOfficeInformatinDetails({ ...officeInformationDetails, employeeStatus: e.target.value })} margin="dense" sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '100%' }}>
                                <MenuItem value="active">Active</MenuItem>
                                <MenuItem value="noticeperiod">Notice Period</MenuItem>
                                <MenuItem value="inactive">In Active</MenuItem>
                            </Select>
                        </FormControl> */}
                        {isEditing ? (
                            <FormControl variant='outlined' fullWidth margin='dense'>
                                <InputLabel id="shift">Shift</InputLabel>
                                <Select labelId='shift' id='shift' label="Shift" margin="dense" disabled={!isEditing}
                                    value={selectedShiftId}
                                    onChange={handleSelectChange3}
                                    sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '100%' }}
                                >
                                    {shiftDetails && shiftDetails.length > 0 ? (
                                        shiftDetails?.map((shift) => (
                                            <MenuItem key={shift._id} value={shift._id}>
                                                {shift.shiftTitle}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem value="" disabled>
                                            No Shifts
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        ) : (
                            <TextField id="shift" name="shift" label="Shift" variant="outlined" fullWidth margin="dense" disabled={!isEditing}
                                onChange={handleInputChange}
                                value={officeInformationDetails?.shiftData?.name || ''}
                                sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '100%' }}
                            />
                        )}
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                        <FormControl variant='outlined' required fullWidth margin='dense'>
                            <InputLabel id="workingDayType">Working Day Type</InputLabel>
                            <Select labelId='workingDayType' id='workingDayType' label="Working Day Type" disabled={!isEditing} value={officeInformationDetails?.workingDayType || ''} onChange={(e) => setOfficeInformatinDetails({ ...officeInformationDetails, workingDayType: e.target.value })} margin="dense" sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '100%' }}>
                                <MenuItem value="standard">Standard</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField id="hireDate" label="Hire Date" type="date" name="hireDate" value={officeInformationDetails?.hireDate ? officeInformationDetails.hireDate.split('T')[0] : ''} onChange={(e) => setOfficeInformatinDetails({ ...officeInformationDetails, hireDate: e.target.value })} InputLabelProps={{ shrink: true }} disabled={!isEditing} fullWidth margin="dense" variant="outlined" sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' } }} />
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                        {isEditing ? (
                            <FormControl variant='outlined' required fullWidth margin='dense'>
                                <InputLabel id="location">Branch / Location</InputLabel>
                                <Select labelId='location' id='location' label="Branch / Location" margin="dense"
                                    value={selectedLocationId}
                                    onChange={handleSelectChange2}
                                    sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '100%' }}
                                >
                                    {locationDetails && locationDetails.length > 0 ? (
                                        locationDetails?.map((location) => (
                                            <MenuItem key={location._id} value={location._id}>
                                                {location.branchName}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem value="" disabled>
                                            No Branches
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        ) : (
                            <TextField id="location" name="location" label="Branch / Location" variant="outlined" fullWidth margin="dense" disabled={!isEditing}
                                onChange={handleInputChange}
                                value={officeInformationDetails?.location?.name || ''}
                                sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '100%' }}
                            />
                        )}
                    </Grid>
                </Grid>
            </Container>
            {(loading) && (
                <Loader />
            )}
        </span>
    )
}

export default CompanyDetails

const Container = styled.div`
    padding: 20px;
    height: 70vh;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: #1B4965 #f0f0f0;

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #1B4965;
        border-radius: 20px;
    }

    &::-webkit-scrollbar-track {
        background: #f0f0f0;
    }
`;
