import axios from 'axios';
const backEndUrl = `${process.env.REACT_APP_BACKEND_URL}/api/subscriber-configuration/location`;

// To Create Loation
export const createLocation = (token, subscriberId, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.post(`${backEndUrl}/create-location/${subscriberId}`, data, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// To get Location based on subscriber
export const getLocation = (token, subscriberId) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.get(`${backEndUrl}/get-location/${subscriberId}`, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// // To get Location data with members
export const getLoctionDataWithMembers = (token, locationId) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.get(`${backEndUrl}/get-location-data/${locationId}`, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// // To update Location 
export const updateLocation = (token, locationId, locationDetails) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.put(`${backEndUrl}/update-location/${locationId}`, locationDetails, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// To Delete Location
export const deleteLocation = (token, locationId) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.delete(`${backEndUrl}/delete-location/${locationId}`, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};