import React, { useEffect, useRef, useState } from 'react';
import * as pdfjsLib from 'pdfjs-dist';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchPlus, faSearchMinus, faTimes, faDownload } from '@fortawesome/free-solid-svg-icons';

// Set the workerSrc to the correct path dynamically
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;


const PdfViewerModal = ({ isOpen, onRequestClose, url, fileInfo }) => {
    const canvasRef = useRef(null);
    const [scale, setScale] = useState(1);

    useEffect(() => {
        const loadPdf = async () => {
            const loadingTask = pdfjsLib.getDocument(url);
            const pdf = await loadingTask.promise;
            const page = await pdf.getPage(1);
            const viewport = page.getViewport({ scale });

            const canvas = canvasRef.current;
            const context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            const renderContext = {
                canvasContext: context,
                viewport: viewport,
            };
            page.render(renderContext);
        };

        loadPdf();
    }, [url, scale]);

    const handleZoomIn = () => {
        setScale(prevScale => prevScale + 0.1);
    };

    const handleZoomOut = () => {
        setScale(prevScale => Math.max(prevScale - 0.1, 0.1));
    };

    const formatFileSize = (sizeInBytes) => {
        return (sizeInBytes / (1024 * 1024)).toFixed(2) + ' MB';
    };

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = url;
        link.download = fileInfo.name;
        link.click();
    };

    return (
        <Dialog
            open={isOpen}
            onClose={onRequestClose}
            maxWidth="lg"
            fullWidth
        >
            <DialogContent style={{ display: 'flex', height: '100%', position: 'relative' }}>
                <IconButton
                    onClick={onRequestClose}
                    style={{ position: 'absolute', top: '10px', right: '10px' }}
                >
                    <FontAwesomeIcon icon={faTimes} />
                </IconButton>
                <Box style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Box mb={2}>
                        <IconButton onClick={handleZoomIn} style={{ marginRight: '10px' }}>
                            <FontAwesomeIcon icon={faSearchPlus} />
                        </IconButton>
                        <IconButton onClick={handleZoomOut}>
                            <FontAwesomeIcon icon={faSearchMinus} />
                        </IconButton>
                    </Box>
                    <canvas ref={canvasRef}></canvas>
                </Box>
                <Box style={{ flex: 0.3, padding: '10px', borderLeft: '1px solid #ccc' }}>
                    <h3>File Information</h3>
                    <p><strong>Name:</strong> {fileInfo.name}</p>
                    <p><strong>Size:</strong> {formatFileSize(fileInfo.size)}</p>
                    <Button
                        variant="contained"
                        style={{ backgroundColor: '#051925', color: '#fff' }}
                        onClick={handleDownload}
                        startIcon={<FontAwesomeIcon icon={faDownload} />}
                    >
                        Download
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default PdfViewerModal;
