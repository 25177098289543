export enum UserStatus {
  Active = 'Active',
  Offline = 'Offline',
  InMeeting = 'InMeeting',
  Away = 'Away',
}

export type StatusColors = Record<UserStatus, string>;

export const statusColors: StatusColors = {
  [UserStatus.Active]: 'green',
  [UserStatus.Offline]: 'red',
  [UserStatus.InMeeting]: 'yellow',
  [UserStatus.Away]: 'purple',
};
