import './Notificationbar.css'

function Notificationbar() {
  return (
    <div className="notificationbar">
      <div className="scroll-text">Remote Teams IT Solutions</div>
    </div>
  )
}

export default Notificationbar
