import axios from 'axios';
const backEndUrl = `${process.env.REACT_APP_BACKEND_URL}/api/userdashboard`;

// To get Attendance status
export const getUserAttendanceStatus = (token, userId, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.get(`${backEndUrl}/user-attendance-status/${userId}`, {
                headers,
                params: data
            });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};