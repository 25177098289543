import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Swal from 'sweetalert2';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Modal, Select, Tab, Tabs, TextField } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import './ClientRegistration.css'
import Fileuploadimg from '../../../assets/Mixed/file-upload.svg';
import timeZonesData from '../../../assets/Country_Timezone/countries+states+cities.json';
import Loader from '../../UiUxComponents/Loader/Loader';
import { selectToken, selectUser } from '../../../Redux/selector';
import { createCompany } from '../../../utils/Services/companyServices'

const ClientRegistration = ({ open, onClose }) => {
    const [value, setValue] = useState(0);
    const [companyDetails, setCompanyDetails] = useState({ companyName: '', companyCode: '', companyOwnerName: '', companyEmail: '', companyWebsite: '', companyRole: '', clientOf: '', companyAddress: '', city: '', states: [], selectedState: '', selectedCountry: '', timeZones: [], selectedTimeZone: '', logo: null, });
    const [adminDetails, setAdminDetails] = useState({ password: '', firstName: '', lastName: '', role: '', workEmail: '', personalEmail: '', mobileNumber: '', designation: '', profilePic: null, uploadedFile: null, });
    const [uploadDocuments, setUploadDocuments] = useState([]);
    const [currentFile, setCurrentFile] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [fileTitle, setFileTitle] = useState('');
    const [fileName, setFileName] = useState('');
    const [companyIds, setCompanyId] = useState([{ idName: '', idValue: '' }])
    const initialCompanyDetails = { companyName: '', companyCode: '', companyOwnerName: '', companyEmail: '', companyWebsite: '', companyAddress: '', city: '', states: [], selectedState: '', selectedCountry: '', timeZones: [], selectedTimeZone: '', logo: null };
    const initialAdminDetails = { password: '', firstName: '', lastName: '', role: '', workEmail: '', personalEmail: '', mobileNumber: '', designation: '', profilePic: null, uploadedFile: null };
    const [loading, setLoading] = useState(false);
    const token = useSelector(selectToken);
    const user = useSelector(selectUser);
    const location = useLocation();

    // Handle Tab Change
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    // Handle Click Next Button
    const handleNext = () => {
        if (value === 0 && !validateCompanyDetails()) {
            return;
        }
        if (value === 1 && !validateAdminDetails()) {
            return;
        }

        setValue((prevValue) => Math.min(prevValue + 1, 3));
    }

    // Handle Click Back Button
    const handleBack = () => {
        setValue((prevValue) => Math.max(prevValue - 1, 0));
    };

    // Handle Close Form
    const handleBeforeUnload = () => {
        const hasCompanyDetails = Object.values(companyDetails).some(value => value !== '' && value !== null && !(Array.isArray(value) && value.length === 0) && !(value === null || value === undefined));
        const hasAdminDetails = Object.values(adminDetails).some(value => value !== '' && value !== null && !(Array.isArray(value) && value.length === 0) && !(value === null || value === undefined));
        const hasDocuments = uploadDocuments.length > 0;

        if (hasCompanyDetails || hasAdminDetails || hasDocuments) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Your entered data will be lost!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#1B4965',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, close it!',
                customClass: {
                    container: 'swal-container',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    resetStates();
                    onClose();
                }
            });
        } else {
            resetStates();
            onClose();
        }
    };

    // Reset the states
    const resetStates = () => {
        setCompanyDetails(initialCompanyDetails);
        setAdminDetails(initialAdminDetails);
        setUploadDocuments([]);
        setCompanyId([{ idName: '', idValue: '' }])
        setValue(0)
    };

    // Step:-1
    // To Upload Company Logo
    const { getRootProps: getLogoRootProps, getInputProps: getLogoInputProps } = useDropzone({
        accept: { 'image/jpeg': [], 'image/png': [], 'image/svg+xml': [] },
        onDrop: (acceptedFiles) => {
            const file = acceptedFiles[0];
            if (file) {
                const fileType = file?.type;
                if (fileType.startsWith('image/')) {
                    setCompanyDetails((prevDetails) => ({
                        ...prevDetails,
                        logo: file,
                    }));
                } else {
                    Swal.fire({
                        title: 'Invalid Image',
                        text: 'Only image files (JPEG, PNG, SVG) are allowed.',
                        icon: 'warning',
                        confirmButtonColor: '#1B4965',
                        customClass: {
                            container: 'swal-container',
                        }
                    });
                }
            }
        },
    });

    // Handle Input Change
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setCompanyDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));
    };

    // Handle City Change
    const handleCityChange = (event) => {
        const cityName = event.target.value;
        let foundStates = [];
        let foundCountry = '';

        timeZonesData.forEach(country => {
            let cityFound = false;
            country.states.forEach(state => {
                state.cities.forEach(city => {
                    if (city.name.toLowerCase() === cityName.toLowerCase()) {
                        foundStates.push(state.name);
                        if (!cityFound) {
                            foundCountry = country.name;
                            cityFound = true;
                        }
                    }
                });
            });
        });

        const newTimeZones = foundStates.length === 1 ? updateTimeZones(foundCountry) : [];

        setCompanyDetails((prevDetails) => ({
            ...prevDetails,
            city: cityName,
            states: foundStates,
            selectedState: '',
            selectedCountry: foundStates.length === 1 ? foundCountry : '',
            timeZones: newTimeZones,
            selectedTimeZone: newTimeZones.length === 1 ? newTimeZones[0].tzName : '',
        }));
    };

    // Handle State Change
    const handleStateChange = (event) => {
        const stateName = event.target.value;
        let foundCountry = '';

        timeZonesData.forEach(country => {
            country.states.forEach(state => {
                if (state.name === stateName) {
                    foundCountry = country.name;
                }
            });
        });

        const newTimeZones = updateTimeZones(foundCountry);

        setCompanyDetails((prevDetails) => ({
            ...prevDetails,
            selectedState: stateName,
            selectedCountry: foundCountry,
            timeZones: newTimeZones,
            selectedTimeZone: newTimeZones.length === 1 ? newTimeZones[0].tzName : '',
        }));
    };

    // Handle Timezones Change
    const handleTimeZoneChange = (event) => {
        const timeZone = event.target.value;
        setCompanyDetails((prevDetails) => ({
            ...prevDetails,
            selectedTimeZone: timeZone,
        }));
    };
    const updateTimeZones = (countryName) => {
        const country = timeZonesData.find(country => country.name === countryName);
        return country ? country.timezones : [];
    }

    // Validate Company Details
    const validateCompanyDetails = () => {
        const { companyName, companyCode, companyOwnerName, companyEmail, companyWebsite } = companyDetails;
        if (!companyName || !companyCode || !companyOwnerName || !companyEmail || !companyWebsite) {
            Swal.fire({
                icon: 'error',
                title: 'Incomplete Information',
                text: 'Please fill in all required fields before proceeding.',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
            return false;
        }
        return true;
    };

    // Step:-2
    // To Upload Profile Pic
    const { getRootProps: getProfilepicRootProps, getInputProps: getProfilepicInputProps } = useDropzone({
        accept: { 'image/jpeg': [], 'image/png': [], 'image/svg+xml': [] },
        onDrop: (acceptedFiles) => {
            const file = acceptedFiles[0];
            if (file) {
                const fileType = file.type;
                if (fileType.startsWith('image/')) {
                    setAdminDetails((adminDetails) => ({
                        ...adminDetails,
                        profilePic: file,
                    }));
                } else {
                    Swal.fire({
                        title: 'Invalid Image',
                        text: 'Only image files (JPEG, PNG, SVG) are allowed.',
                        icon: 'warning',
                        confirmButtonColor: '#1B4965',
                        customClass: {
                            container: 'swal-container',
                        }
                    });
                }
            }
        },
    });

    // To Generate Random Password
    const generatePassword = () => {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let pass = '';
        for (let i = 0; i < 8; i++) {
            pass += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        setAdminDetails({ ...adminDetails, password: pass });
    };

    // To Reset Password
    const resetPassword = () => {
        setAdminDetails({ ...adminDetails, password: '' });
    };

    // Handle File Change
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setAdminDetails({ ...adminDetails, uploadedFile: file });
    };

    // Validate Admin Details
    const validateAdminDetails = () => {
        const { firstName, lastName, role, workEmail, personalEmail, password } = adminDetails;
        if (!firstName || !lastName || !role || !workEmail || !personalEmail || !password) {
            Swal.fire({
                icon: 'error',
                title: 'Incomplete Information',
                text: 'Please fill in all required fields before proceeding.',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
            return false;
        }
        return true;
    };

    // Step:-3 
    // To Upload Company Documents
    const { getRootProps: getDocumentRootProps, getInputProps: getDocumentInputProps } = useDropzone({
        accept: { 'application/pdf': [], 'application/msword': [], 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [] },
        onDrop: (acceptedFiles) => {
            const newFiles = acceptedFiles.filter(file => file.type === 'application/pdf' || file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');

            const duplicateFiles = newFiles.filter(newFile => uploadDocuments.some(uploadedFile => uploadedFile.name === newFile.name));
            if (duplicateFiles.length > 0) {
                Swal.fire({
                    title: 'Duplicate File',
                    text: `The file "${duplicateFiles[0].name}" has already been uploaded.`,
                    icon: 'warning',
                    confirmButtonColor: '#1B4965',
                    customClass: {
                        container: 'swal-container',
                    }
                });
            } else {
                const validFiles = newFiles.filter(file => file.size <= 5 * 1024 * 1024);
                const invalidSizeFiles = newFiles.filter(file => file.size > 5 * 1024 * 1024);

                if (invalidSizeFiles.length > 0) {
                    Swal.fire({
                        title: 'File Too Large',
                        text: 'Files must be less than 5 MB.',
                        icon: 'warning',
                        confirmButtonColor: '#1B4965',
                        customClass: {
                            container: 'swal-container',
                        }
                    });
                } else if (validFiles.length > 0) {
                    setCurrentFile(validFiles[0]);
                    setOpenDialog(true);
                }

                const invalidFiles = acceptedFiles.filter(file =>
                    file.type !== 'application/pdf' &&
                    file.type !== 'application/msword' &&
                    file.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                );
                if (invalidFiles.length > 0) {
                    Swal.fire({
                        title: 'Invalid File',
                        text: 'Only PDF and DOC files are allowed.',
                        icon: 'warning',
                        confirmButtonColor: '#1B4965',
                        customClass: {
                            container: 'swal-container',
                        }
                    });
                }
            }
        },
    });

    // To Remove Uploaded document
    const removeDocument = (index) => {
        setUploadDocuments((prevDetails) => prevDetails.filter((_, i) => i !== index));
    };

    // Handle to Close dialog
    const handleCloseDialog = () => {
        setOpenDialog(false);
        setCurrentFile(null);
        setFileName('');
        setFileTitle('');
    }

    // Handle File Title Change
    const handleFileTitleChange = (event) => {
        setFileTitle(event.target.value);
    }

    // Handle File Name
    const handleFilNameChange = (event) => {
        setFileName(event.target.value);
    }

    // handle to save name for file
    const handleSaveFileName = () => {
        if (fileName.trim() && fileTitle.trim()) {
            let prvState = [...uploadDocuments];
            prvState.push({ file: currentFile, customName: fileName, title: fileTitle });
            setUploadDocuments(prvState);
            handleCloseDialog();
        } else {
            Swal.fire({
                title: 'File Name Required',
                text: 'Please enter a name for this file.',
                icon: 'warining',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            })
        }
    }

    // Step:-4
    // Handle adding a new set of fields
    const handleAddNewId = () => {
        const lastField = companyIds[companyIds.length - 1];
        if (lastField.idName && lastField.idValue) {
            setCompanyId([...companyIds, { idName: '', idValue: '' }]);
        } else {
            Swal.fire({
                title: 'Fill Name and ID',
                text: 'Please fill in both ID Name and ID Value before adding a new row.',
                icon: 'warning',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
        }
    };

    // Handle changes in the ID fields
    const handleFieldChange = (index, event) => {
        const { name, value } = event.target;
        const newIdFields = companyIds.map((field, i) =>
            i === index ? { ...field, [name]: value } : field
        );
        setCompanyId(newIdFields);
    };

    // Handle to delete the ID field
    const handleDelete = (index) => {
        if (index === 0) return;

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#1B4965',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
            customClass: {
                container: 'swal-container',
            }
        }).then((result) => {
            if (result.isConfirmed) {
                const newIdFields = companyIds.filter((_, i) => i !== index);
                setCompanyId(newIdFields);
                Swal.fire({
                    title: 'Deleted!',
                    text: 'The row has been deleted.',
                    icon: 'success',
                    confirmButtonColor: '#1B4965',
                    customClass: {
                        container: 'swal-container',
                    }
                });
            }
        });
    };

    // Handle Submit 
    const handleSubmit = async () => {
        if (companyIds.length === 0 || companyIds.some(id => id.idName === '' || id.idValue === '')) {
            Swal.fire({
                icon: 'warning',
                title: 'Incomplete Details',
                text: 'Please fill company IDs before submitting.',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
            return;
        }
        setLoading(true)
        try {
            if(location.pathname.startsWith('/subscriber/')){
                companyDetails.companyRole = 'client'
                companyDetails.clientOf = user?.companyId?._id
            }
            await createCompany(token, companyDetails, adminDetails, companyIds);
            Swal.fire({
                icon: 'success',
                title: 'Created Successfully',
                text: 'Company Created Successfully.',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
            resetStates();
            onClose();
        } catch (err) {
            console.log(err)
            Swal.fire({
                icon: 'error',
                title: 'Error Creating Company',
                text: 'Error while creating company',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
        } finally {
            setLoading(false);
        }
    }

    return (
        <div>
            <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
                <Box className="clientRegistration" sx={{ width: '40%', height: 'auto' }}>
                    <div style={{ height: '100%', overflow: 'auto' }}>
                        <Box sx={{ position: 'sticky', top: 0, zIndex: 1, background: 'linear-gradient(90deg, #1B4965 63.83%, #051925 82.97%)', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '6px', textAlign: 'center', borderRadius: '4px' }}>
                            <h2 id="modal-modal-title" style={{ margin: 0, color: 'white', marginLeft: '15px' }}>New Client Registration</h2>
                            <IconButton onClick={handleBeforeUnload}>
                                <CloseIcon sx={{ fontSize: '32px', color: 'white' }} />
                            </IconButton>
                        </Box>
                        <Tabs value={value} onChange={handleTabChange} variant="fullWidth" indicatorColor="primary" textColor="primary"
                            sx={{
                                '& .MuiTabs-indicator': {
                                    backgroundColor: '#1B4965',
                                },
                                '& .MuiTab-root.Mui-selected': {
                                    color: '#1B4965',
                                },
                                '& .MuiTab-root': {
                                    color: 'rgba(0, 0, 0, 0.6)',
                                }
                            }}
                        >
                            <Tab label="Client Details" />
                            <Tab label="Admin Details" />
                            <Tab label="Upload Documents" />
                            <Tab label="Client Id's" />
                        </Tabs>
                        <div style={{ padding: '16px' }}>
                            {value === 0 && (
                                <div>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={6}>
                                            <Grid item xs={12}>
                                                <TextField required id="companyName" name="companyName" label="Client Company Name" variant="standard" fullWidth className='TextField' margin="dense"
                                                    value={companyDetails?.companyName}
                                                    onChange={handleInputChange}
                                                    sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '92%' }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField required id="companyCode" name="companyCode" label="Client Company Code" variant="standard" fullWidth className='TextField' margin="dense"
                                                    value={companyDetails?.companyCode}
                                                    onChange={handleInputChange}
                                                    sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '92%' }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                            {(companyDetails?.logo) ? (
                                                <>
                                                    {companyDetails?.logo.name ?
                                                        <div>
                                                            <div style={{ zIndex: 97, display: 'flex', justifyContent: 'flex-end', marginBottom: '-40px' }}>
                                                                <IconButton onClick={() => setCompanyDetails((prevDetails) => ({ ...prevDetails, logo: null }))}>
                                                                    <DeleteForeverIcon sx={{ fontSize: '22px', cursor: 'pointer', }} />
                                                                </IconButton>
                                                            </div>
                                                            <div style={{ height: '120px', textAlign: 'center', borderRadius: '10px' }}>
                                                                <img alt="Logo" src={typeof companyDetails?.logo === 'string' ? companyDetails?.logo : URL.createObjectURL(companyDetails?.logo)} style={{ height: '100%', width: '100%', objectFit: 'contain' }} />
                                                            </div>
                                                        </div> : null
                                                    }
                                                </>
                                            ) : (
                                                <div {...getLogoRootProps()} style={{ height: '120px', textAlign: 'center', border: '1px dashed #1B4965', borderRadius: '10px', cursor: 'pointer', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: '5px' }}>
                                                    <input {...getLogoInputProps()} />
                                                    <img src={Fileuploadimg} alt="" />
                                                    <p style={{ color: 'rgba(0, 0, 0, 0.6)' }}>Click (or) Drag and Drop your Photo Here.. </p>
                                                </div>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={6}>
                                            <TextField required id="companyOwnerName" name="companyOwnerName" label="Client Company Owner Name" variant="standard" fullWidth className='TextField' margin="dense"
                                                value={companyDetails.companyOwnerName}
                                                onChange={handleInputChange}
                                                sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '92%' }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField required id="companyEmail" name="companyEmail" label="Client Company Email" variant="standard" fullWidth className='TextField' margin="dense"
                                                value={companyDetails.companyEmail}
                                                onChange={handleInputChange}
                                                sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '92%' }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <TextField required id="companyWebsite" name="companyWebsite" label="Client Company Website" variant="standard" fullWidth className='TextField' margin="dense"
                                        value={companyDetails.companyWebsite}
                                        onChange={handleInputChange}
                                        sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '96%' }}
                                    />
                                    <TextField id="companyAddress" name="companyAddress" label="Client Company Address" variant="standard" fullWidth className='TextField' margin="dense"
                                        value={companyDetails.companyAddress}
                                        onChange={handleInputChange}
                                        sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '96%' }}
                                    />
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={6}>
                                            <TextField id="city" name="city" label="City" variant="standard" fullWidth className='TextField' margin="dense"
                                                value={companyDetails.city}
                                                onChange={handleCityChange}
                                                sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '92%' }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            {companyDetails.states.length === 1 ? (
                                                <TextField id="state" name="state" label="State" variant="standard" fullWidth className='TextField' margin="dense" disabled
                                                    value={companyDetails.states[0]}
                                                    sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '92%' }}
                                                />
                                            ) : (
                                                <FormControl variant="standard" fullWidth margin="dense" size='small'>
                                                    <InputLabel id="state-label">State</InputLabel>
                                                    <Select labelId="state-label" id="state" name="state" value={companyDetails.selectedState} onChange={handleStateChange} label="State" sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '92%' }}>
                                                        {companyDetails.states.map((state) => (
                                                            <MenuItem key={state} value={state}>
                                                                {state}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={6}>
                                            <TextField id="country" name="country" label="Country" variant="standard" fullWidth className='TextField' margin="dense" InputProps={{ readOnly: true }}
                                                value={companyDetails.selectedCountry}
                                                sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '96%' }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            {companyDetails.timeZones.length > 1 ? (
                                                <FormControl variant="standard" fullWidth margin="dense" size='small'>
                                                    <InputLabel id="timezone-label">Timezone</InputLabel>
                                                    <Select labelId="timezone-label" id="timezone" name="timezone" value={companyDetails.selectedTimeZone} onChange={handleTimeZoneChange} label="Timezone" sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '92%' }}>
                                                        {companyDetails.timeZones.map((timezone) => (
                                                            <MenuItem key={timezone.zoneName} value={timezone.tzName}>
                                                                {timezone.tzName}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            ) : (
                                                <TextField id="timezone" name="timezone" label="Timezone" variant="standard" fullWidth className='TextField' margin="dense" InputProps={{ readOnly: true }}
                                                    value={companyDetails.selectedTimeZone}
                                                    sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '92%' }}
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                </div>
                            )}
                            {value === 1 && (
                                <div>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={6}>
                                            <Grid item xs={12}>
                                                <TextField required id="first-name" label="First Name" variant="standard" fullWidth margin="dense"
                                                    value={adminDetails.firstName}
                                                    onChange={(e) => setAdminDetails({ ...adminDetails, firstName: e.target.value })}
                                                    sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '92%' }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField required id="last-name" label="Last Name" variant="standard" fullWidth margin="dense"
                                                    value={adminDetails.lastName}
                                                    onChange={(e) => setAdminDetails({ ...adminDetails, lastName: e.target.value })}
                                                    sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '92%' }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControl variant='standard' required fullWidth margin='normal' size='small'>
                                                    <InputLabel id="user-role">Role</InputLabel>
                                                    <Select labelId='user-role' id='role' label="Role" value={adminDetails.role} onChange={(e) => setAdminDetails({ ...adminDetails, role: e.target.value })} margin="dense" sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '92%' }}>
                                                        <MenuItem value="Company-super-admin">Super Admin</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                            {(adminDetails?.profilePic) ? (
                                                <>
                                                    {adminDetails?.profilePic.name ?
                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <div style={{ height: '180px', width: '180px', textAlign: 'center', borderRadius: '50%', overflow: 'hidden' }}>
                                                                <img alt="Logo" src={typeof adminDetails?.profilePic === 'string' ? adminDetails?.profilePic : URL.createObjectURL(adminDetails?.profilePic)} style={{ height: '100%', width: '100%', objectFit: 'fill' }} />
                                                            </div>
                                                            <div style={{ zIndex: 97, display: 'flex', justifyContent: 'flex-end', marginTop: '-150px' }}>
                                                                <IconButton onClick={() => setAdminDetails((prevDetails) => ({ ...prevDetails, profilePic: null }))}>
                                                                    <DeleteForeverIcon sx={{ fontSize: '22px', cursor: 'pointer', }} />
                                                                </IconButton>
                                                            </div>
                                                        </div> : null
                                                    }
                                                </>
                                            ) : (
                                                <div {...getProfilepicRootProps()} style={{ height: '180px', textAlign: 'center', border: '1px dashed #1B4965', borderRadius: '10px', cursor: 'pointer', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                    <input {...getProfilepicInputProps()} />
                                                    <img src={Fileuploadimg} alt="" />
                                                    <p style={{ color: 'rgba(0, 0, 0, 0.6)' }}>Click (or) Drag and Drop your Photo Here.. </p>
                                                </div>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={6}>
                                            <TextField required id="workEmail" label="Work Email" variant="standard" fullWidth margin="dense"
                                                value={adminDetails.workEmail}
                                                onChange={(e) => setAdminDetails({ ...adminDetails, workEmail: e.target.value })}
                                                sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '92%' }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField required id="personalEmail" label="Personal Email" variant="standard" fullWidth margin="dense"
                                                value={adminDetails.personalEmail}
                                                onChange={(e) => setAdminDetails({ ...adminDetails, personalEmail: e.target.value })}
                                                sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '92%' }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={6}>
                                            <PhoneInput
                                                country={'in'}
                                                value={adminDetails.mobileNumber}
                                                onChange={(value) => setAdminDetails({ ...adminDetails, mobileNumber: value })}
                                                containerStyle={{ width: '92%', marginTop: '15px', borderRadius: 'none' }}
                                                inputStyle={{ width: '100%', fontSize: '14px', border: 'none', borderBottom: '1px solid #00000099', borderBottomLeftRadius: '0px' }}
                                                buttonStyle={{ border: 'none', backgroundColor: 'white', borderBottom: '1px solid #00000099', borderRadius: 'none', borderBottomRightRadius: '0px' }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={6}>
                                            <TextField required label="Password" variant="standard" fullWidth margin="dense"
                                                value={adminDetails.password}
                                                onChange={(e) => setAdminDetails({ ...adminDetails, password: e.target.value })}
                                                sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '92%' }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sx={{ marginTop: '30px' }}>
                                            <Button variant="outlined" sx={{ color: '#1B4965', border: '1px solid #1B4965' }} onClick={generatePassword}>Generate</Button>
                                            {adminDetails.password && <Button variant="outlined" sx={{ color: '#d32f2f', border: '1px solid #d32f2f', marginLeft: '10px' }} onClick={resetPassword}>Reset</Button>}
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{ marginTop: '10px' }}>
                                        <Grid item xs={4}>
                                            <Button component="label" role={undefined} variant="contained" tabIndex={-1} startIcon={<CloudUploadIcon />} sx={{ background: 'linear-gradient(90deg, #1B4965 63.83%, #051925 82.97%)', backgroundSize: '200%' }}>
                                                Upload ID card
                                                <input type="file" accept="application/pdf" style={{ display: 'none' }} onChange={handleFileChange} />
                                            </Button>
                                        </Grid>
                                        <Grid item xs={8}>
                                            {adminDetails.uploadedFile && <span>{adminDetails.uploadedFile.name}</span>}
                                        </Grid>
                                    </Grid>
                                </div>
                            )}
                            {value === 2 && (
                                <div>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={12}>
                                            <div {...getDocumentRootProps()} className='company-documents-upload'>
                                                <input {...getDocumentInputProps()} />
                                                <img src={Fileuploadimg} alt="" />
                                                <p style={{ color: 'rgba(0, 0, 0, 0.6)' }}>Drag and Drop files here or select files</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} style={{ marginTop: '-30px' }}>
                                            <p style={{ color: 'rgba(0, 0, 0, 0.6)', textAlign: 'left' }}>Supported files: PDF, DOC</p>
                                        </Grid>
                                        <Grid item xs={6} style={{ marginTop: '-30px' }}>
                                            <p style={{ color: 'rgba(0, 0, 0, 0.6)', textAlign: 'right' }}>Maximum file size: 5MB</p>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {uploadDocuments.length > 0 && (
                                            <div style={styles.uploadedFilesContainer}>
                                                {uploadDocuments.map((fileItem, index) => (
                                                    <div key={index} style={styles.uploadedFileContainer}>
                                                        <div style={styles.fileIcon}>
                                                            <InsertDriveFileIcon style={{ fontSize: '30px' }} />
                                                        </div>
                                                        <div style={styles.fileInfo}>
                                                            <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                                                                <p style={styles.fileTitle}>{fileItem.title}</p>
                                                                <p>-</p>
                                                                <p style={styles.fileName}>{fileItem.customName}</p>
                                                                <p style={styles.fileSize}>{(fileItem?.file?.size / 1024 / 1024).toFixed(2)} MB - Uploaded</p>
                                                            </div>

                                                        </div>
                                                        <IconButton onClick={() => removeDocument(index)} style={styles.deleteButton}>
                                                            <DeleteForeverIcon style={{ fontSize: '22px', cursor: 'pointer' }} />
                                                        </IconButton>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </Grid>
                                    <Dialog open={openDialog} onClose={handleCloseDialog}>
                                        <DialogTitle>Enter File Name</DialogTitle>
                                        <DialogContent>
                                            <TextField autoFocus margin='dense' label='Title' type='text' fullWidth value={fileTitle} onChange={handleFileTitleChange} />
                                            <TextField autoFocus margin='dense' label='File Name' type='text' fullWidth value={fileName} onChange={handleFilNameChange} />
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleCloseDialog} color="primary">
                                                Cancel
                                            </Button>
                                            <Button onClick={handleSaveFileName} color="primary">
                                                Save
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>
                            )}
                            {value === 3 && (
                                <div>
                                    <Grid>
                                        <Button variant='outlined' onClick={handleAddNewId} sx={{ color: '#1B4965', border: '1px solid #1B4965' }}>Add New ID</Button>
                                    </Grid>
                                    <div style={{ maxHeight: '360px', overflowY: 'auto', marginTop: '10px', border: '1px solid #ddd', borderRadius: '4px', backgroundColor: '#f9f9f9', padding: '5px 0px', overflowX: 'hidden' }}>
                                        {companyIds.map((field, index) => (
                                            <Grid container spacing={2} alignItems="center" key={index} style={{ marginLeft: '0px' }}>
                                                <Grid item xs={5}>
                                                    <TextField required
                                                        id={`companyIdName-${index}`} name="idName" label="Enter ID Name" variant="outlined" fullWidth size='small' className='TextField' margin="dense"
                                                        value={field.idName}
                                                        onChange={(event) => handleFieldChange(index, event)}
                                                        sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px', backgroundColor: '#fff' }, width: '92%' }}
                                                    />
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <TextField required id={`companyIdvalue-${index}`} name="idValue" label="Enter ID Value" variant="standard" fullWidth size='small' className='TextField' margin="dense"
                                                        value={field.idValue}
                                                        onChange={(event) => handleFieldChange(index, event)}
                                                        sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '92%' }}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <IconButton color="error" sx={{ '& .MuiSvgIcon-root': { fontSize: '24px' } }} onClick={() => handleDelete(index)} disabled={index === 0}>
                                                        <DeleteForeverIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div style={{ marginTop: '25px', display: 'flex', justifyContent: 'space-evenly' }}>
                            {value !== 0 ? (
                                <Button onClick={handleBack} variant="outlined" sx={{ color: '#1B4965', border: '1px solid #1B4965' }}>Back</Button>
                            ) : (
                                <Button onClick={handleBeforeUnload} variant="outlined" sx={{ color: '#1B4965', border: '1px solid #1B4965' }}>Cancel</Button>
                            )}
                            {value !== 3 ? (
                                <Button onClick={handleNext} variant="contained" sx={{ background: 'linear-gradient(90deg, #1B4965 63.83%, #051925 82.97%)', backgroundSize: '200%' }}>Next</Button>
                            ) : (
                                <Button onClick={handleSubmit} variant="contained" sx={{ background: 'linear-gradient(90deg, #1B4965 63.83%, #051925 82.97%)', backgroundSize: '200%' }}>Submit</Button>
                            )}
                        </div>
                    </div>
                </Box>
            </Modal>
            {(loading) && (
                <Loader />
            )}
        </div>
    )
}

const styles = {
    uploadedFilesContainer: {
        maxHeight: '200px',
        overflowY: 'auto',
        border: '1px solid #ddd',
        borderRadius: '4px',
        padding: '10px',
        marginTop: '10px',
        backgroundColor: '#f9f9f9',
    },
    uploadedFileContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '1px solid #D9D9D9',
        borderRadius: '5px',
        padding: '5px 5px',
        backgroundColor: '#EDF6FF'
    },
    fileIcon: {
        marginRight: '10px',
    },
    fileInfo: {
        flex: 1,
    },
    fileTitle: {
        fontWeight: 'bold',
    },
    fileName: {
        margin: 0,
        fontSize: '14px',
        fontStyle: 'italic',
    },
    fileSize: {
        margin: 0,
        fontSize: '12px',
        color: '#888',
        marginLeft: '10px'
    },
    deleteButton: {
        marginLeft: '10px',
    },
}

export default ClientRegistration