import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';

import Swal from 'sweetalert2';
import { Box, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Modal, Select, TextField } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import './LocationRegistration.css'
import timeZonesData from '../../../assets/Country_Timezone/countries+states+cities.json';
import { selectToken } from "../../../Redux/selector";
import { getAllUsersDetails } from "../../../utils/Services/userServices";
import { createLocation } from '../../../utils/Services/subscriberConfigurationServices/locationServices';

function LocationRegistration({ open, onClose }) {
    const [branchName, setBranchName] = useState('')
    const [branchManager, setBranchManager] = useState({ id: '', name: '' });
    const [contactNumber, setContactNumber] = useState('');
    const [address, setAddress] = useState({ street: '', city: '', states: [], country: '', postalCode: '', selectedState: '', selectedCountry: '' })
    const [branchID, setBranchID] = useState([{ branchIDName: '', branchIDValue: '' }]);
    const [userDetails, setUserDetails] = useState([]);
    const token = useSelector(selectToken);
    const { subscriberId } = useParams();

    // To fetch Users Details
    const fetchUsersDetail = async () => {
        try {
            const response = await getAllUsersDetails(token, subscriberId);
            setUserDetails(response?.data?.data || []);
        } catch (error) {
            console.error('Error:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'There was an error. Please try again later.',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
        }
    }
    useEffect(() => {
        fetchUsersDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleBranchNameChange = (event) => {
        setBranchName(event.target.value);
    };

    const handleBranchManagerChange = (event) => {
        const selectedUser = userDetails.find(user => user.fullName === event.target.value);
        if (selectedUser) {
            setBranchManager({ id: selectedUser._id, name: selectedUser.fullName });
        }
    };

    const handleContactNumberChange = (value) => {
        setContactNumber(value);
    };

    // Handle City Change
    const handleCityChange = (event) => {
        const cityName = event.target.value;
        let foundStates = [];
        let foundCountry = '';

        timeZonesData.forEach(country => {
            let cityFound = false;
            country.states.forEach(state => {
                state.cities.forEach(city => {
                    if (city.name.toLowerCase() === cityName.toLowerCase()) {
                        foundStates.push(state.name);
                        if (!cityFound) {
                            foundCountry = country.name;
                            cityFound = true;
                        }
                    }
                });
            });
        });

        setAddress((prevDetails) => ({
            ...prevDetails,
            city: cityName,
            states: foundStates,
            selectedState: '',
            selectedCountry: foundStates.length === 1 ? foundCountry : '',
        }));
    };

    // Handle State Change
    const handleStateChange = (event) => {
        const stateName = event.target.value;
        let foundCountry = '';

        timeZonesData.forEach(country => {
            country.states.forEach(state => {
                if (state.name === stateName) {
                    foundCountry = country.name;
                }
            });
        });

        setAddress((prevDetails) => ({
            ...prevDetails,
            selectedState: stateName,
            selectedCountry: foundCountry,
        }));
    };

    const handleBranchIDChange = (index, field, value) => {
        const updatedBranchID = [...branchID];
        updatedBranchID[index][field] = value;
        setBranchID(updatedBranchID);
    };

    const handleAddBranchID = () => {
        setBranchID([...branchID, { branchIDName: '', branchIDValue: '' }]);
    };

    const handleRemoveBranchID = (index) => {
        const updatedBranchID = [...branchID];
        updatedBranchID.splice(index, 1);
        setBranchID(updatedBranchID);
    };

    // Reset the states
    const resetStates = () => {
        setBranchName('')
        setBranchManager({ id: '', name: '' })
        setContactNumber('')
        setAddress({ street: '', city: '', states: [], country: '', postalCode: '', selectedState: '', selectedCountry: '' })
        setBranchID([{ branchIDName: '', branchIDValue: '' }])
    };

    const handleBeforeUnload = () => {
        resetStates();
        onClose();
    };

    const handleFormSubmit = async () => {
        const data = {
            branchName,
            branchManagerId: branchManager.id,
            branchManagerName: branchManager.name,
            contactNumber,
            address: {
                street: address.street,
                city: address.city,
                states: address.selectedState || address.states,
                country: address.selectedCountry,
                postalCode: address.postalCode,
            },
            branchID
        };
        try {
            await createLocation(token, subscriberId, data);
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Branch / Location registered successfully!',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });

            resetStates();
            onClose();
        } catch (error) {
            console.error('Error:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Failed to register. Please try again later.',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
        }
    };

    return (
        <div>
            <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
                <Box className="locationRegistration"
                    sx={{
                        width: '40%',
                        maxHeight: '90vh',
                        overflow: 'auto',
                        height: 'auto',
                        '&::-webkit-scrollbar': {
                            width: '8px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#1B4965',
                            borderRadius: '20px',
                        },
                        '&::-webkit-scrollbar-track': {
                            background: '#f0f0f0',
                        }
                    }}>
                    <div style={{ height: '100%', overflow: 'auto' }}>
                        <Box sx={{ position: 'sticky', top: 0, zIndex: 1, background: 'linear-gradient(90deg, #1B4965 63.83%, #051925 82.97%)', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '6px', textAlign: 'center', borderRadius: '4px' }}>
                            <h2 id="modal-modal-title" style={{ margin: 0, color: 'white', marginLeft: '15px' }}>New Branch / Location Registration</h2>
                            <IconButton onClick={handleBeforeUnload}>
                                <CloseIcon sx={{ fontSize: '32px', color: 'white' }} />
                            </IconButton>
                        </Box>
                        <div style={{ padding: '16px' }}>
                            <div>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={6}>
                                        <TextField required label="Branch Name" fullWidth margin="normal"
                                            value={branchName}
                                            onChange={handleBranchNameChange}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl required variant='outlined' fullWidth margin='normal'>
                                            <InputLabel id="branchManager">Branch Manager</InputLabel>
                                            <Select labelId='branchManager' id='branchManager' value={branchManager.name} onChange={handleBranchManagerChange} label="Branch Manager" margin="dense" sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '100%' }}>
                                                {userDetails.map((user) => (
                                                    <MenuItem key={user._id} value={user.fullName}>
                                                        {user.fullName}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={6}>
                                        <PhoneInput
                                            value={contactNumber}
                                            onChange={handleContactNumberChange}
                                            country={'in'}
                                            containerStyle={{ width: '100%' }}
                                            inputStyle={{ width: '100%', fontSize: '14px', height: '50px' }}
                                            buttonStyle={{ backgroundColor: 'white' }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField id="street" name="street" label="street" variant="outlined" fullWidth className='TextField' margin="dense"
                                            value={address.street}
                                            onChange={(e) => setAddress({ ...address, street: e.target.value })}
                                            sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '100%' }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={6}>
                                        <TextField id="city" name="city" label="City" variant="outlined" fullWidth className='TextField' margin="dense"
                                            value={address.city}
                                            onChange={handleCityChange}
                                            sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '100%' }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        {address?.states?.length === 1 ? (
                                            <TextField id="state" name="state" label="State" variant="outlined" fullWidth className='TextField' margin="dense"
                                                value={address.states[0]} disabled
                                                sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '100%' }} />
                                        ) : (
                                            <FormControl variant="outlined" fullWidth margin="dense">
                                                <InputLabel id="state-label">State</InputLabel>
                                                <Select labelId="state-label" id="state" name="state" value={address.selectedState} onChange={handleStateChange} label="State" sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '100%' }}>
                                                    {address.states.map((state) => (
                                                        <MenuItem key={state} value={state}>
                                                            {state}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={6}>
                                        <TextField id="country" name="country" label="Country" variant="outlined" fullWidth className='TextField' margin="dense"
                                            value={address.selectedCountry} InputProps={{ readOnly: true }} sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '100%' }} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField id="postalCode" name="postalCode" label="Postal Code" variant="outlined" fullWidth className='TextField' margin="dense"
                                            value={address.postalCode}
                                            onChange={(e) => setAddress({ ...address, postalCode: e.target.value })}
                                            sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '100%' }}
                                        />
                                    </Grid>
                                </Grid>
                                {branchID.map((item, index) => (
                                    <Grid container spacing={2} alignItems="center" key={index} sx={{ marginTop: '0px' }}>
                                        <Grid item xs={5}>
                                            <TextField
                                                label="Branch ID Name"
                                                value={item.branchIDName}
                                                onChange={(e) => handleBranchIDChange(index, 'branchIDName', e.target.value)}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField
                                                label="Branch ID Value"
                                                value={item.branchIDValue}
                                                onChange={(e) => handleBranchIDChange(index, 'branchIDValue', e.target.value)}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <IconButton onClick={() => handleRemoveBranchID(index)} disabled={branchID.length === 1}>
                                                <RemoveIcon />
                                            </IconButton>
                                            {index === branchID.length - 1 && (
                                                <IconButton onClick={handleAddBranchID}>
                                                    <AddIcon />
                                                </IconButton>
                                            )}
                                        </Grid>
                                    </Grid>
                                ))}
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                                    <Button variant="contained" color="primary" onClick={handleFormSubmit} sx={{ background: 'linear-gradient(90deg, #1B4965 63.83%, #051925 82.97%)', backgroundSize: '200%' }}>Register</Button>
                                </Box>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default LocationRegistration
