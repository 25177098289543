import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

import Swal from 'sweetalert2';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";

import './Navbar.css'
import Applogo from '../../../assets/NavbarImages/app-logo.png'
import Downarrow from '../../../assets/NavbarImages/downward-arrow.svg'
// import Apps from '../../../assets/NavbarImages/apps.svg'
// import Settings from '../../../assets/NavbarImages/settings.svg'
// import Notifications from '../../../assets/NavbarImages/notification.svg'
// import Question from '../../../assets/NavbarImages/question.svg'
import User from '../../../assets/NavbarImages/user.svg'
import Logout from '../../../assets/NavbarImages/logout.svg'

import { Roles } from '../../../utils/constant';
import { setUser } from '../../../Redux/reducers/authReducer';
import { selectUser } from '../../../Redux/selector';
import InMeetingModal from './NavbarComponents/InMetingModal/InMeetingModal';
import AddStatusModal from './NavbarComponents/AddStatusModal/AddStatusModal';
import { currentStatus, getCurrentStatus } from '../../../utils/Services/statusServices';
import ProfileDetailsModal from './NavbarComponents/ProfileDetailsModal/ProfileDetailsModal';

function Navbar() {
  const [profileActive, setProfileActive] = useState(false);
  const [showInMeetingModal, setShowInMeetingModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [userCurrentStatus, setUserCurrentStatus] = useState([]);
  const [InMeetingTime, setInMeeting] = useState()
  const [showProfileDetails, setShowProfileDetails] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const profileDetailsRef = useRef(null);
  const profileActiveRef = useRef(null);
  const navActiveRef = useRef(null);
  const profileInMeetingRef = useRef(null);
  const inMeetingModalRef = useRef(null);
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const userId = user ? user._id : null;
  const firstName = user ? user.firstName : null;

  // To Get the Current Status
  useEffect(() => {
    const fetchUserCurrentStatus = async () => {
      try {
        const response = await getCurrentStatus(userId);
        setUserCurrentStatus(response?.data)
      } catch (err) {
        console.error('Error fetching company details:', err);
      }
    };

    fetchUserCurrentStatus();
  }, [userId, profileActive, showInMeetingModal, showStatusModal]);

  // When user click outside the modal the modal will close
  useEffect(() => {
    function handleClickOutside(event) {
      if (profileDetailsRef.current && !profileDetailsRef.current.contains(event.target)) {
        setShowProfileDetails(false);
      }
      if (profileActiveRef.current && !profileActiveRef.current.contains(event.target)) {
        setProfileActive(false);
      }
      if (profileInMeetingRef.current && !profileInMeetingRef.current.contains(event.target)) {
        setShowInMeetingModal(false);
      }
      if (inMeetingModalRef.current && !inMeetingModalRef.current.contains(event.target)) {
        setShowInMeetingModal(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [profileDetailsRef, profileActiveRef, profileInMeetingRef, inMeetingModalRef]);

  // Adjust modal position based on nav-active component
  useEffect(() => {
    if (navActiveRef.current && profileActive) {
      const navActiveRect = navActiveRef.current.getBoundingClientRect();
      setModalPosition({
        top: navActiveRect.bottom + window.scrollY,
        left: navActiveRect.left + window.scrollX,
      });
    }
  }, [profileActive]);

  useEffect(() => {
    if (navActiveRef.current && showInMeetingModal) {
      const navActiveRect = navActiveRef.current.getBoundingClientRect();
      setModalPosition({
        top: navActiveRect.bottom + window.scrollY,
        left: navActiveRect.left + window.scrollX,
      });
    }
  }, [showInMeetingModal]);

  // To display profile active modal
  const toggleProfileActive = () => {
    setProfileActive(!profileActive);
  };

  // Handle to Add New Status
  const handleAddStatusClick = () => {
    setShowStatusModal(true);
    setProfileActive(false)
  };

  // Handle to Close New Status
  const closeStatusModal = () => {
    setShowStatusModal(false);
  };

  // Status Data
  const statusData = {
    Active: { icon: "", label: "Active", time: "" },
    Away: { icon: "", label: "Away", time: "" },
    Offline: { icon: "", label: "Offline", time: "" },
  };

  // To Change Active Status
  const handleStatusChange = async (status) => {
    setProfileActive(false);
    const statusDataToSend = statusData[status];
    setUserCurrentStatus([statusDataToSend]);

    try {
      await currentStatus(statusDataToSend, userId);
      const response = await getCurrentStatus(userId);
      setUserCurrentStatus(response?.data);
    } catch (error) {
      console.error("Failed to update status:", error);
    }

    if (status === "InMeeting") {
      setShowInMeetingModal(true);
    } else {
      setShowInMeetingModal(false);
    }
  };

  const getStatusClass = () => {
    const currentStatuses = userCurrentStatus[0]?.label.trim()
    if (currentStatuses === 'Active') {
      return "active";
    } else if (currentStatuses === 'Away') {
      return "away";
    } else if (currentStatuses === 'Offline') {
      return "offline"
    } else if (currentStatuses === 'InMeeting') {
      return "meeting";
    } else {
      return "newstatus"
    }
  };

  // To Display user Profile details
  const toggleProfileDetails = () => {
    setShowProfileDetails(!showProfileDetails);
  };

  // InMeeting Time set
  const handleInMeetingTimeSelect = async (minutes, displayText) => {
    const untilTime = new Date(Date.now() + minutes * 60000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
    const inMeetingData = { icon: "", label: "InMeeting", time: displayText }

    try {
      await currentStatus(inMeetingData, userId);
    } catch (error) {
      console.error("Failed to update status:", error);
    }

    setInMeeting(untilTime);
    setShowInMeetingModal(false);
  };

  // Handle to Navigate to Homepage
  const handleHome = () => {
    if (user?.role === Roles.SYSTEM_SUPER_ADMIN) {
      navigate("/systemadmin");
    } else if (((user?.role === Roles.COMPANY_SUPER_ADMIN) || (user?.role === Roles.USERS))) {
      if (location.pathname.startsWith('/subscriber/')) {
        navigate(`/subscriber/${user?.companyId?._id}`);
      } else if (location.pathname.startsWith('/client/')) {
        navigate(`/client/${user?._id}`)
      } else if (location.pathname.startsWith('/clientadmin/')) {
        navigate(`/clientadmin/${user?.companyId?._id}`)
      } else {
        navigate(`/user/${user?._id}`);
      }
    } else {
      Swal.fire({
        icon: 'info',
        title: 'Role Dashboard Not Created',
        text: 'The dashboard for this role is not yet created.',
        confirmButtonColor: "#1B4965",
      });
    }
  }

  // Handle logout
  const handleLogout = async () => {
    const offlineData = { icon: "", label: "Offline", time: "" }

    try {
      await currentStatus(offlineData, userId);
    } catch (error) {
      console.error("Failed to update status:", error);
    }
    Swal.fire({
      icon: 'success',
      title: 'Logout Successfully',
      text: `Comeback Again ${firstName}`,
      showConfirmButton: false,
      timer: 1500,
    });
    dispatch(setUser({ user: null, token: null }));
    navigate("/")
  }

  // To Display Company Image
  const companyImageUrl = user?.companyId?.logoLocation?.location ? user.companyId.logoLocation.location : Applogo;

  // To Display User Image
  const userImageUrl = user?.profilePic?.location ? user.profilePic.location : User;

  return (
    <div className='navbar'>
      <div className='nav-comp1'>
        <div className='nav-logo' onClick={handleHome}>
          <img src={companyImageUrl} alt="" className='app-logo' />
        </div>
      </div>
      <div className='nav-comp2'>
        <div className='nav-active' ref={navActiveRef} onClick={toggleProfileActive}>
          <div className={`active-color ${getStatusClass()}`}></div>
          <p className='nav-text'>{userCurrentStatus[0]?.label}</p>
          <div>
            <img src={Downarrow} alt="" className='downarrow' />
          </div>
        </div>
        {/* <div className='menu'>
          <img src={Apps} alt="" className='menu-icon' />
        </div> */}
        {/* <div className='vertical-line'></div>
        <div className='settings'>
          <img src={Settings} alt="" className='settings-icon' />
        </div> */}
        {/* <div className='vertical-line'></div>
        <div className='notifications'>
          <img src={Notifications} alt="" className='notification-icon' />
        </div> */}
        {/* <div className='vertical-line'></div>
        <div className='questions'>
          <img src={Question} alt="" className='questions-icon' />
        </div> */}
        <div className='vertical-line'></div>
        <div className='profile' onClick={toggleProfileDetails}>
          <img src={userImageUrl} alt="" className='user-img' />
          <p className='user-name'>{firstName}</p>
          <img src={Downarrow} alt="" className='profiledownarrow' style={{ marginTop: '-1px' }} />
        </div>
        <div className='vertical-line'></div>
        <div className='logout' onClick={handleLogout}>
          <img src={Logout} alt="" className='logout-icon' />
        </div>
      </div>
      {profileActive && (
        <div className="profile-active" ref={profileActiveRef} style={{ top: `${modalPosition.top}px`, left: `${modalPosition.left}px`, position: 'absolute' }}>
          <div className='profile-active-container'>
            <div className='profile-active-automatic' onClick={() => handleStatusChange("Active")}>
              <div className='profile-active-color'></div>
              <div className='profile-active-content'>
                <p className="title">Automatic</p>
                <p className="subtitle">Based on chat activity</p>
              </div>
            </div>
            <div className='profile-active-meeting' onClick={() => handleStatusChange("InMeeting")}>
              <div className='profile-active-color-meeting'></div>
              <div className='profile-active-content'>
                <p className="title">In-Meeting</p>
                {InMeetingTime ? (
                  <p className="subtitle">Mute chat notifications until {InMeetingTime}</p>
                ) : (
                  <p className="subtitle">Mute chat notifications</p>
                )}
              </div>
            </div>
            <div className='profile-active-away' onClick={() => handleStatusChange("Away")}>
              <div className='profile-active-color-away'></div>
              <div className='profile-active-content'>
                <p className="title">Set as away</p>
              </div>
            </div>
            <div className='profile-active-add-status' style={{ marginBottom: '-4px' }} onClick={handleAddStatusClick}>
              <div className='profile-active-addstatus'><FontAwesomeIcon icon={faPencilAlt} className="pencil-icon" /></div>
              <div className='profile-active-content'>
                <p className="title">Add Status</p>
              </div>
            </div>
            <div className='profile-active-add-status' onClick={() => handleStatusChange("Offline")}>
              <div className='profile-active-color-status'></div>
              <div className='profile-active-content'>
                <p className="title">Offline</p>
              </div>
            </div>
          </div>
        </div>
      )}
      <InMeetingModal showInMeetingModal={showInMeetingModal} handleInMeetingTimeSelect={handleInMeetingTimeSelect} onClose={() => setShowInMeetingModal(false)} ref={inMeetingModalRef} position={modalPosition}/>
      <ProfileDetailsModal open={showProfileDetails} onClose={toggleProfileDetails} user={user} />
      {showStatusModal && (
        <AddStatusModal open={showStatusModal} onClose={closeStatusModal} />
      )}
    </div>
  )
}

export default Navbar