import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from 'styled-components';

import Swal from 'sweetalert2';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

import Loader from "../../../../../UiUxComponents/Loader/Loader";
import { selectToken } from "../../../../../../Redux/selector";
import { getDepartmentDataWithMembers, updateDepartment } from "../../../../../../utils/Services/subscriberConfigurationServices/departmentsServices";

function DepartmentData() {
  const [departmentDetails, setDepartmentDetails] = useState();
  const [departmentUsers, setDepartmentUsers] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const token = useSelector(selectToken);
  const { departmentId } = useParams();

  // To get department details
  const fetchDepartmentDetails = async () => {
    setLoading(true);
    try {
      const response = await getDepartmentDataWithMembers(token, departmentId);
      setDepartmentDetails(response?.data?.department)
      setDepartmentUsers(response?.data?.members)
    } catch (err) {
      console.error('Error fetching department details:', err);
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: 'Failed to fetch department details',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchDepartmentDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle to edit the fields
  const handleEditClick = async () => {
    if (isEditing) {
      await fetchDepartmentDetails();
    }
    setIsEditing(prevEditing => {
      const newEditingState = !prevEditing;
      if (newEditingState) {
        Swal.fire({
          icon: 'info',
          title: 'Editing Enabled',
          text: 'Now you can edit the fields.',
          confirmButtonColor: '#1B4965',
          customClass: {
            container: 'swal-container',
          }
        });
      }
      return newEditingState;
    });
  };

  // handle input change
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDepartmentDetails(prevDetails => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  // Dropdown for Department Head
  const selectedDepartmentHeadId = departmentUsers?.find(department => department.fullName === departmentDetails?.departmentHead?.name)?._id || '';
  const handleSelectChange = (e) => {
    const selectedId = e.target.value;
    const selectedDepartmentHead = departmentUsers?.find(department => department._id === selectedId);
    setDepartmentDetails({
      ...departmentDetails,
      departmentHead: {
        _id: selectedId,
        name: selectedDepartmentHead?.fullName || ''
      }
    });
  };

  // Hanlde Update
  const handleUpdate = async () => {
    setLoading(true);
    try {
      await updateDepartment(token, departmentId, departmentDetails);
      Swal.fire({
        icon: 'success',
        title: 'Department Details Updated',
        text: 'The department have been successfully updated.',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Update Failed',
        text: 'There was an error updating the department details. Please try again.',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });
      console.error('Error updating department details:', err);
    } finally {
      setLoading(false);
      setIsEditing(false);
      fetchDepartmentDetails();
    }
  };

  return (
    <span>
      <Container style={{ padding: '20px', height: '70vh', overflow: 'auto' }}>
        <Grid container spacing={2} justifyContent="flex-end" sx={{ marginBottom: '20px' }}>
          <Grid item>
            <Button variant="contained"
              sx={{
                backgroundColor: '#5A9BD5',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#5A9BD5',
                  opacity: 1
                }
              }}
              endIcon={<EditIcon />}
              onClick={handleEditClick}>{isEditing ? 'Cancel' : 'Edit'}
            </Button>
          </Grid>
          <Grid item>
            {isEditing && (
              <Button variant="outlined"
                sx={{
                  backgroundColor: '#1B4965',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: '#1B4965',
                    opacity: 1
                  }
                }}
                endIcon={<RestartAltIcon />}
                onClick={() => {
                  if (isEditing) {
                    handleUpdate();
                  }
                }}
              > Update
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <TextField id="departmentName" name="departmentName" label="Department Name" variant="outlined" fullWidth margin="dense"
              InputProps={{ readOnly: !isEditing }}
              onChange={(e) => setDepartmentDetails({ ...departmentDetails, departmentName: e.target.value })}
              value={departmentDetails?.departmentName || ''}
              sx={{
                '& .MuiInputLabel-root': { fontSize: '14px' },
                '& .MuiInputBase-root': { fontSize: '14px' },
                width: '100%'
              }}
            />
          </Grid>
          <Grid item xs={6}>
            {isEditing ? (
              <FormControl variant='outlined' required fullWidth margin='dense'>
                <InputLabel id="department">Department Head</InputLabel>
                <Select labelId='department' id='department' label="Department Head" margin="dense"
                  value={selectedDepartmentHeadId}
                  onChange={handleSelectChange}
                  sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '100%' }}
                >
                  {departmentUsers?.map((users) => (
                    <MenuItem key={users._id} value={users._id}>
                      {users.fullName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <TextField id="department" name="department" label="Department Head" variant="outlined" fullWidth margin="dense" InputProps={{ readOnly: !isEditing }}
                onChange={handleInputChange}
                value={departmentDetails?.departmentHead?.name || ''}
                sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '100%' }}
              />
            )}
          </Grid>
        </Grid>
        <TextField id="description" name="description" label="Description" variant="outlined" fullWidth margin="dense"
          InputProps={{ readOnly: !isEditing }}
          onChange={(e) => setDepartmentDetails({ ...departmentDetails, description: e.target.value })}
          value={departmentDetails?.description || ''}
          sx={{
            '& .MuiInputLabel-root': { fontSize: '14px' },
            '& .MuiInputBase-root': { fontSize: '14px' },
            width: '100%'
          }}
        />
      </Container>
      {
        (loading) && (
          <Loader />
        )
      }
    </span >
  )
}

export default DepartmentData

const Container = styled.div`
    padding: 20px;
    height: 70vh;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: #1B4965 #f0f0f0;

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #1B4965;
        border-radius: 20px;
    }

    &::-webkit-scrollbar-track {
        background: #f0f0f0;
    }
`;