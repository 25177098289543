import './FixedSidebar.css'
import Message from '../../../../../assets/FixedSidebarImages/messenger.svg'
import GroupChat from '../../../../../assets/FixedSidebarImages/group-chat.svg'
import Notes from '../../../../../assets/FixedSidebarImages/notes.svg'
import Email from '../../../../../assets/FixedSidebarImages/email.svg'

function Fixedsidebar() {
  return (
      <div className='fixedsidebar'>
        <div className='fixedsidebar-icons'>
          <img src={Message} alt="" className='message-icon'/>
        </div>
        <div className='fixedsidebar-icons'>
          <img src={GroupChat} alt="" className='groupchat-icon'/>
        </div>
        <div className='fixedsidebar-icons'>
          <img src={Notes} alt="" className='notes-icon'/>
        </div>
        <div className='fixedsidebar-icons'>
          <img src={Email} alt="" className='email-icon'/>
        </div>
      </div>
  )
}

export default Fixedsidebar
