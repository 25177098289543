import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Swal from 'sweetalert2';
import styled from 'styled-components';
import { Box, TextField, Button, Popover, Autocomplete } from '@mui/material';

import { selectToken } from '../../../Redux/selector';
import Loader from '../../../Components/UiUxComponents/Loader/Loader';
import { getAllUsersDetails } from '../../../utils/Services/userServices';
import { getAllClients } from '../../../utils/Services/clientServices';
import { getDepartment } from '../../../utils/Services/subscriberConfigurationServices/departmentsServices';
import { getUserDailyReports } from '../../../utils/Services/dailyReportsServices';

function Reports() {
  const [dailyReportData, setDailyReportData] = useState([]);
  const [clientCompanies, setClientCompanies] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [users, setUsers] = useState([]);
  const [filters, setFilters] = useState({ fromDate: '', toDate: '', specificDate: '', username: 'All', clientCompany: 'All', department: 'All' });
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const token = useSelector(selectToken);
  const { subscriberId } = useParams();

  // To fetch User Daily Reports
  const fetchUserDailyReports = async () => {
    setLoading(true);
    try {
      const response = await getUserDailyReports(token, subscriberId, filters);
      setDailyReportData(response?.data || [])
    } catch (error) {
      console.error('Error:', error);
      Swal.fire({
        icon: 'error',
        title: 'Failed ',
        text: 'Failed to fetch data',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      })
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchUserDailyReports();
  }, [filters]);

  // Fetch user details
  const fetchUserDetail = async () => {
    setLoading(true);
    try {
      const response = await getAllUsersDetails(token, subscriberId);
      setUsers(response?.data?.data || []);
    } catch (error) {
      console.error('Error:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'There was an error. Please try again later.',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchUserDetail();
  }, []);

  // Fetch client details
  const fetchClientDetail = async () => {
    setLoading(true);
    try {
      const response = await getAllClients(token, subscriberId);
      setClientCompanies(response?.data || []);
    } catch (error) {
      console.error('Error:', error);
      Swal.fire({
        icon: 'error',
        title: 'Failed ',
        text: 'Failed to fetch data',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      })
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchClientDetail();
  }, []);

  // To fetch Department Details
  const fetchDepartmentDetail = async () => {
    setLoading(true);
    try {
      const response = await getDepartment(token, subscriberId);
      setDepartments(response?.data);
    } catch (error) {
      console.error('Error:', error);
      Swal.fire({
        icon: 'error',
        title: 'Failed ',
        text: 'Failed to fetch data',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      })
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchDepartmentDetail();
  }, []);

  // To load data using default filters
  useEffect(() => {
    const today = new Date();
    const firstDayOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 1));
    const lastDayOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 7));

    setFilters(prevFilters => ({
      ...prevFilters,
      fromDate: firstDayOfWeek.toISOString().split('T')[0],
      toDate: lastDayOfWeek.toISOString().split('T')[0]
    }));
  }, []);

  // To handle filter change
  const handleFilterChange = (e, value, field) => {
    setFilters({
      ...filters,
      [field]: value || 'All'
    });
  };

  // To apply filters
  const applyFilters = () => {
    setAnchorEl(null);
  };

  // To clear filters
  const clearFilters = () => {
    const today = new Date();
    const firstDayOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 1));
    const lastDayOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 7));

    setFilters({
      fromDate: firstDayOfWeek.toISOString().split('T')[0],
      toDate: lastDayOfWeek.toISOString().split('T')[0],
      specificDate: '',
      username: 'All',
      clientCompany: 'All',
      department: 'All'
    });
    setAnchorEl(null);
  };

  // To open filter popover
  const openFilters = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // To close filter popover
  const closeFilters = () => {
    setAnchorEl(null);
  };
  const isPopoverOpen = Boolean(anchorEl);

  const groupTasksByClient = (reports) => {
    return reports.reduce((acc, report) => {
      const date = new Date(report.selectedDate).toLocaleDateString();
      report.task.forEach(task => {
        const clientTasks = acc[date] || {};
        const clientName = task.clientName;

        if (!clientTasks[clientName]) {
          clientTasks[clientName] = [];
        }
        clientTasks[clientName].push(task);
        acc[date] = clientTasks;
      });
      return acc;
    }, {});
  };

  const groupedData = groupTasksByClient(dailyReportData);

  const handleDownload = () => {
    const headers = ['S.No', 'Date', 'User', 'Client Company', 'Task Title', 'No. of Hours', 'Task Status', 'Comments'];
    const csvData = Object.entries(groupedData).map(([date, clients], index) => {
      return Object.entries(clients).flatMap(([clientName, tasks]) =>
        tasks.map((task, taskIndex) => ({
          sNo: taskIndex === 0 ? index + 1 : '',
          date: taskIndex === 0 ? date : '',
          user: taskIndex === 0 ? dailyReportData[0].userID.fullName : '',
          client: taskIndex === 0 ? clientName : '',
          taskTitle: task.taskTitle,
          hours: task.hours,
          status: task.taskStatus,
          comments: task.comments,
        }))
      );
    }).flat();
  
    const csv = [
      headers.join(','), 
      ...csvData.map(row => [
        row.sNo, row.date, row.user, row.client, row.taskTitle, row.hours, row.status, row.comments
      ].join(','))
    ].join('\n');
  
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'daily_reports.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  return (
    <div className='container'>
      <Box sx={{ padding: 2, maxHeight: '85vh', overflow: 'auto' }} className="custom-scrollbar">
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2, gap: 2 }}>
          <Button
            variant="outlined"
            onClick={openFilters}
            sx={{
              color: '#1b4965',
              borderColor: '#1b4965',
              borderRadius: '8px',
              padding: '8px 20px',
              fontWeight: 'bold',
              '&:hover': {
                backgroundColor: '#e0f7fa',
                borderColor: '#1b4965',
              },
            }}
          >
            Filters
          </Button>
          <Button
            variant="contained"
            onClick={handleDownload}
            sx={{
              backgroundColor: '#4caf50',
              color: 'white',
              borderRadius: '8px',
              padding: '8px 20px',
              fontWeight: 'bold',
              '&:hover': {
                backgroundColor: '#45a049',
              },
            }}
          >
            Download
          </Button>
        </Box>
        <Table>
          <thead>
            <Tr>
              <Th>S.No</Th>
              <Th>Date</Th>
              <Th>User</Th>
              <Th>Client Company</Th>
              <Th>Task</Th>
              <Th>No. of Hours</Th>
              <Th>Task Status</Th>
              <Th>Comments</Th>
            </Tr>
          </thead>
          <tbody>
            {Object.entries(groupedData).map(([date, clients], index) => {
              let clientIndex = 0;
              return Object.entries(clients).map(([clientName, tasks]) => {
                return tasks.map((task, taskIndex) => (
                  <Tr key={task._id}>
                    {taskIndex === 0 && clientIndex === 0 && (
                      <>
                        <Td rowSpan={tasks.length}>{index + 1}</Td>
                        <Td rowSpan={tasks.length}>{date}</Td>
                        <Td rowSpan={tasks.length}>{dailyReportData[0].userID.fullName}</Td>
                        <Td rowSpan={tasks.length}>{clientName}</Td>
                      </>
                    )}
                    <Td>
                      <div><strong>Task Title:</strong> {task.taskTitle}</div>
                      <div><strong>Task Description:</strong> {task.taskDescription}</div>
                    </Td>
                    <Td>{task.hours}</Td>
                    <Td>{task.taskStatus}</Td>
                    <Td>{task.comments}</Td>
                  </Tr>
                ));
              });
            })}
          </tbody>
        </Table>
      </Box>
      <Popover open={isPopoverOpen} anchorEl={anchorEl} onClose={closeFilters} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} PaperProps={{ style: { borderRadius: '8px', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)' } }}>
        <FilterBox>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <TextField label="From Date" type="date" name="fromDate" InputLabelProps={{ shrink: true }} value={filters.fromDate} onChange={(e) => setFilters({ ...filters, fromDate: e.target.value })} />
            <TextField label="To Date" type="date" name="toDate" InputLabelProps={{ shrink: true }} value={filters.toDate} onChange={(e) => setFilters({ ...filters, toDate: e.target.value })} />
          </Box>
          <TextField label="Specific Date" type="date" name="specificDate" InputLabelProps={{ shrink: true }} value={filters.specificDate} onChange={(e) => setFilters({ ...filters, specificDate: e.target.value })} />
          <Autocomplete options={['All', ...users.map(user => user.fullName)]} renderInput={(params) => <TextField {...params} label="Username" />} value={filters.username} onChange={(e, value) => handleFilterChange(e, value, 'username')} clearOnEscape />
          <Autocomplete options={['All', ...clientCompanies.map(company => company.companyName)]} renderInput={(params) => <TextField {...params} label="Client Company" />} value={filters.clientCompany} onChange={(e, value) => handleFilterChange(e, value, 'clientCompany')} clearOnEscape />
          <Autocomplete options={['All', ...departments.map(department => department.departmentName)]} renderInput={(params) => <TextField {...params} label="Department" />} value={filters.department} onChange={(e, value) => handleFilterChange(e, value, 'department')} clearOnEscape />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, gap: 2 }}>
            <Button variant="outlined" onClick={clearFilters} sx={{ color: '#1b4965', borderColor: '#1b4965' }}>Clear Filters</Button>
            <Button variant="outlined" onClick={closeFilters} sx={{ color: '#ff5722', borderColor: '#ff5722' }}>Cancel</Button>
            <Button variant="contained" color="primary" onClick={applyFilters} sx={{ backgroundColor: '#4caf50', color: 'white' }}>Apply Filters</Button>
          </Box>
        </FilterBox>
      </Popover>
      {loading && <Loader />}
    </div>
  );
}

export default Reports;

const FilterBox = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: #ffffff;
  border-radius: 8px;
  min-width: 300px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
`;

const Th = styled.th`
  padding: 12px;
  text-align: left;
  border: 1px solid #ddd;
  background-color: #1b4965;
  color: white;
`;

const Td = styled.td`
  padding: 12px;
  text-align: left;
  border: 1px solid #ddd;
`;

const Tr = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }

  &:hover {
    background-color: #ddd;
  }
`;