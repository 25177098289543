import { useLocation, useNavigate } from 'react-router-dom';
import { Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import './ProfileDetailsModal.css';
import User from '../../../../../assets/NavbarImages/user.svg'
import { Roles } from "../../../../../utils/constant";

const ProfileDetailsModal = ({ open, onClose, user }) => {
    const navigate = useNavigate();
    const location = useLocation();
    if (!open) return null;

    const handleButtonClick = () => {
        if (location.pathname.startsWith('/subscriber/')) {
            navigate(`/user/${user?._id}`);
        } else if (location.pathname.startsWith('/client/')) {
            navigate(`/clientadmin/${user?.companyId?._id}`)
        } else if (location.pathname.startsWith('/clientadmin/')) {
            navigate(`/client/${user?._id}`)
        } else {
            navigate(`/subscriber/${user?.companyId?._id}`);
        }
    };

    const handleEditDetailsClick = () => {
        if (location.pathname.startsWith('/client/')) {
            navigate(`/client/coordinator-details/${user?._id}`)
        } else {
            navigate(`/user/user-details/${user?._id}`);
            onClose()
        }
        onClose()
    };

    // To Display User Image
    const userImageUrl = user?.profilePic?.location ? user.profilePic.location : User;

    return (
        <div className="profile-details" onClick={onClose}>
            <div className='profile-details-container' onClick={(e) => e.stopPropagation()}>
                <div style={{ marginBottom: '10px' }}>
                    <Typography variant="body1" component="div">{user?.workEmail}</Typography>
                    <IconButton onClick={onClose} style={{ color: 'white', position: 'absolute', right: '0', top: '0' }}>
                        <CloseIcon sx={{ fontSize: '20px' }} />
                    </IconButton>
                </div>
                <Typography variant="body1" component="div">Managed by {user?.companyId?.companyEmail}</Typography>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
                    <img src={userImageUrl} alt="" style={{ width: '80px', height: '80px', borderRadius: '50%', objectFit: 'fill' }} />
                    <h3>Hello, {user?.firstName}!</h3>
                </div>
                <div style={{ display: 'flex', gap: '5px', justifyContent: 'center' }}>
                    {((user?.role === Roles.SYSTEM_SUPER_ADMIN) || (location.pathname.startsWith('/user/')) || (location.pathname.startsWith('/client/'))) && (
                        <button className='edit-details' onClick={handleEditDetailsClick}>Edit Details</button>
                    )}
                    {/* {user?.role === Roles.COMPANY_SUPER_ADMIN && (
                        <button className='edit-details' onClick={handleButtonClick}>
                            {location.pathname.startsWith('/subscriber/') || location.pathname.startsWith('/clientadmin/')
                                ? 'User Dashboard'
                                : 'Admin Console'}
                        </button>
                    )} */}
                    {user?.role === Roles.COMPANY_SUPER_ADMIN &&
                        !location.pathname.startsWith('/client') && (
                            <button className='edit-details' onClick={handleButtonClick}>
                                {location.pathname.startsWith('/subscriber/') || location.pathname.startsWith('/clientadmin/')
                                    ? 'User Dashboard'
                                    : 'Admin Console'}
                            </button>
                        )}
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '10px', marginBottom: '-12px' }}>
                    <p style={{ fontSize: '12px' }}>Privacy policy</p>
                    <p style={{ fontSize: '12px' }}>Terms of service</p>
                </div>
            </div>
        </div>
    );
};

export default ProfileDetailsModal;
