import axios from 'axios';
const backEndUrl = `${process.env.REACT_APP_BACKEND_URL}/api/auth`;

// To login Users
export const loginUser = (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                'Content-Type': 'application/json'
            }
            let response = await axios.post(`${backEndUrl}/login`, data, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// To get email of user for OTP 
export const getUserEmail = (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                'Content-Type': 'application/json'
            }
            let response = await axios.post(`${backEndUrl}/getuseremail`, data, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// Forgot Password Email Service
export const forgotPassword = (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                'Content-Type': 'application/json'
            }
            let response = await axios.post(`${backEndUrl}/forgot-password`, data, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// To Verify OTP
export const verifyOtp = (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                'Content-Type': 'application/json'
            }
            let response = await axios.post(`${backEndUrl}/verify-otp`, data, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// To Change Password by OTP
export const changePasswordByOtp = (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                'Content-Type': 'application/json'
            }
            let response = await axios.post(`${backEndUrl}/change-password-by-otp`, data, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};