import React from 'react'

function Configuration() {
  return (
    <div>
      Configuration Page
    </div>
  )
}

export default Configuration
