import axios from 'axios';
const backEndUrl = `${process.env.REACT_APP_BACKEND_URL}/api/company`;

// To get All Compines Details
export const getAllCompinesDetails = () => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                'Content-Type': 'application/json'
            }
            let response = await axios.get(`${backEndUrl}/getallCompinesDetails`, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// To get company details
export const getCompanyDetails = (token) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.get(`${backEndUrl}/companydetails`, { headers });
            return resolve(response.data);
        } catch (err) {
            return reject(err);
        }
    });
};

// To Create a Company
export const createCompany = async (token, companyDetails, adminDetails, companyIds) => {
    const formData = new FormData();
    formData.append('companyDetails', JSON.stringify(companyDetails));
    formData.append('adminDetails', JSON.stringify(adminDetails));
    formData.append('companyIds', JSON.stringify(companyIds));

    if (companyDetails.logo) {
        formData.append('logo', companyDetails.logo);
    }

    if (adminDetails.profilePic) {
        formData.append('profilePic', adminDetails.profilePic);
    }
    
    if (adminDetails.uploadedFile) {
        formData.append('uploadedFile', adminDetails.uploadedFile);
    }

    try {
        let response = await axios.post(`${backEndUrl}/createcompany`, formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (err) {
        throw err;
    }
};

// To get Specific Company Details
export const getSpecificCompanyDetails = (token, companyId) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.get(`${backEndUrl}/getSpecificCompanyDetails/${companyId}`, { headers });
            return resolve(response.data);
        } catch (err) {
            return reject(err);
        }
    });
};

// To Update Company Data
export const updateCompanyData = (token, companyId, companyDetails) => {
    const formData = new FormData();
    formData.append('companyDetails', JSON.stringify(companyDetails));
    if (companyDetails.logo) {
        formData.append('logo', companyDetails.logo);
    }
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data"
            }
            let response = await axios.put(`${backEndUrl}/update-company-data/${companyId}`, formData, { headers });
            return resolve(response.data);
        } catch (err) {
            return reject(err);
        }
    });
};