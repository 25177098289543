import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import Swal from "sweetalert2";

import Loader from "../../../Components/UiUxComponents/Loader/Loader";
import { selectToken, selectUser } from "../../../Redux/selector";
import CustomTime from "../../../Components/UiUxComponents/CustomComponents/CustomTime/CustomTime";
import CustomCalendar from "../../../Components/UiUxComponents/CustomComponents/CustomCalendar/CustomCalendar";
import { getUserAttendanceStatus } from "../../../utils/Services/userDashboardServices";
import { getCurrentDayShift } from "../../../utils/Services/shiftServices";

function UsersDashboard() {
  const [userAttendanceStatus, setUserAttendanceStatus] = useState()
  const [currentShift, setCurrentShift] = useState(null);
  const [currentShiftMessage, setCurrentShiftMessage] = useState('')
  const [loading, setLoading] = useState(false);
  const token = useSelector(selectToken);
  const user = useSelector(selectUser);
  const { userId } = useParams();
  const navigate = useNavigate();

  // To get Attendance status
  const fetchAttendanceStatus = async () => {
    setLoading(true);
    try {
      const date = new Date();
      const data = { date };
      const response = await getUserAttendanceStatus(token, userId, data);
      setUserAttendanceStatus(response?.data)
    } catch (error) {
      console.error('Error fetching attendance data:', error);
      Swal.fire({
        icon: 'error',
        title: 'Failed ',
        text: 'Failed to fetch user attendance',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchAttendanceStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // To get current date Shift Data
  const fetchCurrentDateShiftData = async () => {
    try {
      setLoading(true);
      const response = await getCurrentDayShift(token, userId);
      if (response?.data?.shiftData) {
        if (response?.data?.message === 'No assigned shift, sending default shift') {
          setCurrentShift(response?.data?.shiftData);
        } else {
          setCurrentShift(response?.data?.shiftData[0]?.shiftData)
        }
      } else if (response?.data?.message === 'No shift available for today.') {
        setCurrentShiftMessage(response?.data?.message)
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchCurrentDateShiftData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // To get the Greeting
  const getCurrentGreeting = () => {
    const currentHour = new Date().getHours();

    if (currentHour < 12) {
      return "Good Morning";
    } else if (currentHour < 17) {
      return "Good Afternoon";
    } else {
      return "Good Evening";
    }
  };

  const handleAttendance = () => {
    if (!userAttendanceStatus?.attendance) {
      navigate(`/user/attendance/${userId}`);
    } else if (userAttendanceStatus?.attendance.attendanceStatus === 'Clock-in') {
      navigate(`/user/attendance/${userId}`);
    } else if (userAttendanceStatus?.attendance.attendanceStatus === 'Clock-out') {
      navigate(`/user/attendance/${userId}`);
    }
  };

  // Determine attendance and attendanceStatus
  const attendance = userAttendanceStatus?.attendance !== undefined;
  const attendanceStatus = attendance ? userAttendanceStatus.attendance.attendanceStatus : null;

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', padding: '5px', backgroundColor: '#f5f5f5' }}>
      <div style={{ margin: '10px', display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p style={{ fontSize: '25px', fontWeight: 'bold', color: '#1B4965', backgroundColor: 'white', padding: '12px', borderRadius: '8px', margin: 0, display: 'inline-block' }}>
            {getCurrentGreeting()}, {user?.firstName}!
          </p>
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'stretch' }}>
          <div style={{ width: '420px', height: '300px', backgroundColor: 'white', borderRadius: '8px', marginTop: '20px' }}>
            <p style={{ fontSize: '22px', color: '#1B4965', margin: 0, borderBottom: '1px solid #ccc', paddingBottom: '8px', padding: '16px', fontWeight: 'bold', flex: 1 }}>Attendance</p>
            {loading ? (
              <Loader />
            ) : userAttendanceStatus?.message === "No attendance record found for this date" ? (
              <div style={{ color: "#1B4965", margin: 0, borderBottom: "1px solid #ccc", paddingBottom: "8px", padding: "16px", flex: 1 }}>
                <p style={{ fontSize: "20px", color: "#757575", margin: 0, marginBottom: "5px" }}>Today</p>
                <p style={{ fontSize: "16px", color: "#757575", margin: 0, marginBottom: "5px" }}>You have not marked yourself as present today!</p>
                <p style={{ fontSize: "18px", color: "#1B4965", margin: 0, marginBottom: "10px" }}>Shift timing: -</p>
                <p style={{ fontSize: "16px", color: "#d32f2f", margin: 0 }}>Time left: -</p>
              </div>
            ) : userAttendanceStatus?.attendance ? (
              <div style={{ color: "#1B4965", margin: 0, borderBottom: "1px solid #ccc", paddingBottom: "8px", padding: "16px", flex: 1 }}>
                <p style={{ fontSize: "20px", color: "#757575", margin: 0, marginBottom: "5px" }}>Today</p>
                {userAttendanceStatus.attendance.attendanceStatus === "Clock-in" ? (
                  <>
                    <p style={{ fontSize: "16px", color: "#757575", margin: 0, marginBottom: "5px" }}>
                      You already clicked Clock-out
                    </p>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" }}>
                      <span style={{ fontSize: "16px", color: "#1B4965" }}>
                        <strong>Clock-in:</strong> {new Date(userAttendanceStatus.attendance.clockInTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                      </span>
                      <span style={{ fontSize: "16px", color: "#1B4965" }}>
                        <strong>Clock-out:</strong> {new Date(userAttendanceStatus.attendance.clockOutTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                      </span>
                    </div>
                    <p style={{ fontSize: "16px", color: "#1B4965", margin: 0 }}>
                      <strong>Duration:</strong> {userAttendanceStatus.attendance.grossHours} hours
                    </p>
                  </>
                ) : (
                  <>
                    <p style={{ fontSize: "16px", color: "#1B4965", margin: 0, marginBottom: "10px" }}>
                      You clicked Clock-in at {new Date(userAttendanceStatus.attendance.clockInTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}.
                    </p>
                    <p style={{ fontSize: "16px", color: "#1B4965", margin: 0, marginBottom: "10px" }}>
                      Your Shift Will end at -
                    </p>
                    <p style={{ fontSize: "16px", color: "#1B4965", margin: 0 }}>
                      Make sure to Clock-out at the end of your shift!
                    </p>
                  </>
                )}
              </div>
            ) : null}
            <button style={{ padding: '10px 12px', backgroundColor: '#1B4965', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer', margin: '20px 10px 0px 18px', width: '90%', fontSize: '18px' }} onClick={handleAttendance}>
              {attendance === false
                ? 'Clock-in Tomorrow'
                : attendance === true && attendanceStatus === 'Clock-out'
                  ? 'Clock-out'
                  : 'Clock-in '}
            </button>
          </div>
        </div>
      </div>
      <div style={{ margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <CustomTime />
        <CustomCalendar />
      </div>
    </div>
  );
}

export default UsersDashboard;