import axios from 'axios';
const backEndUrl = `${process.env.REACT_APP_BACKEND_URL}/api/dailyReport`;

// To Add user daily report
export const addDailyReport = (token, userId, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.post(`${backEndUrl}/add-daily-report/${userId}`, data, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// To Get user daily report
export const getDailyReport = (token, userId, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.get(`${backEndUrl}/get-daily-report/${userId}`, {
                headers,
                params: data
            });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// To Update user daily report
export const updateDailyReport = (token, userId, taskId, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.put(`${backEndUrl}/update-daily-report/${taskId}/${userId}`, data, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// To Delete user daily report
export const deleteDailyReport = (token, userId, taskId, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.delete(`${backEndUrl}/delete-daily-report/${taskId}/${userId}`, {
                headers,
                params: data
            });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// To Submit user daily report
export const submitDailyReport = (token, userId, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.put(`${backEndUrl}/submit-daily-report/${userId}`, data, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// To get reports for company admin
export const getUserDailyReports = (token, subscriberId, filters) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.get(`${backEndUrl}/get-user-daily-reports/${subscriberId}`, {
                headers,
                params: filters
            });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};