import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { useDropzone } from 'react-dropzone';

import Swal from 'sweetalert2';
import { Button, Grid, IconButton, TextField } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import Fileuploadimg from '../../../../assets/Mixed/file-upload.svg';
import Loader from '../../../UiUxComponents/Loader/Loader';
import { selectToken } from '../../../../Redux/selector';
import { getSpecificCompanyDetails, updateCompanyData } from '../../../../utils/Services/companyServices'

const CompanyDetails = () => {
    const [companyDetails, setCompanyDetails] = useState()
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const token = useSelector(selectToken);
    const { clientId } = useParams();

    // To get specific company details
    const fetchCompanyDetails = async () => {
        setLoading(true);
        try {
            const response = await getSpecificCompanyDetails(token, clientId);
            setCompanyDetails(response?.company || {});
        } catch (err) {
            console.error('Error fetching company details:', err);
            Swal.fire({
                icon: 'error',
                title: 'Failed ',
                text: 'Failed to fetch data',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            })
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCompanyDetails();
    }, []);

    // Handle to edit the fields
    const handleEditClick = async () => {
        if (isEditing) {
            await fetchCompanyDetails();
        }
        setIsEditing(prevEditing => {
            const newEditingState = !prevEditing;
            if (newEditingState) {
                Swal.fire({
                    icon: 'info',
                    title: 'Editing Enabled',
                    text: 'Now you can edit the fields.',
                    confirmButtonColor: '#1B4965',
                    customClass: {
                        container: 'swal-container',
                    }
                });
            }
            return newEditingState;
        });
    };

    // handle input change
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setCompanyDetails(prevDetails => ({
            ...prevDetails,
            [name]: value,
        }));
    };

    // To Upload a Image
    const { getRootProps: getLogoRootProps, getInputProps: getLogoInputProps } = useDropzone({
        accept: { 'image/jpeg': [], 'image/png': [], 'image/svg+xml': [] },
        onDrop: (acceptedFiles) => {
            const file = acceptedFiles[0];
            if (file) {
                const fileType = file?.type;
                if (fileType.startsWith('image/')) {
                    setCompanyDetails((prevDetails) => ({
                        ...prevDetails,
                        logo: file,
                    }));
                } else {
                    Swal.fire({
                        title: 'Invalid Image',
                        text: 'Only image files (JPEG, PNG, SVG) are allowed.',
                        icon: 'warning',
                        confirmButtonColor: '#1B4965',
                        customClass: {
                            container: 'swal-container',
                        }
                    });
                }
            }
        },
    });

    // Handle Update
    const handleUpdate = async () => {
        setLoading(true);
        try {
            await updateCompanyData(token, clientId, companyDetails);
            Swal.fire({
                icon: 'success',
                title: 'Client Company Details Updated',
                text: 'The company details have been successfully updated.',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
        } catch (err) {
            Swal.fire({
                icon: 'error',
                title: 'Update Failed',
                text: 'There was an error updating the company details. Please try again.',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
            console.error('Error updating company details:', err);
        } finally {
            setLoading(false);
            setIsEditing(false);
            fetchCompanyDetails();
        }
    };

    return (
        <span>
            <Container style={{ padding: '20px', height: '70vh', overflow: 'auto' }}>
                <Grid container spacing={2} justifyContent="flex-end" sx={{ marginBottom: '20px' }}>
                    <Grid item>
                        <Button variant="contained"
                            sx={{
                                backgroundColor: '#5A9BD5',
                                color: '#fff',
                                '&:hover': {
                                    backgroundColor: '#5A9BD5',
                                    opacity: 1
                                }
                            }}
                            endIcon={<EditIcon />}
                            onClick={handleEditClick}>{isEditing ? 'Cancel' : 'Edit'}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained"
                            sx={{
                                backgroundColor: '#1B4965',
                                color: '#fff',
                                '&:hover': {
                                    backgroundColor: '#1B4965',
                                    opacity: 1
                                }
                            }}
                            endIcon={<RestartAltIcon />} onClick={handleUpdate}>Update
                        </Button>
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                        <Grid item xs={12}>
                            <TextField required id="companyName" name="companyName" label="Company Name" variant="outlined" fullWidth className='TextField' margin="dense" InputProps={{ readOnly: !isEditing }} onChange={handleInputChange} value={companyDetails?.companyName || ''}
                                sx={{
                                    '& .MuiInputLabel-root': { fontSize: '14px' },
                                    '& .MuiInputBase-root': { fontSize: '14px' },
                                    width: '100%'
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField required id="companyCode" name="companyCode" label="Company Code" variant="outlined" fullWidth className='TextField' margin="dense" InputProps={{ readOnly: !isEditing }} onChange={handleInputChange} value={companyDetails?.companyCode || ''}
                                sx={{
                                    '& .MuiInputLabel-root': { fontSize: '14px' },
                                    '& .MuiInputBase-root': { fontSize: '14px' },
                                    width: '100%'
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        {(companyDetails?.logoLocation || companyDetails?.logo) ? (
                            <>
                                {companyDetails?.logoLocation?.originalname ?
                                    <>
                                        <div>
                                            {isEditing && (
                                                <div style={{ zIndex: 97, display: 'flex', justifyContent: 'flex-end', marginBottom: '-40px' }}>
                                                    <IconButton onClick={() => setCompanyDetails((prevDetails) => ({ ...prevDetails, logoLocation: null }))}>
                                                        <DeleteForeverIcon sx={{ fontSize: '22px', cursor: 'pointer', }} />
                                                    </IconButton>
                                                </div>
                                            )}
                                            <div style={{ height: '120px', textAlign: 'center', borderRadius: '10px' }}>
                                                <img alt="Logo" src={companyDetails?.logoLocation?.location} style={{ height: '100%', width: '100%', objectFit: 'contain' }} />
                                            </div>
                                        </div>
                                    </> :
                                    companyDetails?.logo.name ?
                                        <div>
                                            <div style={{ zIndex: 97, display: 'flex', justifyContent: 'flex-end', marginBottom: '-40px' }}>
                                                <IconButton onClick={() => setCompanyDetails((prevDetails) => ({ ...prevDetails, logo: null }))}>
                                                    <DeleteForeverIcon sx={{ fontSize: '22px', cursor: 'pointer', }} />
                                                </IconButton>
                                            </div>
                                            <div style={{ height: '120px', textAlign: 'center', borderRadius: '10px' }}>
                                                <img alt="Logo" src={typeof companyDetails?.logo === 'string' ? companyDetails?.logo : URL.createObjectURL(companyDetails?.logo)} style={{ height: '100%', width: '100%', objectFit: 'contain' }} />
                                            </div>
                                        </div> : null
                                }
                            </>
                        ) : (
                            <div {...getLogoRootProps()} style={{ height: '120px', textAlign: 'center', border: '1px dashed #1B4965', borderRadius: '10px', cursor: 'pointer', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <input {...getLogoInputProps()} />
                                <img src={Fileuploadimg} alt="" />
                                <p style={{ color: 'rgba(0, 0, 0, 0.6)' }}>Click (or) Drag and Drop your Photo Here.. </p>
                            </div>
                        )}
                        { }
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                        <TextField required id="companyOwnerName" name="companyOwnerName" label="Company Owner Name" variant="outlined" fullWidth className='TextField' margin="dense" InputProps={{ readOnly: !isEditing }} onChange={handleInputChange} value={companyDetails?.companyOwnerName || ''}
                            sx={{
                                '& .MuiInputLabel-root': { fontSize: '14px' },
                                '& .MuiInputBase-root': { fontSize: '14px' },
                                width: '100%'
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField required id="companyEmail" name="companyEmail" label="Company Email" variant="outlined" fullWidth className='TextField' margin="dense" InputProps={{ readOnly: !isEditing }} onChange={handleInputChange} value={companyDetails?.companyEmail || ''}
                            sx={{
                                '& .MuiInputLabel-root': { fontSize: '14px' },
                                '& .MuiInputBase-root': { fontSize: '14px' },
                                width: '100%'
                            }}
                        />
                    </Grid>
                </Grid>
                <TextField required id="companyWebsite" name="companyWebsite" label="Company Website" variant="outlined" fullWidth className='TextField' margin="dense" InputProps={{ readOnly: !isEditing }} onChange={handleInputChange} value={companyDetails?.companyWebsite || ''}
                    sx={{
                        '& .MuiInputLabel-root': { fontSize: '14px' },
                        '& .MuiInputBase-root': { fontSize: '14px' },
                        width: '100%'
                    }}
                />
                <Grid container spacing={2} alignItems="flex-start">
                    <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <TextField id="companyAddress" name="companyAddress" label="Company Address" variant="outlined" fullWidth multiline rows={5} className='TextField' margin="dense" InputProps={{ readOnly: !isEditing }} onChange={handleInputChange} value={companyDetails?.companyAddress || ''}
                            sx={{
                                '& .MuiInputLabel-root': { fontSize: '14px' },
                                '& .MuiInputBase-root': { fontSize: '14px' },
                                height: '100%'
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} container spacing={2} alignItems="center">
                        <Grid item xs={6}>
                            <TextField id="city" name="city" label="City" variant="outlined" fullWidth className='TextField' margin="dense" InputProps={{ readOnly: !isEditing }} onChange={handleInputChange} value={companyDetails?.city || ''}
                                sx={{
                                    '& .MuiInputLabel-root': { fontSize: '14px' },
                                    '& .MuiInputBase-root': { fontSize: '14px' },
                                    width: '100%'
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField id="state" name="state" label="State" variant="outlined" fullWidth className='TextField' margin="dense" InputProps={{ readOnly: !isEditing }} onChange={handleInputChange} value={companyDetails?.state || ''}
                                sx={{
                                    '& .MuiInputLabel-root': { fontSize: '14px' },
                                    '& .MuiInputBase-root': { fontSize: '14px' },
                                    width: '100%'
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField id="country" name="country" label="Country" variant="outlined" fullWidth className='TextField' margin="dense" InputProps={{ readOnly: !isEditing }} onChange={handleInputChange} value={companyDetails?.country || ''}
                                sx={{
                                    '& .MuiInputLabel-root': { fontSize: '14px' },
                                    '& .MuiInputBase-root': { fontSize: '14px' },
                                    width: '100%'
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField id="timezone" name="timezone" label="Timezone" variant="outlined" fullWidth className='TextField' margin="dense" InputProps={{ readOnly: !isEditing }} onChange={handleInputChange} value={companyDetails?.timezone || ''}
                                sx={{
                                    '& .MuiInputLabel-root': { fontSize: '14px' },
                                    '& .MuiInputBase-root': { fontSize: '14px' },
                                    width: '100%'
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            {(loading) && (
                <Loader />
            )}
        </span>
    );
};

export default CompanyDetails;

const Container = styled.div`
    padding: 20px;
    height: 70vh;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: #1B4965 #f0f0f0;

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #1B4965;
        border-radius: 20px;
    }

    &::-webkit-scrollbar-track {
        background: #f0f0f0;
    }
`;