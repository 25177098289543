import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Swal from 'sweetalert2';
import { Box, Button, Card, CardContent, Grid, Modal, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

import Loader from '../../../UiUxComponents/Loader/Loader';
import { selectToken } from '../../../../Redux/selector';
import AddNewShift from '../AddNewShifts/AddNewShift';
import { getAllShift } from '../../../../utils/Services/shiftServices';
import EditShifts from '../EditShifts/EditShifts';

function ViewShifts({ openModal, handleCloseModal }) {
    const [isNewShiftModalOpen, setIsNewShiftModalOpen] = useState(false);
    const [isEditShiftModalOpen, setIsEditShiftModalOpen] = useState(false);
    const [selectedShiftForEdit, setSelectedShiftForEdit] = useState(null);
    const [loading, setLoading] = useState(false);
    const [shifts, setShifts] = useState([]);
    const token = useSelector(selectToken);
    const { subscriberId } = useParams();

    // To get shifts
    const fetchShiftDetails = async () => {
        setLoading(true);
        try {
            const response = await getAllShift(token, subscriberId);

            if (response.status === 404) {
                setShifts([]);
            } else {
                setShifts(response?.data);
            }
        } catch (err) {
            console.error('Error fetching shifts:', err);
            if (err.response?.status !== 404) {
                Swal.fire({
                    icon: 'error',
                    title: 'Failed',
                    text: 'Failed to fetch Shifts',
                    confirmButtonColor: '#1B4965',
                    customClass: {
                        container: 'swal-container',
                    }
                });
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchShiftDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // To Open Add New Shift Modal
    const handleOpenNewShiftModal = () => {
        setIsNewShiftModalOpen(true);
    };

    // To Open Edit Shift Modal
    const handleEditShift = (shift) => {
        setSelectedShiftForEdit(shift);
        setIsEditShiftModalOpen(true);
    };

    // To Close Edit Shift Modal
    const handleEditShiftModalClose = () => {
        setIsEditShiftModalOpen(false);
        fetchShiftDetails();
    };

    // To Close Add New Shift Modal
    const handleCloseNewShiftModal = () => {
        setIsNewShiftModalOpen(false);
        fetchShiftDetails();
    };

    return (
        <>
            <Modal open={openModal} onClose={handleCloseModal} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Box sx={{ width: 300, height: '100vh', backgroundColor: 'white', boxShadow: 24, p: 4, position: 'relative', left: 0, transition: 'transform 0.3s ease-in-out', transform: openModal ? 'translateX(0)' : 'translateX(-100%)' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', marginTop: '15px' }}>
                        <Typography variant="h6" sx={{ color: '#1B4965' }}>Available Shifts</Typography>
                        <Button onClick={handleCloseModal} variant="contained"
                            sx={{
                                backgroundColor: '#1B4965', color: 'white',
                                '&:hover': {
                                    backgroundColor: '#1B4965',
                                },
                                fontSize: '12px'
                            }}
                        >Back
                        </Button>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                        <Button variant="contained" onClick={handleOpenNewShiftModal}
                            sx={{
                                backgroundColor: '#FF6B6B', color: 'white',
                                '&:hover': {
                                    backgroundColor: '#FF4C4C',
                                },
                                display: 'flex',
                                alignItems: 'center',
                                gap: 0.5,
                            }}
                        ><AddIcon />New Shift
                        </Button>
                    </Box>
                    <Box sx={{
                        mt: 2, height: '70vh', overflow: 'auto', scrollbarWidth: 'thin', scrollbarColor: '#1B4965 #f0f0f0',
                        '&::-webkit-scrollbar': {
                            width: '8px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#1B4965',
                            borderRadius: '20px',
                        },
                        '&::-webkit-scrollbar-track': {
                            background: '#f0f0f0',
                        },
                    }}>
                        {shifts.map(shift => (
                            <Card key={shift._id} sx={{ mt: 2, boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', backgroundColor: '#f5f5f5', borderRadus: '8px', overflow: 'hidden' }}>
                                <CardContent sx={{ p: 1 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={9}>
                                            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 0.5 }}>
                                                {shift.shiftTitle}
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary"  sx={{ mb: 0 }}>
                                                {shift.startTime} - {shift.endTime}
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary"  sx={{ mt: 0.5 }}>
                                                Duration: {shift.duration}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3} sx={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                                            <EditIcon sx={{ color: '#9C27B0', cursor: 'pointer' }} onClick={() => handleEditShift(shift)} />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        ))}
                    </Box>
                </Box>
            </Modal>
            <AddNewShift open={isNewShiftModalOpen} handleClose={handleCloseNewShiftModal} />
            <EditShifts open={isEditShiftModalOpen} handleClose={handleEditShiftModalClose} shift={selectedShiftForEdit} />
            {(loading) && (
                <Loader />
            )}
        </>
    )
}

export default ViewShifts;
