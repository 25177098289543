import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutUser } from "../Redux/reducers/authReducer";
import Swal from "sweetalert2";

const useInactivityTimeout = (isExcludedRoute = false, timeout = 60 * 60 * 1000) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let logoutTimer: ReturnType<typeof setTimeout> | null = null

  const resetLogoutTimer = () => {
    if (logoutTimer) clearTimeout(logoutTimer);
    logoutTimer = setTimeout(() => {
      handleLogout();
    }, timeout);
  };

  const handleLogout = () => {
    dispatch(logoutUser());
    Swal.fire({
      icon: 'info',
      title: 'Session Expired',
      text: 'Your session has expired due to inactivity. Please log in again.',
      confirmButtonColor: "#1B4965",
    }).then(() => {
      navigate("/");
    });
  };

  useEffect(() => {
    if (isExcludedRoute) return;

    document.addEventListener('mousemove', resetLogoutTimer);
    document.addEventListener('keypress', resetLogoutTimer);
    resetLogoutTimer();

    return () => {
      if(logoutTimer) clearTimeout(logoutTimer);
      document.removeEventListener('mousemove', resetLogoutTimer);
      document.removeEventListener('keypress', resetLogoutTimer);
    };
  }, [isExcludedRoute]);
};

export default useInactivityTimeout;
