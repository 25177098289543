import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Swal from 'sweetalert2';
import { MaterialReactTable, useMaterialReactTable, type MRT_ColumnDef, } from 'material-react-table';
import { ListItemIcon, MenuItem } from '@mui/material';
import { AccountCircle, Send } from '@mui/icons-material';

import './Client.css'
import Loader from '../../../Components/UiUxComponents/Loader/Loader'
import { CompanyType } from '../../../types';
import Pagination from '../../../Components/Pagination/Pagination';
import { selectToken, selectUser } from '../../../Redux/selector';
import ClientRegistration from '../../../Components/Registrations/ClientRegistration/ClientRegistration';
import { getCompanyDetails } from '../../../utils/Services/companyServices'

const Client = () => {
  const [data, setData] = useState<CompanyType[]>([]);
  const [openModal, setClientRegistrationModal] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const token = useSelector(selectToken);
  const user = useSelector(selectUser);
  const companyId = user?.companyId?._id

  // To fetch Client Details
  const fetchCompanyDetails = async () => {
    setLoading(true);
    try {
      const result = await getCompanyDetails(token);
      const transformedData = result.data
      .filter((company: CompanyType) => company.clientOf === companyId)
      .map((company: CompanyType) => ({
        ...company,
        companyAdminName: company.employees && company?.employees?.length > 0 ? company?.employees[0]?.fullName : 'N/A',
        location: company.city && company.country ? `${company.city}, ${company.country}` : 'N/A'
      }));
      setData(transformedData);
    } catch (error) {
      console.error('Error fetching company details:', error);
      Swal.fire({
        icon: 'error',
        title: 'Failed ',
        text: 'Failed to fetch company details',
        confirmButtonColor: '#1B4965',
        customClass: {
            container: 'swal-container',
        }
    });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchCompanyDetails();
  }, []);
  useEffect(() => {
    if (!openModal) {
      fetchCompanyDetails();
    }
  }, [openModal]);

  // To open Client Registration Modal
  const openClientRegistrationModal = () => {
    setClientRegistrationModal(true);
  };

  // To close Client Registration Modal
  const closeClientRegistrationModal = () => {
    setClientRegistrationModal(false);
  };

  // To Handle page change in pagination
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // To display columns
  const columns = useMemo<MRT_ColumnDef<CompanyType>[]>(
    () => [
      { accessorKey: 'companyCode', header: 'Company Code' },
      { accessorKey: 'companyName', header: 'Company Name' },
      { accessorKey: 'companyOwnerName', header: 'Company Owner Name' },
      { accessorKey: 'companyAdminName', header: 'Company Admin Name' },
      { accessorKey: 'location', header: 'Location' },
    ],
    [],
  );

  // To set table header styles
  const muiTableHeadCellProps = {
    sx: {
      backgroundColor: '#1B4965',
      color: '#fff',
      fontWeight: 'bold',
      '& .MuiTableSortLabel-root .MuiTableSortLabel-icon': {
        color: '#fff !important',
      },
      '& .MuiTableSortLabel-icon': {
        color: '#fff',
      },
      '& .MuiIconButton-root': {
        color: '#fff',
      },
      '&:first-of-type': {
        borderTopLeftRadius: '12px',
      },
      '&:last-of-type': {
        borderTopRightRadius: '12px',
      },
      fontSize: '14px'
    },
  };

  // To set table body row styles
  const muiTableBodyRowProps = (row: any) => ({
    sx: {
      backgroundColor: getRowBackgroundColor(row),
    }
  })

  // To set table body cell styles
  const muiTableBodyCellProps = (row: any) => ({
    sx: {
      fontSize: '13px'
    }
  })

  // To set background color for even rows in tables
  const getRowBackgroundColor = (row: any) => {
    return row?.row?.index % 2 === 0 ? "#ffffff" : "#d6e1ed"
  };

  // To Render table
  const table = useMaterialReactTable({
    columns,
    data: data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    enableTopToolbar: false,
    muiTableHeadCellProps,
    muiTableBodyRowProps,
    muiTableBodyCellProps,
    enablePagination: false,
    enableRowActions: true,
    renderBottomToolbarCustomActions: () => (
      <Pagination
        count={data.length}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    ),
    initialState: {
      columnPinning: { right: ['mrt-row-actions'] }
    },
    muiTablePaperProps: ({ table }) => ({
      style: {
        zIndex: -1 
      },
    }),
    renderRowActionMenuItems: ({row, closeMenu }) => [
      <MenuItem
        key={0}
        onClick={() => {
          navigate(`/subscriber/client-details/${row.original._id}`);
          closeMenu();
        }}
        sx={{ m: 0, fontSize: '13px' }}
      >
        <ListItemIcon>
          <AccountCircle style={{ fontSize: '18px' }} />
        </ListItemIcon>
        View Details
      </MenuItem>,
      <MenuItem
        key={1}
        onClick={() => {
          alert("Write send email logic")
          closeMenu();
        }}
        sx={{ m: 0, fontSize: '13px' }}
      >
        <ListItemIcon >
          <Send style={{ fontSize: '18px' }} />
        </ListItemIcon>
        Send Email
      </MenuItem >,
    ],
  })

  return (
    <div>
      <div className='companyclient-dashboard'>
        <div className='companyclient-title'>
          <h1>Clients List</h1>
        </div>
        <button className='companyclient-addclient' onClick={openClientRegistrationModal}>Add Client</button>
      </div>
      <div className='companyclient-clienttable'>
        <MaterialReactTable table={table} />
      </div>
      <ClientRegistration open={openModal} onClose={closeClientRegistrationModal} />
      {(loading) && (
        <Loader />
      )}
    </div>
  )
}

export default Client