import React, { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import { Box, Button, IconButton, InputAdornment, TextField } from '@mui/material';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import './ChangePassword.css';
import Applogo from '../../../assets/NavbarImages/app-logo-color.png';
import Loader from '../../../Components/UiUxComponents/Loader/Loader';
import { changePasswordByOtp, verifyOtp } from '../../../utils/Services/authServices';

function ChangePassword() {
  const [changePasswordData, setChangePasswordData] = useState({ password: '', confirmPassword: '' });
  const [showPassword, setShowPassword] = useState({ password: false, confirmPassword: false });
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const otpInputs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];
  const [loading, setLoading] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { email } = location.state || {};

  const handleInputChange = (event) => {
    setChangePasswordData({ ...changePasswordData, [event.target.name]: event.target.value });
  };

  // Handle Password Visibility Toggle
  const handleClickShowPassword = (field) => {
    setShowPassword({ ...showPassword, [field]: !showPassword[field] });
  };

  // Handle Change OTP
  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    if (value !== '' && index < 5) {
      otpInputs[index + 1].current.focus();
    }
  };

  // Handle Key Press Down
  const handleKeyDown = (index, event) => {
    if (event.key === 'Backspace' && index > 0 && otp[index] === '') {
      otpInputs[index - 1].current.focus();
    }
  };

  // To Handle Verify OTP
  const handleVerifyOtp = async () => {
    if (otp.some(digit => digit === '')) {
      Swal.fire({
        icon: 'warning',
        title: 'OTP required',
        text: 'Please enter the complete OTP code.',
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }

    const otpCode = otp.join('');
    const data = { email: email, userEnteredOTP: otpCode };
    setLoading(true);
    try {
      await verifyOtp(data);
      setIsOtpVerified(true); 
      Swal.fire({
        icon: 'success',
        title: 'OTP Verified',
        text: 'You can now change your password.',
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'OTP Verification Failed',
        text: 'The OTP entered is incorrect or has expired.',
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
    }
  };

  // To Handle Change Password
  const handleChangePassword = async (e) => {
    e.preventDefault();
    if (!changePasswordData.password || !changePasswordData.confirmPassword) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please fill all the required details',
        confirmButtonColor: "#1B4965",
      });
      return;
    } else if (changePasswordData.password !== changePasswordData.confirmPassword) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Passwords do not match',
        confirmButtonColor: "#1B4965",
      });
      return;
    }
    const data = { email: email, newPassword : changePasswordData.password };
    setLoading(true);
    try {
      const response = await changePasswordByOtp(data);
      Swal.fire({
        icon: 'success',
        title: 'Password Changed Successfully',
        text: response.data.message || "Password changed successfully.",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.response?.data?.message || "Failed to change password.",
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
      navigate("/");
    }
  };

  return (
    <div className='changePassword'>
      <div className='changepassword-container'>
        <img src={Applogo} alt="" className='changepassword-logo' />
        <h2 className='changepassword-title'>Change Password</h2>
        {!isOtpVerified ? (
          <>
            <div className='otp-card-inputs' style={{ display: 'flex' }}>
              {otp.map((digit, index) => (
                <input key={index} ref={otpInputs[index]} type="text" maxLength="1" value={digit} onChange={(e) => handleOtpChange(index, e.target.value)} onKeyDown={(e) => handleKeyDown(index, e)}/>
              ))}
            </div>
            <Button onClick={handleVerifyOtp} variant="contained" style={{ marginTop: '1rem', backgroundColor: '#1B4965', color: '#fff' }}>
              Verify OTP
            </Button>
          </>
        ) : (
          <form onSubmit={handleChangePassword} style={{ width: '80%' }}>
            <Box>
              <TextField label="Password" variant="outlined" type={showPassword.password ? "text" : "password"} fullWidth margin="normal" name="password"
                onChange={handleInputChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={() => handleClickShowPassword('password')} size="small">
                        {showPassword.password ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#1B4965",
                    },
                    "&:hover fieldset": {
                      borderColor: "#1B4965",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#051925",
                    },
                  },
                }}
              />
            </Box>
            <Box>
              <TextField
                label="Confirm Password"
                variant="outlined"
                type={showPassword.confirmPassword ? "text" : "password"}
                fullWidth
                margin="normal"
                name="confirmPassword"
                onChange={handleInputChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={() => handleClickShowPassword('confirmPassword')} size="small">
                        {showPassword.confirmPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#1B4965",
                    },
                    "&:hover fieldset": {
                      borderColor: "#1B4965",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#051925",
                    },
                  },
                }}
              />
            </Box>
            <Button type="submit" variant="contained" fullWidth
              style={{ width: "calc(100% - 0px)", borderRadius: "25px", outline: "none", border: "none", background: "linear-gradient(90deg, #1B4965 63.83%, #051925 82.97%)", backgroundSize: "200%", fontSize: "1.2rem", color: "#fff", fontFamily: "'Poppins', sans-serif", textTransform: "uppercase", margin: "1rem 0", cursor: "pointer", transition: "0.5s" }}>
              Change Password
            </Button>
          </form>
        )}
      </div>
      {loading && <Loader />}
    </div>
  );
}

export default ChangePassword;
