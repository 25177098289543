import { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { Box, Typography, Button, IconButton, TextField } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import ViewShifts from '../../../Components/CompanyAdminComponents/ShiftsComponents/ViewShifts/ViewShifts';
import ShiftTable from '../../../Components/CompanyAdminComponents/ShiftsComponents/ShiftTable/ShiftTable';
import { addWeeks, endOfWeek, format, startOfWeek, subWeeks } from '../../../utils/CustomPackages/dateUtils';

function Shifts() {
  const [openModal, setOpenModal] = useState(false);
  const [currentWeek, setCurrentWeek] = useState(new Date());

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  // To get week Dates Range
  const getFormattedDateRange = (date) => {
    const start = startOfWeek(date, { weekStartsOn: 1 });
    const end = endOfWeek(date, { weekStartsOn: 1 });
    return `${format(start, 'dd MMM')} - ${format(end, 'dd MMM')}`;
  };

  // Handlers for previous navigation buttons(Week election)
  const handlePreviousWeek = () => {
    setCurrentWeek(subWeeks(currentWeek, 1));
  };

  // Handlers for Next navigation buttons(Week election)
  const handleNextWeek = () => {
    setCurrentWeek(addWeeks(currentWeek, 1));
  };

  return (
    <Box sx={{ padding: 2, background: '#f1f1f1', height: '100%', maxHeight: '85vh', overflow: 'auto' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" padding="10px" borderBottom="1px solid #e0e0e0">
        <Typography variant="h5" sx={{ color: '#1B4965' }}>Shift Board</Typography>
        <Box display="flex">
          <Box display="flex" alignItems="center" sx={{ marginRight: '30px', border: '1px solid #ccc', borderRadius: '4px', backgroundColor: '#fff' }}>
            <IconButton onClick={handlePreviousWeek}><ArrowBackIosIcon sx={{ fontSize: '16px' }} /></IconButton>
            <TextField size="small" value={getFormattedDateRange(currentWeek)} variant="outlined"
              sx={{ width: '150px', textAlign: 'center' }}
              InputProps={{ readOnly: true, sx: { fontSize: '14px' } }}
            />
            <IconButton onClick={handleNextWeek}><ArrowForwardIosIcon sx={{ fontSize: '16px' }} /></IconButton>
          </Box>
          <Button variant="outlined" sx={{ color: '#1B4965', border: '1px solid #ccc', backgroundColor: '#fff', fontSize: '14px' }} onClick={handleOpenModal}>View Shifts</Button>
        </Box>
      </Box>
      <Box sx={{ marginTop: '10px', marginBottom: '40px'}}>
        <DndProvider backend={HTML5Backend}>
          <ShiftTable currentWeek={currentWeek} />
        </DndProvider>
      </Box>
      <ViewShifts openModal={openModal} handleCloseModal={handleCloseModal} />
    </Box>
  );
}

export default Shifts;
