import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Swal from 'sweetalert2';
import { MaterialReactTable, useMaterialReactTable, type MRT_ColumnDef, } from 'material-react-table';
import { ListItemIcon, MenuItem } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import './Location.css'
import Loader from '../../../UiUxComponents/Loader/Loader';
import { BranchLocationType } from '../../../../types';
import { selectToken } from '../../../../Redux/selector';
import Pagination from '../../../Pagination/Pagination';
import LocationRegistration from '../../../Registrations/LocationRegistration/LocationRegistration'
import { getLocation, deleteLocation } from '../../../../utils/Services/subscriberConfigurationServices/locationServices';

function Location() {
  const [data, setData] = useState<BranchLocationType[]>([]);
  const [openModal, setLocationRegistrationModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const token = useSelector(selectToken);
  const { subscriberId } = useParams();
  const navigate = useNavigate();

  // To fetch Location Details
  const fetchLocationDetail = async () => {
    setLoading(true);
    try {
      const response = await getLocation(token, subscriberId);
      setData(response?.data);
    } catch (error) {
      console.error('Error:', error);
      Swal.fire({
        icon: 'error',
        title: 'Failed ',
        text: 'Failed to fetch data',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      })
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchLocationDetail();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!openModal) {
      fetchLocationDetail();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  // To open Location Registration Modal
  const openLocationRegistrationModal = () => {
    setLocationRegistrationModal(true);
  };

  // To close Designation Registration Modal
  const closeLocationRegistrationModal = () => {
    setLocationRegistrationModal(false);
  };

  // To Handle page change in pagination
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const columns = useMemo<MRT_ColumnDef<BranchLocationType>[]>(
    () => [
      { accessorKey: 'branchName', header: 'Branch Name' },
      {
        accessorFn: row => row.branchManager.name,
        id: 'branchManagerName',
        header: 'Branch Manager',
      },
      {
        accessorFn: row =>
          `${row.address?.street ?? ''}, ${row.address?.city ?? ''}, ${row.address?.states ?? ''}, ${row.address?.postalCode ?? ''}, ${row.address?.country ?? ''}`.replace(/, ,/g, ',').replace(/^,|,$/g, ''),
        id: 'address',
        header: 'Address',
      },
    ], []
  );

  const deleteLocationById = async (locationId: string | undefined) => {
    if (locationId) {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1B4965',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          container: 'swal-container',
        }
      });

      if (result.isConfirmed) {
        setLoading(true);
        try {
          await deleteLocation(token, locationId);
          Swal.fire({
            icon: 'success',
            title: 'Location / Branch Deleted Successfully',
            confirmButtonColor: '#1B4965',
            customClass: {
              container: 'swal-container',
            }
          });
        } catch (error) {
          console.error('Error deleting location:', error);
          Swal.fire({
            icon: 'error',
            title: 'Failed to delete location',
            confirmButtonColor: '#1B4965',
            customClass: {
              container: 'swal-container',
            }
          });
        } finally {
          setLoading(false);
          fetchLocationDetail();
        }
      }
    }
  };

  // To set table header styles
  const muiTableHeadCellProps = {
    sx: {
      backgroundColor: '#1B4965',
      color: '#fff',
      fontWeight: 'bold',
      '& .MuiTableSortLabel-root .MuiTableSortLabel-icon': {
        color: '#fff !important',
      },
      '& .MuiTableSortLabel-icon': {
        color: '#fff',
      },
      '& .MuiIconButton-root': {
        color: '#fff',
      },
      '&:first-of-type': {
        borderTopLeftRadius: '12px',
      },
      '&:last-of-type': {
        borderTopRightRadius: '12px',
      },
      fontSize: '14px'
    },
  };

  // To set table body row styles
  const muiTableBodyRowProps = (row: any) => ({
    sx: {
      backgroundColor: getRowBackgroundColor(row),
    }
  })

  // To set table body cell styles
  const muiTableBodyCellProps = (row: any) => ({
    sx: {
      fontSize: '13px',
    }
  })

  // To set background color for even rows in tables
  const getRowBackgroundColor = (row: any) => {
    return row?.row?.index % 2 === 0 ? "#ffffff" : "#d6e1ed"
  };

  // To Render table
  const table = useMaterialReactTable({
    columns,
    data: data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    enableTopToolbar: false,
    muiTableHeadCellProps,
    muiTableBodyRowProps,
    muiTableBodyCellProps,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enablePagination: false,
    enableRowActions: true,
    renderBottomToolbarCustomActions: () => (
      <Pagination
        count={data.length}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    ),
    initialState: {
      density: 'comfortable',
      columnPinning: { right: ['mrt-row-actions'] }
    },
    muiTablePaperProps: ({ table }) => ({
      style: {
        zIndex: -1,
      },
    }),
    muiTableContainerProps: { sx: { maxHeight: '65vh' } },
    renderRowActionMenuItems: ({ row, closeMenu }) => {
      const { _id } = row.original;
      return [
        <MenuItem
          key={0}
          onClick={() => {
            navigate(`/subscriber/view-location/${_id}`);
            closeMenu();
          }}
          sx={{ m: 0, fontSize: '13px' }}
        >
          <ListItemIcon>
            <AccountCircle style={{ fontSize: '18px' }} />
          </ListItemIcon>
          View Location / Branch
        </MenuItem>,
        <MenuItem
          key={1}
          onClick={() => {
            deleteLocationById(_id)
            closeMenu();
          }}
          sx={{ m: 0, fontSize: '13px' }}
        >
          <ListItemIcon>
            <DeleteForeverIcon style={{ fontSize: '18px' }} />
          </ListItemIcon>
          Delete Location / Branch
        </MenuItem>,
      ];
    },
  })

  return (
    <div>
      <div className='location-dashboard'>
        <div className='location-title'>
          <p>Branch / Location</p>
        </div>
        <button className='add-location' onClick={openLocationRegistrationModal}>Create New</button>
      </div>
      <div className='locationtable'>
        <MaterialReactTable table={table} />
      </div>
      <LocationRegistration open={openModal} onClose={closeLocationRegistrationModal} />
      {(loading) && (
        <Loader />
      )}
    </div>
  )
}

export default Location
