import moment from 'moment';

export const startOfWeek = (date) => {
    const day = date.getDay();
    const difference = day === 0 ? -6 : 1 - day;
    return new Date(date.setDate(date.getDate() + difference));
};

export const endOfWeek = (date) => {
    const day = date.getDay();
    const difference = day === 0 ? 0 : 7 - day;
    return new Date(date.setDate(date.getDate() + difference));
};

export const startOfMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), 1);
};

export const endOfMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};

export const eachDayOfInterval = (start, end, params) => {
    const dates = [];

    let current = new Date(start);

    while (moment(current).isSameOrBefore(moment(end))) {
        dates.push(new Date(current));
        current.setDate(current.getDate() + 1);
    }

    return dates;
};

export const isSaturday = (date) => date.getDay() === 6;

export const isSunday = (date) => date.getDay() === 0;

export const format = (date, formatString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };

    switch (formatString) {
        case 'yyyy-MM-dd':
            return date.toISOString().split('T')[0];
        case 'dd/MM/yyyy':
            return date.toLocaleDateString('en-GB', options);
        case 'MM/dd/yyyy':
            return date.toLocaleDateString('en-US', options);
        case 'hh:mm a': {
            const hours = date.getHours() % 12 || 12;
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
            return `${hours}:${minutes} ${ampm}`;
        }
        case 'dd MMM': {
            const day = date.getDate().toString().padStart(2, '0');
            const month = date.toLocaleString('en-US', { month: 'short' });
            return `${day} ${month}`;
        }
        case 'dd': {
            return date.getDate().toString().padStart(2, '0');
        }
        case 'EEE': {
            const weekday = date.toLocaleString('en-US', { weekday: 'short' });
            return weekday;
        }
        case 'MMM': {
            const month = date.toLocaleString('en-US', { month: 'short' });
            return month;
        }
        default:
            return date.toDateString();
    }
};

export const normalizeDate = (date) => {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    return d;
};

export const subWeeks = (date, weeks) => {
    const result = new Date(date);
    result.setDate(result.getDate() - weeks * 7);
    return result;
};

export const addWeeks = (date, weeks) => {
    const result = new Date(date);
    result.setDate(result.getDate() + weeks * 7);
    return result;
};

export const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
};

export const parseClockInTime = (timeString) => {
    const [datePart, timePart] = timeString.split(' ');
    const [day, month, year] = datePart.split('/').map(Number);
    const [hours, minutes, seconds] = timePart.split(':').map(Number);

    const date = new Date(year, month - 1, day, hours, minutes, seconds);
    return date;
};